import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityGetBuilderScenes = () => {
  (window as { [key: string]: any })["get_builder_scenes"] = (
    username: any
  ) => {
    (window as any).AWS.config.credentials = new (
      window as any
    ).AWS.CognitoIdentityCredentials(
      { IdentityPoolId: "eu-west-1:4bd21aea-5db0-4a1d-b936-1de1ab44ee28" },
      { region: "eu-west-1" }
    );
    var s3 = new (window as any).AWS.S3({
      apiVersion: "2006-03-01",
      region: "eu-west-1",
    });
    var sceneList = "";
    var lookTo = "Builder/" + username;
    var params = {
      Bucket: "atlas-prod-meta-assetsatlasspace-origin",
    };
    s3.listObjects(params, function (err: any, data: any) {
      if (err) console.log(err, err.stack); // an error occurred
      else {
        var objectArray = data.Contents;
        for (let i = 0; i < objectArray.length; i++) {
          if (objectArray[i].Key.startsWith(lookTo)) {
            var scene = objectArray[i].Key;
            scene = scene.substring(lookTo.length + 1, scene.length);
            sceneList += scene + "+";
          }
        }

        unityContext.send("NetworkManager", "GetBuilderSceneNames", sceneList);
      }
    });
  };
};

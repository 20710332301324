import React from "react";
import { Route, Routes } from "react-router-dom";

const CreateNftGallery = () => {
  return (
    <Routes>
      <Route
        path="/create-nft-gallery"
        element={
          <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
            <iframe style={{ width: "100%", height: "100%" }} id="createNftGallery" src="/unity/?createNftGallery" frameBorder={0}></iframe>
          </div>
        }
      />
    </Routes>
  );
};

export default CreateNftGallery;

import axios, { AxiosResponse } from "axios";
import { BaseResponse } from "../BaseResponse";
import { BaseService } from "../BaseService";
import { LoginRequest, LoginResponse } from "./types/LoginTypes";
import { LoginError } from "utils/authCustomError/LoginError/LoginError";

export class LoginService {
  private userService: BaseService;
  private readonly url: string = "Auth/LoginUser";

  constructor() {
    this.userService = new BaseService();
  }

  public async login(loginData: LoginRequest): Promise<AxiosResponse | any> {
    try {
      const response = await this.userService.post<
        LoginRequest,
        AxiosResponse<BaseResponse<LoginResponse>>
      >(this.url, loginData);

      if (response.status === 200) {
        return response;
      }
    } catch (error: any) {
      if (axios.isAxiosError(error!)) {
        console.log("error", error.response);

        LoginError(error?.response?.data);
      } else {
        console.log(error);
      }
    }
    return;
  }
}

import { apiUrl } from "api/apiUrl";

export const isMetamaskPublicAddress = async (address: string) => {
  const response = await fetch(
    `${apiUrl}/Metamask/CheckMetaMaskUserExists?publicAddress=${address}`
  );

  const data = await response.json();
  return data;
};

import React, { ChangeEvent, useState } from "react";
import "./SocialFactor.css";
import AuthCards from "components/AuthCards/AuthCards";
import Inputs from "components/Inputs/Inputs";
import { TorusRegisterCodeRequest } from "services/AuthService/types/TorusTypes/TorusRegisterCodeTypes";
import Social from "assets/Svg/Social";
import { useLocation, useNavigate } from "react-router-dom";
import { Localstorage } from "utils/localstorage/Localstorage";
import { TorusRegisterError } from "utils/authCustomError/Torus/TorusError";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";

interface IProps {
  socialRegisterFormId: string;
}

const SocialFactor: React.FC<IProps> = ({ socialRegisterFormId }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [registerCode, setRegisterCode] = useState<TorusRegisterCodeRequest>({
    registerFormId: "",
    confirmationCode: "",
  });

  console.log("register code", registerCode);

  const onChangeRegister = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterCode((prev: TorusRegisterCodeRequest) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const torusRegister = async () => {
    const request = await fetch(`${apiUrl}/Torus/ConfirmTorusUserRegister`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        registerFormId: state,
        confirmationCode: registerCode.confirmationCode,
      }),
    });
    const response = await request.json();
    console.log(response);

    if (response?.status === "Success") {
      await new Localstorage().setLocalstorage(response);
      if (response.avatarUrl === "" || response.avatarUrl === null) {
        navigate("create-avatar");
      } else {
        navigate("/meta");
      }
    } else {
      TorusRegisterError(response.errors);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="login-email"
    >
      <div className="login-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p>Please enter your register Code That Has Been Sent To Your Email</p>
      </div>
      <div className="login-flex">
        <div className="login-left">
          <AuthCards title="Log in with Socials" url="/">
            <Social />
          </AuthCards>
        </div>
        <div className="login-account">
          <h6>Register Code</h6>
          <Inputs
            title="Code"
            name="confirmationCode"
            onChange={onChangeRegister}
          ></Inputs>
          <small className="wrong-code">Confirm Code Not Found</small>
        </div>
      </div>
      <div className="login-accept">
        <button onClick={torusRegister}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default SocialFactor;

import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityOpenAI = () => {
  (window as { [key: string]: any })["open_ai"] = async (prompt: any) => {
    const promptArray = prompt.split("+");
    var target = promptArray[0];
    var npc = promptArray[1];
    var msg = promptArray[2];

    var url = "https://api.openai.com/v1/completions";
    var xhr = new XMLHttpRequest();
    xhr.open("POST", url);

    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader(
      "Authorization",
      "Bearer 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"
    );
    xhr.setRequestHeader("OpenAI-Organization", "org-xXIdPZH5dUDQrO661C98dRnf");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        var open_ai_response = JSON.parse(xhr.responseText);
        var result = open_ai_response.choices[0].text;
        result = result.trim();
        var toUnity = target + "+" + npc + "+" + result;

        unityContext.send("NetworkManager", "OnChatGPTAnswered", toUnity);
      }
    };

    const data = {
      prompt: msg,
      temperature: 0.7,
      max_tokens: 256,
      top_p: 1,
      frequency_penalty: 0.75,
      presence_penalty: 0,
      model: "text-davinci-003",
    };

    var jsonData = JSON.stringify(data);

    xhr.send(jsonData);
  };
};

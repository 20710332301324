import React, { ChangeEvent, useState, useEffect } from "react";
import "./ResetPass.css";
import AuthCards from "components/AuthCards/AuthCards";
import Inputs from "components/Inputs/Inputs";
import PasswordKey from "assets/Svg/Register/Key";
import { ResetPasswordService } from "services/AuthService/ResetPasswordService";
import { ResetPasswordRequest } from "services/AuthService/types/ResetPasswordTypes";
import { ResetPasswordType } from "./ResetPassword";
import { useNavigate } from "react-router-dom";
import { IResetPasswordError } from "utils/authCustomError/resetPasswordError/IResetPasswordError";
import { ResetPasswordError } from "utils/authCustomError/resetPasswordError/ResetPasswordError";
import { motion } from "framer-motion";
interface IProps {
  forgotEmail: string;
}

const Index: React.FC<IProps> = ({ forgotEmail }) => {
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const [reset, setReset] = useState<ResetPasswordType>({
    emailOrUsername: forgotEmail,
    resetCode: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setReset((prev: ResetPasswordType) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

  const resetPassword = async () => {
    const requestReset: ResetPasswordRequest = {
      emailOrUsername: reset.emailOrUsername,
      newPassword: reset.newPassword,
      resetCode: reset.resetCode,
    };
    const userService = new ResetPasswordService();
    const response = await userService.resetPassword(requestReset);

    if (response?.status === 200) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (
      reset.newPassword !== "" &&
      reset.newPassword === reset.confirmPassword
    ) {
      const confirmPasswordError: IResetPasswordError = {
        data: null,
        errors: [
          {
            code: 235,
            description: "Password check success",
            key: "Password check success",
          },
        ],
        message: null,
        status: "error",
      };
      ResetPasswordError(confirmPasswordError);
      setButtonDisabled(true);
    } else if (reset.newPassword === "" && reset.confirmPassword === "") {
      const confirmPasswordError: IResetPasswordError = {
        data: null,
        errors: [
          {
            code: 235,
            description: "Password check success",
            key: "Password check success",
          },
        ],
        message: null,
        status: "error",
      };
      ResetPasswordError(confirmPasswordError);
    } else if (
      reset.newPassword !== "" ||
      reset.confirmPassword !== "" ||
      reset.newPassword !== reset.confirmPassword
    ) {
      const confirmPasswordError: IResetPasswordError = {
        data: null,
        errors: [
          {
            code: 235,
            description: "Password and confirm password are not the same",
            key: "Password and confirm password are not the same",
          },
        ],
        message: null,
        status: "error",
      };
      setButtonDisabled(false);
      ResetPasswordError(confirmPasswordError);
    } else {
      setButtonDisabled(false);
    }
  }, [reset]);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="resetpass-wrapper"
    >
      <div className="reset-pass">
        <div className="reset-email">
          <div className="reset-title">
            <h1>Reset Your Password</h1>
            <p>
              After resetting your password please return back to Atlas Space
              tab in <br /> your browser to login with your new password.
            </p>
          </div>
          <div className="reset-flex">
            <div className="reset-left">
              <AuthCards title="Reset Password" url="/">
                <PasswordKey width="80" height="80" />
              </AuthCards>
            </div>
            <div className="reset-account">
              <h6>Verification Code</h6>
              <Inputs
                title="Code"
                name="resetCode"
                type="text"
                onChange={handleChange}
              >
                {/* <PasswordKey /> */}
              </Inputs>
              {/* yanlış verification kodu olduğunda çıkacak uyarı */}
              <small className="wrong-verificationcode">
                Invalid Reset Code
              </small>

              {/* verification kodunun süresi geçtiğinde çıkacak uyarı */}
              <small className="resetcode-expired">Reset Code Expired</small>
              <h6>New Password</h6>

              <Inputs
                title="Password"
                name="newPassword"
                className="title-padding"
                style={{ paddingLeft: "45px" }}
                type="password"
                onChange={handleChange}
              >
                <PasswordKey />
              </Inputs>

              <Inputs
                title="Password"
                name="confirmPassword"
                className="title-padding"
                style={{ paddingLeft: "45px" }}
                type="password"
                onChange={handleChange}
              >
                <PasswordKey />
              </Inputs>
            </div>
          </div>
          <div className="reset-accept">
            <button
              onClick={resetPassword}
              className={buttonDisabled ? "continue" : "disabled"}
            >
              Reset and Save Your Password
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Index;

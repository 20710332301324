import { apiUrl } from "api/apiUrl";

export const loginWithMetamask = async (address: string) => {
  const request = await fetch(`${apiUrl}/Metamask/LoginMetamaskUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ publicAddress: address }),
  });
  const response = await request.json();
  return response;
};

export async function inputCheckControll<T>(obj: {
  [K in keyof T]: T[K];
}): Promise<boolean> {
  const inputValue: boolean = Object.values(obj).every((value) => value !== "");
  return inputValue;
}

export function inputCheckControllSync<T>(obj: {
  [K in keyof T]: T[K];
}): boolean {
  const inputValue: boolean = Object.values(obj).every((value) => value !== "");
  return inputValue;
}

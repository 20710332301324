import React from "react";

function Back() {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1987_52550"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={18}
        height={18}
      >
        <rect width={18} height={18} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1987_52550)">
        <path
          d="M10.5287 13.7959L5.69531 8.97308L10.5287 4.13965L11.6415 5.25242L7.92086 8.97308L11.6415 12.6831L10.5287 13.7959Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default Back;

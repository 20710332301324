import React, { ChangeEvent, useState } from "react";
import "./Login.css";
import AuthCards from "components/AuthCards/AuthCards";
import Mail from "assets/Svg/Mail";
import Inputs from "components/Inputs/Inputs";
import User from "assets/Svg/Register/User";
import PasswordKey from "assets/Svg/Register/Key";
import { Link, useNavigate } from "react-router-dom";
import { LoginRequest } from "services/AuthService/types/LoginTypes";
import { LoginService } from "services/AuthService/LoginService";
import { Localstorage } from "utils/localstorage/Localstorage";
import { motion } from "framer-motion";
const Login = () => {
  const navigate = useNavigate();

  const [loginUser, setLoginUser] = useState<LoginRequest>({
    usernameOrEmail: "",
    password: "",
  });

  const onChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginUser((prev: LoginRequest) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const loginUserWithEmail = async () => {
    const loginService = new LoginService();
    const response = await loginService.login(loginUser);
    if (response) {
      if (response.status === 200) {
        new Localstorage().setLocalstorage(response.data);

        if (response.data.data.avatarUrl === null || response.data.data.avatarUrl === "") {
          navigate("/create-avatar");
        } else {
          navigate("/meta");
        }
      }
    }
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="login-email">
      <div className="login-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p>Join to the Atlas Space for free</p>
      </div>
      <div className="login-flex">
        <div className="login-left">
          <AuthCards title="Log in with E-Mail" url="/">
            <Mail />
          </AuthCards>
        </div>
        <div className="login-account">
          <h6>Account</h6>
          <Inputs title="Username" name="usernameOrEmail" className="title-padding" style={{ paddingLeft: "45px" }} onChange={onChangeLogin}>
            <User />
          </Inputs>
          <small className="wrong-username">Email or Username Not Found</small>
          <Inputs title="Password" name="password" className="title-padding" style={{ paddingLeft: "45px" }} type="password" onChange={onChangeLogin}>
            <PasswordKey />
          </Inputs>
          <small className="wrong-password">Wrong Password</small>
          <div className="login-remember">
            <div className="login-checkbox">
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
            <Link to="/forgotpassword">Forgot Your Password?</Link>
          </div>
        </div>
      </div>
      <div className="login-accept">
        <button onClick={loginUserWithEmail}>Accept and Start Exploring</button>
      </div>
      <div className="already-account">
        <span>
          Don’t have an account? <Link to="/register">Sign Up Here</Link>
        </span>
      </div>
    </motion.div>
  );
};

export default Login;

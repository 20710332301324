import React, { ChangeEvent, useState } from "react";
import "./SocialRegisterCodeView.css";
import AuthCards from "components/AuthCards/AuthCards";
import Inputs from "components/Inputs/Inputs";
import Social from "assets/Svg/Social";
import { TorusLoginCodeService } from "services/AuthService/TorusService/TorusLoginCodeService";
import { TorusLoginCodeRequest } from "services/AuthService/types/TorusTypes/TorusLoginCodeTypes";
import { useNavigate } from "react-router-dom";
import { Localstorage } from "utils/localstorage/Localstorage";
import { motion } from "framer-motion";

interface IProps {
  socialAdress: string;
}

const SocialRegisterCodeView: React.FC<IProps> = ({ socialAdress }) => {
  const navigate = useNavigate();
  const [loginTorus, setLoginTorus] = useState<TorusLoginCodeRequest>({
    loginFormId: socialAdress,
    confirmationCode: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setLoginTorus((prev: TorusLoginCodeRequest) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

  // onclick durumunda istek atma durumu
  const torusLoginCode = async () => {
    const userService = new TorusLoginCodeService();
    const response = await userService.torusLogin(loginTorus);
    console.log("response", response);

    if (response?.status === 200) {
      new Localstorage().setLocalstorage(response?.data);
      if (
        response?.data.avatarUrl === null ||
        response?.data.avatarUrl === ""
      ) {
        navigate("/create-avatar");
      } else {
        navigate("/meta");
      }
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="login-email"
      >
        <div className="login-title">
          <h1>Welcome to ATLAS SPACE</h1>
          <p>
            Please enter your register Code That Has Been Sent To Your Email
          </p>
        </div>
        <div className="login-flex">
          <div className="login-left">
            <AuthCards title="Log in with Socials" url="/">
              <Social />
            </AuthCards>
          </div>
          <div className="login-account">
            <h6>Login Code</h6>
            <Inputs
              title="Code"
              name="confirmationCode"
              onChange={handleChange}
            ></Inputs>
            <small className="wrong-code">Confirm Code Not Found</small>
          </div>
        </div>
        <div className="login-accept">
          <button onClick={torusLoginCode}>Start Exploring</button>
        </div>
      </motion.div>
    </>
  );
};

export default SocialRegisterCodeView;

import React, { useState } from "react";
import "./Terms.css";
import PrivacyBackground from "components/PrivacyBg/PrivacyBackground";
import Container from "components/Container/Container";

interface IProps {
  setShowRegister: (state: boolean) => void;
}

const Index: React.FC<IProps> = ({ setShowRegister }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const checkControll = (e: any) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Container>
      <PrivacyBackground>
        <div className="social-title"></div>
        <div className="social-container">
          <p className="scroll">
            <div className="scroll-padding">
              <div className="WordSection1">
                <h1
                  className="MsoTitle"
                  style={{ textAlign: "center", marginTop: "0in" }}
                >
                  PRIVACY POLICY
                </h1>
                <p className="MsoNormal">
                  <b>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                        lineHeight: "150%",
                      }}
                    >
                      Last updated May 01, 2023
                    </span>
                  </b>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    This<span style={{ letterSpacing: "-.1pt" }}> </span>
                    privacy
                    <span style={{ letterSpacing: "-.1pt" }}></span>notice
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    <span style={{ letterSpacing: "-.1pt" }}> </span>IMMERSIVE
                    <span style={{ letterSpacing: "-.05pt" }}></span>DREAM
                    <span style={{ letterSpacing: "-.2pt" }}> </span>TECH,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>INC.
                    <span style={{ letterSpacing: "-.1pt" }}> </span>(doing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>business
                    <span style={{ letterSpacing: "-.1pt" }}></span>as
                    <span style={{ letterSpacing: "-.35pt" }}> </span>Atlas
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Space)
                    <span style={{ letterSpacing: "-1.5pt" }}></span>("
                    <b>
                      Atlas<span style={{ letterSpacing: ".05pt" }}> </span>
                      Space
                    </b>
                    ,"
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: ".05pt",
                    }}
                  >
                    {" "}
                    “<b>Platform</b>,"{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    "<b>we</b>,"
                    <span style={{ letterSpacing: ".1pt" }}> </span>"<b>us</b>
                    ,"
                    <span style={{ letterSpacing: ".05pt" }}> </span>or
                    <span style={{ letterSpacing: ".1pt" }}> </span>"<b>our</b>
                    "),
                    <span style={{ letterSpacing: ".05pt" }}> </span>describes
                    <span style={{ letterSpacing: ".05pt" }}></span>how
                    <span style={{ letterSpacing: ".1pt" }}> </span>and
                    <span style={{ letterSpacing: ".05pt" }}> </span>why
                    <span style={{ letterSpacing: ".05pt" }}> </span>we
                    <span style={{ letterSpacing: ".1pt" }}> </span>might
                    <span style={{ letterSpacing: ".05pt" }}> </span>collect,
                    <span style={{ letterSpacing: ".1pt" }}> </span>store,
                    <span style={{ letterSpacing: ".05pt" }}> </span>use, and/or
                    share ("
                    <b>process</b>") your information when you use our services
                    ("
                    <b>Services</b>"),
                    <span style={{ letterSpacing: ".05pt" }}> </span>such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>when you:
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginLeft: "22.0pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Visit our website at{" "}
                    <a href="https://atlas.space">https://atlas.space</a>, or
                    any website of ours that links to this
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>privacy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>notice
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginLeft: "22.0pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Download and use our mobile application (Atlas Space), our
                    Facebook
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    application (Atlas Space),&nbsp; use our web application
                    (Atlas Space),
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    or any other application of ours that links to this privacy
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>notice
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginLeft: "22.0pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Engage<span style={{ letterSpacing: "-.05pt" }}> </span>
                    with us
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in other
                    <span style={{ letterSpacing: "-.05pt" }}> </span>related
                    ways,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    including any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>sales,
                    marketing,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or events
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Questions or concerns?{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Reading this privacy notice will help you understand your
                    <span style={{ letterSpacing: ".05pt" }}> </span>privacy
                    rights and choices. If you do not agree with our policies
                    and practices, please do
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>not use
                    our Services. If you still have any questions or concerns,
                    please contact us at
                    <span style={{ letterSpacing: ".05pt" }}>
                      {" "}
                      <a href="mailto:connect@atlas.space?subject=Privacy%20Policy">
                        connect@atlas.space.
                      </a>
                    </span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h2>SUMMARY OF KEY POINTS</h2>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        This summary provides key points from our privacy
                        notice, but you can find out
                      </span>
                    </i>
                  </b>
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}> </span>
                    </i>
                  </b>
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        more details about any of these topics by clicking the
                        link following each key point or by using
                      </span>
                    </i>
                  </b>
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}> </span>
                    </i>
                  </b>
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        our{" "}
                        <a href="#_TABLE OF CONTENTS">
                          table of
                          <span style={{ letterSpacing: "-.05pt" }}> </span>
                          contents
                        </a>{" "}
                        below to find the section you are looking for.
                      </span>
                    </i>
                  </b>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span
                        style={{
                          fontSize: "10.5pt",
                          lineHeight: "150%",
                          fontFamily: '"Arial",sans-serif',
                        }}
                      >
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      What personal information do we process?{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    When you visit, use, or navigate our
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process
                    <span style={{ letterSpacing: "-.1pt" }}></span>personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    depending
                    <span style={{ letterSpacing: "-.1pt" }}> </span>on
                    <span style={{ letterSpacing: "-.05pt" }}> </span>how
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>interact
                    <span style={{ letterSpacing: "-.1pt" }}> </span>with
                    <span style={{ letterSpacing: "-.35pt" }}> </span>Atlas
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>Space and
                    the Services, the choices you make, and the products and
                    features you use.
                    <span style={{ letterSpacing: ".05pt" }}></span>Learn
                    <span style={{ letterSpacing: "-.05pt" }}> </span>more
                    <span style={{ letterSpacing: "-.05pt" }}> </span>about{" "}
                    <a href="#_Personal information you disclose to us">
                      personal
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      information you
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      disclose to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>us
                    </a>
                    .
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      Do we process any sensitive personal information?{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process sensitive
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    personal information when necessary with your consent or as
                    otherwise permitted by
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    applicable
                    <span style={{ letterSpacing: "-.05pt" }}> </span>law.
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Learn
                    <span style={{ letterSpacing: "-.05pt" }}> </span>more about
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    sensitive
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      Do we receive any information from third parties?{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We do not receive any information
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>from
                    <span style={{ letterSpacing: "-.05pt" }}></span>third
                    <span style={{ letterSpacing: "-.05pt" }}> </span>parties.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      How do we process your information?{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We process your information to provide,
                    <span style={{ letterSpacing: ".05pt" }}> </span>improve,
                    and administer our Services, communicate with you, for
                    security and fraud
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    prevention, and to comply with law. We may also process your
                    information for other
                    <span style={{ letterSpacing: ".05pt" }}> </span>purposes
                    with your consent. We process your information only when we
                    have a valid
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>legal
                    <span style={{ letterSpacing: "-.05pt" }}></span>reason to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>do so.
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Learn more
                    <span style={{ letterSpacing: "-.05pt" }}> </span>about how
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we process
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    information.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      In what situations and with which types of parties do we
                      share personal
                      <span style={{ letterSpacing: ".05pt" }}> </span>
                      information?{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may share information in specific situations and with
                    specific
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    categories of third parties. Learn more about when and with
                    whom we share your
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    information.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      How do we keep your information safe?{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We have organizational and technical
                    <span style={{ letterSpacing: ".05pt" }}> </span>processes
                    and procedures in place to protect your personal
                    information. However, no
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    electronic transmission over the internet or information
                    storage technology can be
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    guaranteed to be 100% secure, so we cannot promise or
                    guarantee that hackers,
                    <span style={{ letterSpacing: ".05pt" }}> </span>cyber
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    criminals, or other unauthorized third parties will not be
                    able to defeat our security
                    <span style={{ letterSpacing: ".05pt" }}> </span>and
                    improperly collect, access, steal, or modify your
                    information. Learn more about how
                    <span style={{ letterSpacing: "-1.5pt" }}></span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>keep
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>safe.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      What are your rights?{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Depending on where you are located geographically, the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    applicable privacy law may mean you have certain rights
                    regarding your personal
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    information.
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Learn
                    <span style={{ letterSpacing: "-.05pt" }}> </span>more about
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    privacy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>rights.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      How<span style={{ letterSpacing: "-.05pt" }}> </span>do
                      you
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      exercise your
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      rights?
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    The<span style={{ letterSpacing: "-.05pt" }}> </span>
                    easiest way
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    exercise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    rights is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by
                    visiting
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    <a href="https://atlas.space/data-request">
                      https://atlas.space/data-request
                    </a>
                    , or by contacting us. We will consider and act upon any
                    <span style={{ letterSpacing: ".05pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    accordance with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    applicable data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    protection laws.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Want<span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>learn
                    <span style={{ letterSpacing: "-.1pt" }}> </span>more
                    <span style={{ letterSpacing: "-.1pt" }}> </span>about
                    <span style={{ letterSpacing: "-.1pt" }}> </span>what
                    <span style={{ letterSpacing: "-.35pt" }}> </span>Atlas
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Space
                    <span style={{ letterSpacing: "-.1pt" }}> </span>does
                    <span style={{ letterSpacing: "-.1pt" }}> </span>with
                    <span style={{ letterSpacing: "-.1pt" }}> </span>any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.1pt" }}> </span>we
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    collect?
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Review
                    <span style={{ letterSpacing: "-.05pt" }}></span>the privacy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>notice in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>full.
                  </span>
                </p>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "150%",
                    fontFamily: '"Arial",sans-serif',
                  }}
                >
                  <br />
                </span>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <h1>
                  <a href="/">
                    <span style={{ fontSize: "10.5pt", lineHeight: "150%" }}>
                      TABLE OF CONTENTS
                    </span>
                  </a>
                </h1>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    1. WHAT INFORMATION DO WE COLLECT?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    2. HOW DO WE PROCESS YOUR INFORMATION?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    5. WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    6. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    8. HOW LONG DO WE KEEP YOUR INFORMATION?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    10. DO WE COLLECT INFORMATION FROM MINORS?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    11. WHAT ARE YOUR PRIVACY RIGHTS?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    12. CONTROLS FOR DO-NOT-TRACK FEATURES
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    14. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    15. DO WE MAKE UPDATES TO THIS NOTICE?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
                    COLLECT FROM YOU?
                  </span>
                </p>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "150%",
                    fontFamily: '"Arial",sans-serif',
                  }}
                >
                  <br style={{ pageBreakBefore: "always" }} />
                </span>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">1. </span>WHAT INFORMATION DO WE COLLECT?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <h2>
                  <a href="/">Personal information you disclose to us</a>
                </h2>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        In<span style={{ letterSpacing: "-.05pt" }}> </span>
                        Short:
                        <span style={{ letterSpacing: "-.05pt" }}></span>
                      </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We<span style={{ letterSpacing: "-.05pt" }}> </span>
                      collect personal
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      information
                      <span style={{ letterSpacing: "-.05pt" }}> </span>that
                      <span style={{ letterSpacing: "-.05pt" }}> </span>you
                      provide
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>us.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We collect personal information that you voluntarily provide
                    to us when you register on
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>the
                    Services, express an interest in obtaining information about
                    us or our products and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    Services, when you participate in activities on the
                    Services, or otherwise when you
                    <span style={{ letterSpacing: ".05pt" }}> </span>contact
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span className="Heading3Char">
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial MT"',
                      }}
                    >
                      Personal Information Provided by You.
                    </span>
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      {" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The personal information that we collect
                    <span style={{ letterSpacing: ".05pt" }}> </span>depends on
                    the context of your interactions with us and the Services,
                    the choices you
                    <span style={{ letterSpacing: ".05pt" }}> </span>make, and
                    the products and features you use. The personal information
                    we collect may
                    <span style={{ letterSpacing: "-1.55pt" }}></span>include
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    following:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    names
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    phone<span style={{ letterSpacing: "-.05pt" }}> </span>
                    numbers
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    email<span style={{ letterSpacing: "-.05pt" }}> </span>
                    addresses
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    mailing<span style={{ letterSpacing: "-.05pt" }}> </span>
                    addresses
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    job<span style={{ letterSpacing: "-.05pt" }}> </span>
                    titles
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    usernames
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    passwords
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    contact<span style={{ letterSpacing: "-.05pt" }}> </span>
                    preferences
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    contact<span style={{ letterSpacing: "-.05pt" }}> </span>
                    or authentication
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    billing<span style={{ letterSpacing: "-.05pt" }}> </span>
                    addresses
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    debit/credit
                    <span style={{ letterSpacing: "-.05pt" }}> </span>card
                    numbers
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span className="Heading3Char">
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial MT"',
                      }}
                    >
                      Sensitive Information.
                    </span>
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                        letterSpacing: "-.05pt",
                      }}
                    >
                      {" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    When<span style={{ letterSpacing: "-.05pt" }}> </span>
                    necessary,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>consent
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    <span style={{ letterSpacing: "-.1pt" }}> </span>otherwise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    permitted
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    applicable
                    <span style={{ letterSpacing: "-.05pt" }}> </span>law,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process
                    the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    following
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    categories
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    sensitive
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    data<span style={{ letterSpacing: "-.05pt" }}> </span>
                    about a<span style={{ letterSpacing: "-.05pt" }}></span>
                    person's sex
                    <span style={{ letterSpacing: "-.05pt" }}> </span>life or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>sexual
                    orientation
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    revealing race
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or ethnic
                    <span style={{ letterSpacing: "-.05pt" }}> </span>origin
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    financial
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Payment Data.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may collect data necessary to process your payment if you
                    make
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    purchases, such as your payment instrument number, and the
                    security code associated
                    <span style={{ letterSpacing: ".05pt" }}> </span>with your
                    payment instrument. All payment data is stored by ConsenSys
                    Software Inc.,
                    <span style={{ letterSpacing: ".05pt" }}> </span>Google
                    Payments and Apple Payments Inc.. You may find their privacy
                    notice link(s)
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice&ldl=en">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        here: https://consensys.net/privacy-policy/,
                        https://payments.google.com/payments/apis-
                        <span style={{ letterSpacing: "-1.5pt" }}> </span>
                        secure/get_legal_document?ldo=0&amp;ldt=privacynotice&amp;ldl=en
                        and
                      </span>
                    </a>
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    <a href="https://www.apple.com/legal/applepayments/privacy-notice/">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        https://www.apple.com/legal/applepayments/privacy-notice/
                      </span>
                    </a>
                    .
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Social Media Login Data.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may provide you with the option to register with us using
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>your
                    existing social media account details, like your Facebook,
                    Twitter, or other social
                    <span style={{ letterSpacing: ".05pt" }}> </span>media
                    account. If you choose to register in this way, we will
                    collect the information
                    <span style={{ letterSpacing: ".05pt" }}> </span>described
                    in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?"
                    <span style={{ letterSpacing: ".05pt" }}> </span>below.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Application Data.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If you use our application(s), we also may collect the
                    following
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>if
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you choose
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to provide
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>access or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    permission:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      Geolocation Information.{" "}
                    </span>
                  </i>
                  <span style={{ lineHeight: "150%" }}>
                    We may request access or permission to track location-
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>based
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    information from
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    mobile
                    <span style={{ letterSpacing: "-.05pt" }}> </span>device,
                    either
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    continuously or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>while you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are
                  </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    {" "}
                    using our mobile application(s), to provide certain
                    location-based services. If you wish to change our access or
                    permissions, you may do so in your device's settings.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Mobile<span style={{ letterSpacing: "-.1pt" }}> </span>
                      Device
                      <span style={{ letterSpacing: "-.3pt" }}> </span>Access.
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>access
                    <span style={{ letterSpacing: "-.1pt" }}></span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    permission
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>certain
                    <span style={{ letterSpacing: "-.1pt" }}> </span>features
                    <span style={{ letterSpacing: "-1.45pt" }}></span>from your
                    mobile device, including your mobile device's calendar,
                    camera,
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    microphone, reminders, sensors, social media accounts,
                    contacts, storage, sms
                    <span style={{ letterSpacing: ".05pt" }}> </span>messages,
                    bluetooth, and other features. If you wish to change our
                    access or
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    permissions,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may do
                    <span style={{ letterSpacing: "-.05pt" }}> </span>so in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    device's
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    settings.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Mobile Device Data.{" "}
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We automatically collect device information (such as your
                    <span style={{ letterSpacing: ".05pt" }}> </span>mobile
                    device ID, model, and manufacturer), operating system,
                    version information
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>and system
                    configuration information, device and application
                    identification
                    <span style={{ letterSpacing: ".05pt" }}> </span>numbers,
                    browser type and version, hardware model Internet service
                    provider
                    <span style={{ letterSpacing: ".05pt" }}> </span>and/or
                    mobile carrier, and Internet Protocol (IP) address (or proxy
                    server). If you
                    <span style={{ letterSpacing: ".05pt" }}> </span>are using
                    our application(s), we may also collect information about
                    the phone
                    <span style={{ letterSpacing: ".05pt" }}> </span>network
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    associated
                    <span style={{ letterSpacing: ".1pt" }}> </span>with
                    <span style={{ letterSpacing: ".1pt" }}> </span>your
                    <span style={{ letterSpacing: ".1pt" }}> </span>mobile
                    <span style={{ letterSpacing: ".1pt" }}> </span>device,
                    <span style={{ letterSpacing: ".1pt" }}> </span>your
                    <span style={{ letterSpacing: ".1pt" }}> </span>mobile
                    <span style={{ letterSpacing: ".1pt" }}> </span>device’s
                    <span style={{ letterSpacing: ".1pt" }}> </span>operating
                    <span style={{ letterSpacing: ".05pt" }}> </span>system or
                    platform, the type of mobile device you use, your mobile
                    device’s unique
                    <span style={{ letterSpacing: "-1.5pt" }}></span>device
                    <span style={{ letterSpacing: "-.05pt" }}> </span>ID, and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information about
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    features
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    application(s) you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    accessed.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Push Notifications.{" "}
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may request to send you push notifications regarding your
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>account
                    or certain features of the application(s). If you wish to
                    opt out from
                    <span style={{ letterSpacing: ".05pt" }}> </span>receiving
                    these types of communications, you may turn them off in your
                    device's
                    <span style={{ letterSpacing: ".05pt" }}> </span>settings.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    This information is primarily needed to maintain the
                    security and operation of our
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    application(s),
                    <span style={{ letterSpacing: "-.1pt" }}> </span>for
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    troubleshooting,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.1pt" }}> </span>for
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    <span style={{ letterSpacing: "-.1pt" }}> </span>internal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    analytics
                    <span style={{ letterSpacing: "-.1pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    reporting
                    <span style={{ letterSpacing: "-.1pt" }}> </span>purposes.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    All personal information that you provide to us must be
                    true, complete, and accurate, and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>must
                    <span style={{ letterSpacing: "-.05pt" }}> </span>notify us
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>changes to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <h2>
                  <span style={{ fontSize: "10.5pt", lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </h2>
                <h2>Information automatically collected</h2>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      Some information — such as your Internet Protocol (IP)
                      address and/or
                      <span style={{ letterSpacing: ".05pt" }}> </span>browser
                      and device characteristics — is collected automatically
                      when you visit our
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>
                      Services.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We automatically collect certain information when you visit,
                    use, or navigate the Services.
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    This information does not reveal your specific identity
                    (like your name or contact
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information) but may include device and usage information,
                    such as your IP address,
                    <span style={{ letterSpacing: ".05pt" }}> </span>browser and
                    device characteristics, operating system, language
                    preferences, referring
                    <span style={{ letterSpacing: ".05pt" }}> </span>URLs,
                    device name, country, location, information about how and
                    when you use our
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services,
                    and other technical information. This information is
                    primarily needed to maintain
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>the
                    security and operation of our Services, and for our internal
                    analytics and reporting
                    <span style={{ letterSpacing: ".05pt" }}> </span>purposes.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Like many businesses, we also collect information through
                    cookies and similar
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    technologies. You can find out more about this in our Cookie
                    Notice:
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    https://atlas.space/cookies.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The<span style={{ letterSpacing: "-.05pt" }}> </span>
                    information we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>collect
                    includes:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Log and Usage Data.{" "}
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Log and usage data is service-related, diagnostic, usage,
                    <span style={{ letterSpacing: ".05pt" }}> </span>and
                    performance information our servers automatically collect
                    when you access or
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>use our
                    Services and which we record in log files. Depending on how
                    you interact
                    <span style={{ letterSpacing: ".05pt" }}> </span>with us,
                    this log data may include your IP address, device
                    information, browser
                    <span style={{ letterSpacing: ".05pt" }}> </span>type, and
                    settings and information about your activity in the Services
                    (such as the
                    <span style={{ letterSpacing: ".05pt" }}> </span>date/time
                    stamps associated with your usage, pages and files viewed,
                    searches,
                    <span style={{ letterSpacing: ".05pt" }}> </span>and other
                    actions you take such as which features you use), device
                    event
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information (such as system activity, error reports
                    (sometimes called "crash
                    <span style={{ letterSpacing: ".05pt" }}> </span>dumps"),
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>hardware
                    settings).
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Device Data.{" "}
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We collect device data such as information about your
                    computer,
                    <span style={{ letterSpacing: ".05pt" }}> </span>phone,
                    tablet, or other device you use to access the Services.
                    Depending on the
                    <span style={{ letterSpacing: ".05pt" }}> </span>device
                    used, this device data may include information such as your
                    IP address (or
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>proxy
                    server), device and application identification numbers,
                    location, browser
                    <span style={{ letterSpacing: ".05pt" }}> </span>type,
                    hardware model, Internet service provider and/or mobile
                    carrier, operating
                    <span style={{ letterSpacing: ".05pt" }}> </span>system,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>system
                    configuration
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Location Data.{" "}
                    </span>
                  </i>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We collect location data such as information about your
                    device's
                    <span style={{ letterSpacing: ".05pt" }}> </span>location,
                    which can be either precise or imprecise. How much
                    information we
                    <span style={{ letterSpacing: ".05pt" }}> </span>collect
                    depends on the type and settings of the device you use to
                    access the
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services.
                    For example, we may use GPS and other technologies to
                    collect
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    geolocation data that tells us your current location (based
                    on your IP address). You
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>can opt
                    out of allowing us to collect this information either by
                    refusing access to the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    information or by disabling your Location setting on your
                    device. However, if you
                    <span style={{ letterSpacing: ".05pt" }}> </span>choose
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to opt
                    <span style={{ letterSpacing: "-.05pt" }}></span>out, you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be able
                    <span style={{ letterSpacing: "-.05pt" }}></span>to use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>certain
                    aspects
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of the
                    <span style={{ letterSpacing: "-.05pt" }}></span>Services.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Information collected when you use our Facebook
                      application(s).{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We by default
                    <span style={{ letterSpacing: ".05pt" }}></span>access your
                    Facebook basic account information, including your name,
                    email, gender,
                    <span style={{ letterSpacing: ".05pt" }}> </span>birthday,
                    current city, and profile picture URL, as well as other
                    information that you
                    <span style={{ letterSpacing: ".05pt" }}> </span>choose to
                    make public. We may also request access to other permissions
                    related to your
                    <span style={{ letterSpacing: "-1.5pt" }}></span>account,
                    such as friends, check-ins, and likes, and you may choose to
                    grant or deny us
                    <span style={{ letterSpacing: ".05pt" }}> </span>access to
                    each individual permission. For more information regarding
                    Facebook
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    permissions,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>refer
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="https://developers.facebook.com/docs/facebook-login/permissions">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        Facebook Permissions
                        <span style={{ letterSpacing: "-.05pt" }}> </span>
                        Reference{" "}
                      </span>
                    </a>
                    page.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <b>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <br />
                  </span>
                </b>
                <p className="MsoNormal">
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <h1 style={{ marginLeft: "0in", textIndent: "0in" }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </h1>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                    </i>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We process your information to provide, improve, and
                      administer our Services,
                      <span style={{ letterSpacing: ".05pt" }}> </span>
                      communicate
                      <span style={{ letterSpacing: "-.1pt" }}> </span>with
                      <span style={{ letterSpacing: "-.05pt" }}> </span>you,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>for
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      security
                      <span style={{ letterSpacing: "-.05pt" }}> </span>and
                      <span style={{ letterSpacing: "-.05pt" }}> </span>fraud
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      prevention,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>and
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>comply
                      <span style={{ letterSpacing: "-.05pt" }}></span>with
                      <span style={{ letterSpacing: "-.05pt" }}> </span>law.
                      <span style={{ letterSpacing: "-.05pt" }}> </span>We
                      <span style={{ letterSpacing: "-.1pt" }}> </span>may
                      <span style={{ letterSpacing: "-1.45pt" }}> </span>also
                      <span style={{ letterSpacing: "-.05pt" }}></span>process
                      <span style={{ letterSpacing: "-.05pt" }}> </span>your
                      information
                      <span style={{ letterSpacing: "-.05pt" }}> </span>for
                      other
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      purposes with
                      <span style={{ letterSpacing: "-.05pt" }}> </span>your
                      consent
                    </span>
                  </i>
                </p>
                <h3>
                  <span style={{ lineHeight: "150%" }}>
                    We process your personal information for a variety of
                    reasons, depending on how
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>interact
                    <span style={{ letterSpacing: "-.05pt" }}></span>with our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Services, including:
                  </span>
                </h3>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To<span style={{ letterSpacing: "-.15pt" }}> </span>
                      facilitate
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      account
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      creation
                      <span style={{ letterSpacing: "-.15pt" }}> </span>and
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      authentication
                      <span style={{ letterSpacing: "-.1pt" }}> </span>and
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      otherwise
                      <span style={{ letterSpacing: "-.1pt" }}> </span>manage
                      <span style={{ letterSpacing: "-.15pt" }}></span>user
                      <span style={{ letterSpacing: "-1.45pt" }}> </span>
                      accounts.{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your information so you can create and log in
                    to your
                    <span style={{ letterSpacing: ".05pt" }}> </span>account,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>well as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>keep
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>working
                    order.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To<span style={{ letterSpacing: "-.1pt" }}> </span>
                      deliver
                      <span style={{ letterSpacing: "-.1pt" }}></span>and
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      facilitate
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      delivery
                      <span style={{ letterSpacing: "-.1pt" }}></span>of
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      services
                      <span style={{ letterSpacing: "-.1pt" }}> </span>to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>the
                      <span style={{ letterSpacing: "-.1pt" }}> </span>user.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We<span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>process
                    <span style={{ letterSpacing: "-.1pt" }}></span>your
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>provide
                    you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    requested service.
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <b>
                    <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                  </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To respond to user inquiries/offer support to users.{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information to respond to your inquiries and solve any
                    potential issues you might
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>have
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    requested
                    <span style={{ letterSpacing: "-.05pt" }}> </span>service.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To<span style={{ letterSpacing: "-.15pt" }}> </span>send
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      administrative
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      information
                      <span style={{ letterSpacing: "-.1pt" }}> </span>to
                      <span style={{ letterSpacing: "-.15pt" }}> </span>you.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We<span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>process
                    <span style={{ letterSpacing: "-.15pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>send you
                    details about our products and services, changes to our
                    terms and
                    <span style={{ letterSpacing: ".05pt" }}> </span>policies,
                    <span style={{ letterSpacing: "-.05pt" }}></span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>other
                    similar
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <b>
                    <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                  </b>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To<span style={{ letterSpacing: "-.1pt" }}> </span>
                      fulfill
                      <span style={{ letterSpacing: "-.05pt" }}></span>and
                      <span style={{ letterSpacing: "-.1pt" }}> </span>manage
                      <span style={{ letterSpacing: "-.05pt" }}> </span>your
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      orders.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>fulfill
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>manage
                    your orders, payments, returns, and exchanges made through
                    the
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To enable user-to-user communications.{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your information if
                    <span style={{ letterSpacing: ".05pt" }}> </span>you choose
                    to use any of our offerings that allow for communication
                    with another
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>user.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To<span style={{ letterSpacing: "-.15pt" }}> </span>
                      request
                      <span style={{ letterSpacing: "-.15pt" }}></span>
                      feedback.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>process
                    <span style={{ letterSpacing: "-.15pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.15pt" }}> </span>when
                    <span style={{ letterSpacing: "-.1pt" }}> </span>necessary
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>feedback
                    and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to contact
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you about
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your use
                    <span style={{ letterSpacing: "-.05pt" }}></span>of our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Services.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To<span style={{ letterSpacing: "-.15pt" }}> </span>send
                      <span style={{ letterSpacing: "-.15pt" }}> </span>you
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      marketing
                      <span style={{ letterSpacing: "-.15pt" }}> </span>and
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      promotional
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      communications.
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.15pt" }}> </span>process
                    <span style={{ letterSpacing: "-1.45pt" }}></span>the
                    personal information you send to us for our marketing
                    purposes, if this is in
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    accordance with your marketing preferences. You can opt out
                    of our marketing
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    <span style={{ letterSpacing: "-.05pt" }}>
                      emails at any time. For{" "}
                    </span>
                    more information, see "WHAT ARE YOUR PRIVACY
                    <span style={{ letterSpacing: ".05pt" }}></span>RIGHTS?"
                    <span style={{ letterSpacing: "-.05pt" }}> </span>below.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To deliver targeted advertising to you.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process your information to
                    <span style={{ letterSpacing: ".05pt" }}> </span>develop and
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    display personalized content and advertising tailored to
                    your interests,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    location, and more. For more information see our Cookie
                    Notice:
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    <a href="https://atlas.space/cookies">
                      https://atlas.space/cookies
                    </a>
                    .
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To<span style={{ letterSpacing: "-.1pt" }}> </span>
                      protect
                      <span style={{ letterSpacing: "-.1pt" }}></span>our
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      Services.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>process
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.1pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>part
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    <span style={{ letterSpacing: "-.1pt" }}> </span>efforts
                    <span style={{ letterSpacing: "-1.45pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>keep our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Services
                    safe
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    secure,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    including fraud monitoring
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    prevention.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To identify usage trends.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process information about how you use our
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>better
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    understand
                    <span style={{ letterSpacing: "-.05pt" }}> </span>how
                    <span style={{ letterSpacing: "-.1pt" }}> </span>they
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are
                    <span style={{ letterSpacing: "-.05pt" }}> </span>being
                    <span style={{ letterSpacing: "-.05pt" }}> </span>used
                    <span style={{ letterSpacing: "-.1pt" }}> </span>so
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>improve
                    <span style={{ letterSpacing: "-.1pt" }}></span>them.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To<span style={{ letterSpacing: "-.15pt" }}> </span>
                      determine
                      <span style={{ letterSpacing: "-.15pt" }}> </span>the
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      effectiveness
                      <span style={{ letterSpacing: "-.15pt" }}> </span>of
                      <span style={{ letterSpacing: "-.15pt" }}> </span>our
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      marketing
                      <span style={{ letterSpacing: "-.15pt" }}></span>and
                      <span style={{ letterSpacing: "-.15pt" }}> </span>
                      promotional
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      campaigns.
                      <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your information to better understand how to
                    provide marketing
                    <span style={{ letterSpacing: ".05pt" }}></span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    promotional
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    campaigns that
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are most
                    <span style={{ letterSpacing: "-.05pt" }}> </span>relevant
                    to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you.
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      To save or protect an individual's vital interest.{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information when necessary to save or protect an
                    individual’s vital interest, such as
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>prevent
                    <span style={{ letterSpacing: "-.05pt" }}> </span>harm.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      To moderate user-to-user communications.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    In case of unethical or unlawful
                    <span style={{ letterSpacing: ".05pt" }}> </span>actions,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    engagements
                    <span style={{ letterSpacing: "-.1pt" }}> </span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    interactions
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                    <span style={{ letterSpacing: "-.1pt" }}> </span>any
                    <span style={{ letterSpacing: "-.1pt" }}> </span>kind
                    <span style={{ letterSpacing: "-.05pt" }}> </span>that
                    <span style={{ letterSpacing: "-.1pt" }}> </span>is
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    implemented
                    <span style={{ letterSpacing: "-.1pt" }}> </span>by
                    <span style={{ letterSpacing: "-.1pt" }}> </span>any
                    <span style={{ letterSpacing: "-.1pt" }}> </span>user,
                    <span style={{ letterSpacing: "-1.45pt" }}></span>they are
                    notified, kicked out, restricted or banned in accordance
                    with their actions
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>related
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    website,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>platform
                    or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>users.
                  </span>
                </p>
                <i>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <br />
                  </span>
                </i>
                <p className="MsoNormal">
                  <i>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      &nbsp;
                    </span>
                  </i>
                </p>
                <h1>
                  <span lang="TR">3. </span>WHAT LEGAL BASES DO WE RELY ON TO
                  PROCESS YOUR INFORMATION?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We only process your personal information when we believe
                      it is necessary and
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>we have
                      <span style={{ letterSpacing: ".05pt" }}> </span>a
                      <span style={{ letterSpacing: ".05pt" }}> </span>valid
                      <span style={{ letterSpacing: ".05pt" }}> </span>legal
                      <span style={{ letterSpacing: ".05pt" }}> </span>reason
                      <span style={{ letterSpacing: ".05pt" }}> </span>(i.e.,
                      <span style={{ letterSpacing: ".05pt" }}> </span>legal
                      <span style={{ letterSpacing: ".05pt" }}> </span>basis)
                      <span style={{ letterSpacing: ".05pt" }}> </span>to
                      <span style={{ letterSpacing: ".05pt" }}> </span>do
                      <span style={{ letterSpacing: ".05pt" }}> </span>so
                      <span style={{ letterSpacing: ".05pt" }}> </span>under
                      <span style={{ letterSpacing: ".05pt" }}> </span>
                      applicable
                      <span style={{ letterSpacing: ".05pt" }}> </span>law,
                      <span style={{ letterSpacing: ".05pt" }}> </span>like
                      <span style={{ letterSpacing: ".05pt" }}> </span>with
                      <span style={{ letterSpacing: ".05pt" }}> </span>your
                      consent, to comply with laws, to provide you with services
                      to enter into or fulfill our
                      <span style={{ letterSpacing: ".05pt" }}> </span>
                      contractual
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      obligations, to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      protect your
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      rights, or
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to
                      fulfill
                      <span style={{ letterSpacing: "-.05pt" }}> </span>our
                      legitimate business
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      interests.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <h4>
                  <u>
                    <span style={{ lineHeight: "150%" }}>If</span>
                  </u>
                  <span style={{ lineHeight: "150%", letterSpacing: "-.05pt" }}>
                    {" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    y
                    <u>
                      ou are<span style={{ letterSpacing: "-.05pt" }}> </span>
                      located in
                      <span style={{ letterSpacing: "-.05pt" }}> </span>the EU
                      <span style={{ letterSpacing: "-.05pt" }}> </span>or UK,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>this
                      section
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      applies to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    </u>
                    .
                  </span>
                </h4>
                <p className="MsoBodyText">
                  <b>
                    <i>
                      <span
                        style={{
                          fontSize: "10.5pt",
                          lineHeight: "150%",
                          fontFamily: '"Arial",sans-serif',
                        }}
                      >
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The General Data Protection Regulation (GDPR) and UK GDPR
                    require us to explain the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>valid
                    legal bases we rely on in order to process your personal
                    information. As such, we
                    <span style={{ letterSpacing: ".05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>rely on
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    following
                    <span style={{ letterSpacing: "-.05pt" }}> </span>legal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>bases to
                    <span style={{ letterSpacing: "-.05pt" }}></span>process
                    your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Consent.
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process your information if you have given us
                    permission (i.e.,
                    <span style={{ letterSpacing: ".05pt" }}> </span>consent)
                    <span style={{ letterSpacing: "-.15pt" }}></span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>use
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.1pt" }}> </span>for
                    <span style={{ letterSpacing: "-.1pt" }}> </span>a
                    <span style={{ letterSpacing: "-.15pt" }}> </span>specific
                    <span style={{ letterSpacing: "-.1pt" }}></span>purpose.
                    <span style={{ letterSpacing: "-.2pt" }}> </span>You
                    <span style={{ letterSpacing: "-.1pt" }}> </span>can
                    <span style={{ letterSpacing: "-.1pt" }}> </span>withdraw
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}></span>consent at
                    <span style={{ letterSpacing: "-.05pt" }}> </span>any time.
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Learn more
                    <span style={{ letterSpacing: "-.05pt" }}> </span>about
                    withdrawing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    consent.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Performance
                      <span style={{ letterSpacing: "-.1pt" }}> </span>of
                      <span style={{ letterSpacing: "-.1pt" }}> </span>a
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      Contract.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process
                    <span style={{ letterSpacing: "-.1pt" }}></span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.1pt" }}> </span>when
                    <span style={{ letterSpacing: "-.1pt" }}> </span>we
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>believe
                    it is necessary to fulfill our contractual obligations to
                    you, including
                    <span style={{ letterSpacing: ".05pt" }}> </span>providing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or at
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>prior to
                    <span style={{ letterSpacing: "-.05pt" }}></span>entering
                    into
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a contract
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with you.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <b>
                    <span style={{ lineHeight: "150%" }}>
                      Legitimate Interests.{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "150%" }}>
                    We may process your information when we believe it is
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    reasonably necessary to achieve our legitimate business
                    interests and those
                    <span style={{ letterSpacing: ".05pt" }}> </span>interests
                    do not outweigh your interests and fundamental rights and
                    freedoms. For
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>example,
                    we may process your personal information for some of the
                    purposes
                    <span style={{ letterSpacing: ".05pt" }}> </span>described
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>order to:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    S
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    end users information about special offers and discounts on
                    our products
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>services
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Develop and display personalized and relevant advertising
                    content for our
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>users
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Analyze how our Services are used so we can improve them to
                    engage and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>retain
                    <span style={{ letterSpacing: "-.05pt" }}></span>users
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Support<span style={{ letterSpacing: "-.05pt" }}> </span>
                    our marketing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    activities
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Diagnose<span style={{ letterSpacing: "-.05pt" }}> </span>
                    problems and/or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>prevent
                    fraudulent
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    activities
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: ".5in" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Understand how our users use our products and services so we
                    can
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>improve
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    experience
                  </span>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Legal Obligations.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process your information where we believe it is
                    <span style={{ letterSpacing: ".05pt" }}> </span>necessary
                    for compliance with our legal obligations, such as to
                    cooperate with a
                    <span style={{ letterSpacing: ".05pt" }}> </span>law
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    enforcement
                    <span style={{ letterSpacing: "-.1pt" }}> </span>body
                    <span style={{ letterSpacing: "-.1pt" }}> </span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    regulatory
                    <span style={{ letterSpacing: "-.1pt" }}> </span>agency,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>exercise
                    <span style={{ letterSpacing: "-.1pt" }}> </span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>defend
                    <span style={{ letterSpacing: "-.1pt" }}> </span>our
                    <span style={{ letterSpacing: "-.1pt" }}> </span>legal
                    <span style={{ letterSpacing: "-.1pt" }}> </span>rights,
                    <span style={{ letterSpacing: "-.1pt" }}></span>or
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    disclose
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    evidence
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    litigation
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in which
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we are
                    <span style={{ letterSpacing: "-.05pt" }}></span>involved.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Vital Interests.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process your information where we believe it is
                    necessary
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>to
                    protect your vital interests or the vital interests of a
                    third party, such as situations
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>
                    involving
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    potential
                    <span style={{ letterSpacing: "-.05pt" }}> </span>threats to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the safety
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>person.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h4>
                  <u>
                    <span style={{ lineHeight: "150%" }}>If</span>
                  </u>
                  <span style={{ lineHeight: "150%", letterSpacing: "-.05pt" }}>
                    {" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    y
                    <u>
                      ou are<span style={{ letterSpacing: "-.05pt" }}> </span>
                      located in
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      Canada, this
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      section applies
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to you
                    </u>
                    .
                  </span>
                </h4>
                <p className="MsoBodyText">
                  <b>
                    <i>
                      <span
                        style={{
                          fontSize: "10.5pt",
                          lineHeight: "150%",
                          fontFamily: '"Arial",sans-serif',
                        }}
                      >
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may process your information if you have given us
                    specific permission (i.e., express
                    <span style={{ letterSpacing: ".05pt" }}> </span>consent) to
                    use your personal information for a specific purpose, or in
                    situations where
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    permission
                    <span style={{ letterSpacing: "-.05pt" }}> </span>can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    <span style={{ letterSpacing: "-.1pt" }}> </span>inferred
                    <span style={{ letterSpacing: "-.05pt" }}></span>(i.e.,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>implied
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    consent).
                    <span style={{ letterSpacing: "-.2pt" }}> </span>You
                    <span style={{ letterSpacing: "-.05pt" }}> </span>can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>withdraw
                    <span style={{ letterSpacing: "-.05pt" }}></span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>consent
                    <span style={{ letterSpacing: "-.05pt" }}> </span>at
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>time.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    In some exceptional cases, we may be legally permitted under
                    applicable law to process
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>without
                    your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>consent,
                    including,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    example:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If collection is clearly in the interests of an individual
                    and consent cannot be
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>obtained
                    <span style={{ letterSpacing: "-.05pt" }}></span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a timely
                    <span style={{ letterSpacing: "-.05pt" }}> </span>way
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    For<span style={{ letterSpacing: "-.05pt" }}> </span>
                    investigations and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>fraud
                    detection
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    prevention
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    For<span style={{ letterSpacing: "-.05pt" }}> </span>
                    business transactions
                    <span style={{ letterSpacing: "-.05pt" }}> </span>provided
                    certain
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    conditions are
                    <span style={{ letterSpacing: "-.05pt" }}> </span>met
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If it is contained in a witness statement and the collection
                    is necessary to assess,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>process,
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>settle an
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    insurance claim
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    For<span style={{ letterSpacing: "-.05pt" }}> </span>
                    identifying injured,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>ill, or
                    <span style={{ letterSpacing: "-.05pt" }}></span>deceased
                    persons
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    communicating
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with next
                    of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>kin
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If we have reasonable grounds to believe an individual has
                    been, is, or may be
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>victim
                    <span style={{ letterSpacing: "-.05pt" }}></span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    financial abuse
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If it is reasonable to expect collection and use with
                    consent would compromise the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    availability or the accuracy of the information and the
                    collection is reasonable for
                    <span style={{ letterSpacing: ".05pt" }}> </span>purposes
                    related to investigating a breach of an agreement or a
                    contravention of
                    <span style={{ letterSpacing: ".05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>laws
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of Canada
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>province
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If<span style={{ letterSpacing: "-.05pt" }}> </span>
                    disclosure
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>required
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>comply
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    subpoena,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>warrant,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>court
                    <span style={{ letterSpacing: "-.05pt" }}> </span>order,
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>rules
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>court
                    <span style={{ letterSpacing: "-.05pt" }}></span>relating to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    production
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of records
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If it was produced by an individual in the course of their
                    employment, business, or
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    profession and the collection is consistent with the
                    purposes for which the
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>was
                    <span style={{ letterSpacing: "-.05pt" }}> </span>produced
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If<span style={{ letterSpacing: "-.05pt" }}> </span>the
                    collection
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is solely
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    journalistic,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    artistic, or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>literary
                    purposes
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If<span style={{ letterSpacing: "-.05pt" }}> </span>the
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    publicly
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    available and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    specified
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by the
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    regulations
                  </span>
                </p>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "150%",
                    fontFamily: '"Arial",sans-serif',
                  }}
                >
                  <br />
                </span>
                <p className="MsoNormal">
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">4. </span>WHEN AND WITH WHOM DO WE SHARE YOUR
                  PERSONAL INFORMATION?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We may share information in specific situations described
                      in this section and/or
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>with
                      <span style={{ letterSpacing: "-.05pt" }}> </span>the
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      following categories
                      <span style={{ letterSpacing: "-.05pt" }}> </span>of third
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      parties.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Vendors, Consultants, and Other Third-Party Service
                      Providers.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may share your
                    <span style={{ letterSpacing: ".05pt" }}> </span>data with
                    third-party vendors, service providers, contractors, or
                    agents ("
                    <b>third parties</b>")
                    <span style={{ letterSpacing: ".05pt" }}></span>who perform
                    services for us or on our behalf and require access to such
                    information to do
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>that work.
                    We have contracts in place with our third parties, which are
                    designed to help
                    <span style={{ letterSpacing: ".05pt" }}> </span>safeguard
                    your personal information. This means that they cannot do
                    anything with your
                    <span style={{ letterSpacing: ".05pt" }}></span>personal
                    information unless we have instructed them to do it. They
                    will also not share
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    personal information with any organization apart from us.
                    They also commit to
                    <span style={{ letterSpacing: ".05pt" }}> </span>protect the
                    data they hold on our behalf and to retain it for the period
                    we instruct. The
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    categories
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of third
                    <span style={{ letterSpacing: "-.05pt" }}> </span>parties we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may share
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with are
                    <span style={{ letterSpacing: "-.05pt" }}></span>as follows:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Ad<span style={{ letterSpacing: "-.05pt" }}> </span>
                    Networks
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Cloud<span style={{ letterSpacing: "-.05pt" }}> </span>
                    Computing Services
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Communication
                    <span style={{ letterSpacing: "-.2pt" }}> </span>&amp;
                    <span style={{ letterSpacing: "-.2pt" }}> </span>
                    Collaboration
                    <span style={{ letterSpacing: "-.25pt" }}> </span>Tools
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Data<span style={{ letterSpacing: "-.05pt" }}> </span>
                    Storage Service
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Providers
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Data<span style={{ letterSpacing: "-.35pt" }}> </span>
                    Analytics Services
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Payment<span style={{ letterSpacing: "-.05pt" }}> </span>
                    Processors
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                    P
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    erformance
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    Monitoring
                    <span style={{ letterSpacing: "-.3pt" }}> </span>Tools
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Retargeting
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Platforms
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Social<span style={{ letterSpacing: "-.05pt" }}> </span>
                    Networks
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    Testing
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.3pt",
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.05pt",
                    }}
                  >
                    Tools
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Website<span style={{ letterSpacing: "-.1pt" }}> </span>
                    Hosting
                    <span style={{ letterSpacing: "-.05pt" }}></span>Service
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Providers
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    User<span style={{ letterSpacing: "-.35pt" }}> </span>
                    Account Registration
                    <span style={{ letterSpacing: "-.05pt" }}> </span>&amp;
                    <span style={{ letterSpacing: "-.3pt" }}> </span>
                    Authentication
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Services
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Product<span style={{ letterSpacing: "-.15pt" }}> </span>
                    Engineering
                    <span style={{ letterSpacing: "-.15pt" }}> </span>&amp;
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Design
                    <span style={{ letterSpacing: "-.25pt" }}> </span>Tools
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>also
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>need to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>share
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in the
                    <span style={{ letterSpacing: "-.05pt" }}></span>following
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    situations:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Business Transfers.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may share or transfer your information in connection
                    <span style={{ letterSpacing: ".05pt" }}> </span>with,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>during
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    negotiations
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>merger,
                    <span style={{ letterSpacing: "-.1pt" }}></span>sale
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                    <span style={{ letterSpacing: "-.1pt" }}> </span>company
                    <span style={{ letterSpacing: "-.1pt" }}> </span>assets,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    financing,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>or
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    acquisition
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>all
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a portion
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>business
                    to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>another
                    <span style={{ letterSpacing: "-.05pt" }}></span>company.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      When<span style={{ letterSpacing: "-.1pt" }}> </span>we
                      <span style={{ letterSpacing: "-.05pt" }}> </span>use
                      <span style={{ letterSpacing: "-.1pt" }}> </span>Google
                      <span style={{ letterSpacing: "-.05pt" }}></span>Maps
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      Platform
                      <span style={{ letterSpacing: "-.25pt" }}> </span>APIs.
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>share
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-1.45pt" }}></span>certain
                    Google Maps Platform APIs (e.g., Google Maps API, Places
                    API). We
                    <span style={{ letterSpacing: ".05pt" }}> </span>obtain and
                    store on your device ("cache") your location for three (3)
                    months. You
                    <span style={{ letterSpacing: ".05pt" }}> </span>may revoke
                    your consent anytime by contacting us at the contact details
                    provided
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>at
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>end of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>this
                    document.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Affiliates.
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>share
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    affiliates,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>which case
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>require
                    those affiliates to honor this privacy notice. Affiliates
                    include our parent
                    <span style={{ letterSpacing: ".05pt" }}></span>company and
                    any subsidiaries, joint venture partners, or other companies
                    that we
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>control
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>that are
                    <span style={{ letterSpacing: "-.05pt" }}> </span>under
                    common
                    <span style={{ letterSpacing: "-.05pt" }}> </span>control
                    with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Business Partners.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may share your information with our business partners to
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>offer
                    <span style={{ letterSpacing: "-.05pt" }}></span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>certain
                    products,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    services, or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    promotions.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Other Users.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    When you share personal information (for example, by posting
                    <span style={{ letterSpacing: ".05pt" }}> </span>comments,
                    contributions,
                    <span style={{ letterSpacing: ".05pt" }}> </span>or other
                    <span style={{ letterSpacing: ".05pt" }}></span>content
                    <span style={{ letterSpacing: ".05pt" }}> </span>to the
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services)
                    or
                    <span style={{ letterSpacing: ".05pt" }}> </span>otherwise
                    <span style={{ letterSpacing: ".05pt" }}> </span>interact
                    <span style={{ letterSpacing: ".05pt" }}> </span>with public
                    areas of the Services, such personal information may be
                    viewed by all
                    <span style={{ letterSpacing: ".05pt" }}> </span>users and
                    may be publicly made available outside the Services in
                    perpetuity. If you
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>interact
                    with other users of our Services and register for our
                    Services through a
                    <span style={{ letterSpacing: ".05pt" }}> </span>social
                    network (such as Facebook), your contacts on the social
                    network will see
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.15pt" }}> </span>name,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>profile
                    <span style={{ letterSpacing: "-.15pt" }}></span>photo,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    descriptions
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    activity.
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    Similarly,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>other
                    <span style={{ letterSpacing: "-.1pt" }}> </span>users
                    <span style={{ letterSpacing: "-.15pt" }}> </span>will
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>be able
                    to view descriptions of your activity, communicate with you
                    within our
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>view your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>profile.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Offer Wall.{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Our application(s) may display a third-party hosted "offer
                    wall." Such
                    <span style={{ letterSpacing: ".05pt" }}> </span>an offer
                    wall allows third-party advertisers to offer virtual
                    currency, gifts, or other
                    <span style={{ letterSpacing: ".05pt" }}> </span>items to
                    users in return for the acceptance and completion of an
                    advertisement
                    <span style={{ letterSpacing: ".05pt" }}></span>offer. Such
                    an offer wall may appear in our application(s) and be
                    displayed to you
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>based on
                    certain data, such as your geographic area or demographic
                    information.
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>When you
                    click on an offer wall, you will be brought to an external
                    website
                    <span style={{ letterSpacing: ".05pt" }}> </span>belonging
                    to other persons and will leave our application(s). A unique
                    identifier,
                    <span style={{ letterSpacing: ".05pt" }}> </span>such as
                    your user ID, will be shared with the offer wall provider in
                    order to prevent
                    <span style={{ letterSpacing: "-1.55pt" }}></span>fraud
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>properly
                    credit
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>relevant
                    reward.
                  </span>
                </p>
                <span
                  lang="TR"
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "150%",
                    fontFamily: '"Arial",sans-serif',
                  }}
                >
                  <br />
                </span>
                <p className="MsoNormal">
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">5. </span>WHAT IS OUR STANCE ON THIRD-PARTY
                  WEBSITES?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We are not responsible for the safety of any information
                      that you share with
                      <span style={{ letterSpacing: ".05pt" }}> </span>third
                      parties that we may link to or who advertise on our
                      Services, but are not affiliated
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>with,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>our
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      Services.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The Services, including our offer wall, may link to
                    third-party websites, online services, or
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    mobile applications and/or contain advertisements from third
                    parties that are not affiliated
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>with us
                    and which may link to other websites, services, or
                    applications. Accordingly, we
                    <span style={{ letterSpacing: ".05pt" }}> </span>do not make
                    any guarantee regarding any such third parties, and we will
                    not be liable for
                    <span style={{ letterSpacing: ".05pt" }}> </span>any loss or
                    damage caused by the use of such third-party websites,
                    services, or
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    applications. The inclusion of a link towards a third-party
                    website, service, or application
                    <span style={{ letterSpacing: ".05pt" }}> </span>does not
                    imply an endorsement by us. We cannot guarantee the safety
                    and privacy of
                    <span style={{ letterSpacing: ".05pt" }}> </span>data you
                    provide to any third parties. Any data collected by third
                    parties is not covered by
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>this
                    privacy notice. We are not responsible for the content or
                    privacy and security
                    <span style={{ letterSpacing: ".05pt" }}> </span>practices
                    and policies of any third parties, including other websites,
                    services, or
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    applications
                    <span style={{ letterSpacing: "-.1pt" }}> </span>that
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    <span style={{ letterSpacing: "-.05pt" }}> </span>linked
                    <span style={{ letterSpacing: "-.05pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>from
                    <span style={{ letterSpacing: "-.1pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Services.
                    <span style={{ letterSpacing: "-.15pt" }}> </span>You
                    <span style={{ letterSpacing: "-.05pt" }}> </span>should
                    <span style={{ letterSpacing: "-.05pt" }}> </span>review
                    <span style={{ letterSpacing: "-.05pt" }}></span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>policies
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    such<span style={{ letterSpacing: "-.05pt" }}> </span>
                    third parties
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    contact
                    <span style={{ letterSpacing: "-.05pt" }}> </span>them
                    directly
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to respond
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    questions.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">6. </span>DO WE USE COOKIES AND OTHER TRACKING
                  TECHNOLOGIES?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We may use cookies and other tracking technologies to
                      collect and store your
                      <span style={{ letterSpacing: "-1.55pt" }}> </span>
                      information.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may use cookies and similar tracking technologies (like
                    web beacons and pixels) to
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>access or
                    store information. Specific information about how we use
                    such technologies
                    <span style={{ letterSpacing: ".05pt" }}> </span>and how you
                    can refuse certain cookies is set out in our Cookie Notice:
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    https://atlas.space/cookies.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">7. </span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                </h1>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                    </i>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      If you choose to register
                    </span>
                  </i>
                  <i>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      {" "}
                      or log in to our Services using a social media account, we
                      may have access to certain information about you.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Our Services offer you the ability to register and log in
                    using your third-party social media
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    account details (like your Facebook or Twitter logins).
                    Where you choose to do this, we
                    <span style={{ letterSpacing: ".05pt" }}> </span>will
                    receive certain profile information about you from your
                    social media provider. The
                    <span style={{ letterSpacing: ".05pt" }}></span>profile
                    information we receive may vary depending on the social
                    media provider
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    concerned, but will often include your name, email address,
                    friends list, and profile
                    <span style={{ letterSpacing: ".05pt" }}> </span>picture, as
                    well as other information you choose to make public on such
                    a social media
                    <span style={{ letterSpacing: ".05pt" }}></span>platform. If
                    you log in using Facebook, we may also request access to
                    other permissions
                    <span style={{ letterSpacing: ".05pt" }}> </span>related to
                    your account, such as your friends, check-ins, and likes,
                    and you may choose
                    <span style={{ letterSpacing: ".05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>grant
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or deny
                    <span style={{ letterSpacing: "-.05pt" }}></span>us access
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to each
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    individual permission.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We will use the information we receive only for the purposes
                    that are described in this
                    <span style={{ letterSpacing: ".05pt" }}> </span>privacy
                    notice or that are otherwise made clear to you on the
                    relevant Services. Please
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>note that
                    we do not control, and are not responsible for, other uses
                    of your personal
                    <span style={{ letterSpacing: ".05pt" }}></span>
                    information by your third-party social media provider. We
                    recommend that you review
                    <span style={{ letterSpacing: ".05pt" }}> </span>their
                    privacy notice to understand how they collect, use, and
                    share your personal
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and how
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>set your
                    <span style={{ letterSpacing: "-.05pt" }}></span>privacy
                    preferences
                    <span style={{ letterSpacing: "-.05pt" }}> </span>on their
                    <span style={{ letterSpacing: "-.05pt" }}> </span>sites and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>apps.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">8. </span>HOW LONG DO WE KEEP YOUR
                  INFORMATION?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We keep your information for as long as necessary to
                      fulfill the purposes
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>
                      outlined
                      <span style={{ letterSpacing: "-.05pt" }}> </span>in
                      <span style={{ letterSpacing: "-.05pt" }}> </span>this
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      privacy notice
                      <span style={{ letterSpacing: "-.05pt" }}> </span>unless
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      otherwise required
                      <span style={{ letterSpacing: "-.05pt" }}> </span>by
                      <span style={{ letterSpacing: "-.05pt" }}> </span>law.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We will only keep your personal information for as long as
                    it is necessary for the
                    <span style={{ letterSpacing: ".05pt" }}> </span>purposes
                    set out in this privacy notice, unless a longer retention
                    period is required or
                    <span style={{ letterSpacing: ".05pt" }}> </span>permitted
                    by law (such as tax, accounting, or other legal
                    requirements). No purpose in
                    <span style={{ letterSpacing: ".05pt" }}> </span>this notice
                    will require us keeping your personal information for longer
                    than the period of
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>time
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>which
                    users
                    <span style={{ letterSpacing: "-.05pt" }}> </span>have an
                    <span style={{ letterSpacing: "-.05pt" }}> </span>account
                    with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    When we have no ongoing legitimate business need to process
                    your personal
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-.05pt" }}> </span>either
                    <span style={{ letterSpacing: "-.05pt" }}></span>delete
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    anonymize
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>if
                    <span style={{ letterSpacing: "-.05pt" }}> </span>this
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>possible
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>(for
                    example, because your personal information has been stored
                    in backup archives),
                    <span style={{ letterSpacing: ".05pt" }}> </span>then we
                    will securely store your personal information and isolate it
                    from any further
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    processing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>until
                    <span style={{ letterSpacing: "-.05pt" }}></span>deletion is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    possible.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">9. </span>HOW DO WE KEEP YOUR INFORMATION
                  SAFE?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We aim to protect your personal information through a
                      system of organizational
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>and
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      technical
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      security measures.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We have implemented appropriate and reasonable technical and
                    organizational security
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    measures<span style={{ letterSpacing: "-.05pt" }}> </span>
                    designed to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>protect
                    the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>security
                    of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>any
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>process.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    However, despite our safeguards and efforts to secure your
                    information, no electronic
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    transmission over the Internet or information storage
                    technology can be guaranteed to be
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>100%
                    secure, so we cannot promise or guarantee that hackers,
                    cybercriminals, or other
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    unauthorized third parties will not be able to defeat our
                    security and improperly collect,
                    <span style={{ letterSpacing: ".05pt" }}> </span>access,
                    steal, or modify your information. Although we will do our
                    best to protect your
                    <span style={{ letterSpacing: ".05pt" }}> </span>personal
                    information, transmission of personal information to and
                    from our Services is at
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>own
                    <span style={{ letterSpacing: "-.05pt" }}> </span>risk.
                    <span style={{ letterSpacing: "-.15pt" }}></span>You
                    <span style={{ letterSpacing: "-.05pt" }}> </span>should
                    <span style={{ letterSpacing: "-.05pt" }}> </span>only
                    <span style={{ letterSpacing: "-.05pt" }}> </span>access
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>within
                    <span style={{ letterSpacing: "-.05pt" }}></span>a secure
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    environment.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">10. </span>DO WE COLLECT INFORMATION FROM
                  MINORS?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      We do not knowingly collect data from or market to
                      children under 18 years of
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>age.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We do not knowingly solicit data from or market to children
                    under 18 years of age. By
                    <span style={{ letterSpacing: ".05pt" }}> </span>using the
                    Services, you represent that you are at least 18 or that you
                    are the parent or
                    <span style={{ letterSpacing: ".05pt" }}> </span>guardian
                    <span style={{ letterSpacing: "-.05pt" }}></span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>minor
                    <span style={{ letterSpacing: "-.05pt" }}></span>and consent
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such minor
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    dependent’s
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Services. If
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>we learn
                    that personal information from users less than 18 years of
                    age has been
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    collected, we will deactivate the account and take
                    reasonable measures to promptly
                    <span style={{ letterSpacing: ".05pt" }}> </span>delete such
                    data from our records. If you become aware of any data we
                    may have
                    <span style={{ letterSpacing: ".05pt" }}> </span>collected
                    <span style={{ letterSpacing: "-.05pt" }}></span>from
                    children
                    <span style={{ letterSpacing: "-.05pt" }}> </span>under age
                    <span style={{ letterSpacing: "-.05pt" }}> </span>18, please
                    <span style={{ letterSpacing: "-.05pt" }}> </span>contact us
                    <span style={{ letterSpacing: "-.05pt" }}> </span>at{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space.
                      </span>
                    </a>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">11. </span>WHAT ARE YOUR PRIVACY RIGHTS?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>In Short: </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      In some regions, such as the European Economic Area (EEA),
                      United Kingdom
                      <span style={{ letterSpacing: "-1.55pt" }}> </span>(UK),
                      and Canada, you have rights that allow you greater access
                      to and control over your
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>
                      personal
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      information.
                      <span style={{ letterSpacing: "-.15pt" }}> </span>You
                      <span style={{ letterSpacing: "-.05pt" }}> </span>may
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      review,
                      <span style={{ letterSpacing: "-.1pt" }}></span>change,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>or
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      terminate
                      <span style={{ letterSpacing: "-.05pt" }}> </span>your
                      <span style={{ letterSpacing: "-.1pt" }}> </span>account
                      <span style={{ letterSpacing: "-.05pt" }}> </span>at
                      <span style={{ letterSpacing: "-.05pt" }}> </span>any
                      <span style={{ letterSpacing: "-.05pt" }}> </span>time.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    In some regions (like the EEA, UK, and Canada), you have
                    certain rights under
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    applicable data protection laws. These may include the right
                    (i) to request access and
                    <span style={{ letterSpacing: ".05pt" }}> </span>obtain a
                    copy of your personal information, (ii) to request
                    rectification or erasure; (iii) to
                    <span style={{ letterSpacing: ".05pt" }}> </span>restrict
                    the processing of your personal information; and (iv) if
                    applicable, to data
                    <span style={{ letterSpacing: ".05pt" }}></span>
                    portability. In certain circumstances, you may also have the
                    right to object to the
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    processing
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.1pt" }}></span>
                    information.
                    <span style={{ letterSpacing: "-.15pt" }}> </span>You
                    <span style={{ letterSpacing: "-.05pt" }}> </span>can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>make
                    <span style={{ letterSpacing: "-.1pt" }}> </span>such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}></span>by
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    contacting
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>by using
                    the contact details provided in the section "HOW CAN YOU
                    CONTACT US
                    <span style={{ letterSpacing: ".05pt" }}> </span>ABOUT
                    <span style={{ letterSpacing: "-.25pt" }}> </span>THIS
                    <span style={{ letterSpacing: "-.05pt" }}> </span>NOTICE?"
                    below.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We will consider and act upon any request in accordance with
                    applicable data protection
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>laws.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    If<span style={{ letterSpacing: "-.05pt" }}> </span>you are
                    <span style={{ letterSpacing: "-.05pt" }}> </span>located in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the EEA
                    <span style={{ letterSpacing: "-.35pt" }}> </span>or UK
                  </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    {" "}
                    and you believe we are unlawfully processing your personal
                    information, you also have the right to complain to your{" "}
                    <a href="https://ec.europa.eu/newsroom/article29/items/612080">
                      Member State data protection authority
                    </a>{" "}
                    or{" "}
                    <a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">
                      UK data protection authority
                    </a>
                    .
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    If you are located in Switzerland, you may contact the{" "}
                    <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                      Federal Data Protection and Information Commissioner
                    </a>
                    .
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      Withdrawing your consent:
                    </span>
                  </b>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    {" "}
                    If we are relying on your consent to process your personal
                    information, which may be express and/or implied consent
                    depending on the applicable law, you have the right to
                    withdraw your consent at any time. You can withdraw your
                    consent at any time by contacting us by using the contact
                    details provided in the section "HOW CAN YOU CONTACT US
                    ABOUT THIS NOTICE?" below or updating your preferences.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    However, please note that this will not affect the
                    lawfulness of the processing before its withdrawal nor, when
                    applicable law allows, will it affect the processing of your
                    personal information conducted in reliance on lawful
                    processing grounds other than consent.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      Opting out of marketing and promotional communications
                    </span>
                  </b>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    : You can unsubscribe from our marketing and promotional
                    communications at any time by clicking on the unsubscribe
                    link in the emails that we send, replying "STOP" or
                    "UNSUBSCRIBE" to the SMS messages that we send, Clicking the
                    "DO NOT REMIND ME AGAIN" OR "SKIP" OR "DO NOT SHOW ME AGAIN"
                    , Managing preferences under "Settings", or by contacting us
                    using the details provided in the section "HOW CAN YOU
                    CONTACT US ABOUT THIS NOTICE?" below. You will then be
                    removed from the marketing lists. However, we may still
                    communicate with you — for example, to send you
                    service-related messages that are necessary for the
                    administration and use of your account, to respond to
                    service requests, or for other non-marketing purposes.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <h2>
                  <span lang="TR">Account Information</span>
                </h2>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    If you would at any time like to review or change the
                    information in your account or terminate your account, you
                    can:
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    •{" "}
                  </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    Log in to your account settings and update your user
                    account.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    Contact us using the contact information provided.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    Upon your request to terminate your account, we will
                    deactivate or delete your account and information from our
                    active databases. However, we may retain some information in
                    our files to prevent fraud, troubleshoot problems, assist
                    with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      Cookies and similar technologies:
                    </span>
                  </b>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    {" "}
                    Most Web browsers are set to accept cookies by default. If
                    you prefer, you can usually choose to set your browser to
                    remove cookies and to reject cookies. If you choose to
                    remove cookies or reject cookies, this could affect certain
                    features or services of our Services. You may also
                    <a href="https://optout.aboutads.info/?c=2&lang=EN">
                      {" "}
                      opt out of interest-based advertising by advertisers
                    </a>{" "}
                    on our Services. For further information, please see our
                    Cookie Notice:{" "}
                    <a href="https://atlas.space/cookies">
                      https://atlas.space/cookies
                    </a>
                    .
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    If you have questions or comments about your privacy rights,
                    you may email us at connect@atlas.space.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">12.CONTROLS FOR DO-NOT-TRACK FEATURES</span>
                </h1>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    Most web browsers and some mobile operating systems and
                    mobile applications include a Do-Not-Track ("DNT") feature
                    or setting you can activate to signal your privacy
                    preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform
                    technology standard for recognizing and implementing DNT
                    signals has been finalized. As such, we do not currently
                    respond to DNT browser signals or any other mechanism that
                    automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we
                    must follow in the future, we will inform you about that
                    practice in a revised version of this privacy notice.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">
                    13.DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </span>
                </h1>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span lang="TR" style={{ lineHeight: "150%" }}>
                        &nbsp;
                      </span>
                    </i>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <i>
                      <span lang="TR" style={{ lineHeight: "150%" }}>
                        In Short:
                      </span>
                    </i>
                  </b>
                  <i>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      {" "}
                      Yes, if you are a resident of California, you are granted
                      specific rights regarding access to your personal
                      information.
                    </span>
                  </i>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    California Civil Code Section 1798.83, also known as the
                    "Shine The Light" law, permits our users who are California
                    residents to request and obtain from us, once a year and
                    free of charge, information about categories of personal
                    information (if any) we disclosed to third parties for
                    direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in
                    the immediately preceding calendar year. If you are a
                    California resident and would like to make such a request,
                    please submit your request in writing to us using the
                    contact information provided below.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    If you are under 18 years of age, reside in California, and
                    have a registered account with Services, you have the right
                    to request removal of unwanted data that you publicly post
                    on the Services. To request removal of such data, please
                    contact us using the contact information provided below and
                    include the email address associated with your account and a
                    statement that you reside in California. We will make sure
                    the data is not publicly displayed on the Services, but
                    please be aware that the data may not be completely or
                    comprehensively removed from all our systems (e.g., backups,
                    etc.).
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <h2>
                  <span lang="TR">CCPA Privacy Notice</span>
                </h2>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The<span style={{ letterSpacing: "-.05pt" }}> </span>
                    California Code
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    Regulations
                    <span style={{ letterSpacing: "-.05pt" }}> </span>defines a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    "resident" as:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    (1){" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    every individual who is in the State of California for other
                    than a temporary or
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    transitory
                    <span style={{ letterSpacing: "-.05pt" }}> </span>purpose
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    (2){" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    every<span style={{ letterSpacing: "-.05pt" }}> </span>
                    individual who
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    domiciled
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>State of
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    California who
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is outside
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the State
                  </span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span style={{ lineHeight: "150%" }}>
                    of California for a temporary or transitory purpose
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%", letterSpacing: "-1.5pt" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    All<span style={{ letterSpacing: "-.05pt" }}> </span>other
                    individuals
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are
                    defined
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    "non-residents."
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    If this definition of "resident" applies to you, we must
                    adhere to certain rights and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    obligations
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    regarding
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    What<span style={{ letterSpacing: "-.05pt" }}> </span>
                    categories of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>do we
                    <span style={{ letterSpacing: "-.05pt" }}></span>collect?
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    We have collected the following categories of personal
                    information in the past twelve (12)
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>months:
                  </span>
                </p>
                <p
                  className="MsoListParagraph"
                  style={{ marginLeft: "-6.5pt", textIndent: "0in" }}
                >
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <table
                  className="MsoNormalTable"
                  border={1}
                  cellSpacing={0}
                  cellPadding={0}
                  width={602}
                  style={{
                    width: "451.25pt",
                    marginLeft: "-8.7pt",
                    borderCollapse: "collapse",
                    border: "none",
                  }}
                >
                  <tbody>
                    <tr style={{ height: "11.05pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "11.05pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <b>
                            <span
                              style={{
                                lineHeight: "150%",
                                fontFamily: '"Arial",sans-serif',
                              }}
                            >
                              Category
                            </span>
                          </b>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          border: "solid black 1.0pt",
                          borderLeft: "none",
                          padding: "0in 0in 0in 0in",
                          height: "11.05pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <b>
                            <span
                              style={{
                                lineHeight: "150%",
                                fontFamily: '"Arial",sans-serif',
                              }}
                            >
                              Examples
                            </span>
                          </b>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          border: "solid black 1.0pt",
                          borderLeft: "none",
                          padding: "0in 0in 0in 0in",
                          height: "11.05pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <b>
                            <span
                              style={{
                                lineHeight: "150%",
                                fontFamily: '"Arial",sans-serif',
                              }}
                            >
                              Collected
                            </span>
                          </b>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "47.75pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "47.75pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            A.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Identifiers
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "47.75pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Contact details, such as real name, alias,
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            postal address, telephone or mobile contact
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            number, unique personal identifier, online
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            identifier,
                            <span style={{ letterSpacing: "-.1pt" }}> </span>
                            Internet
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Protocol
                            <span style={{ letterSpacing: "-.1pt" }}> </span>
                            address,
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            email
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            address,
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and account
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            name
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "47.75pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "38.65pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            B. Personal information
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            categories listed in the
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            California
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            Customer
                            <span style={{ letterSpacing: "-.3pt" }}> </span>
                            Records
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            statute
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Name, contact information, education,
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            employment,
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            employment
                            <span style={{ letterSpacing: "-.3pt" }}> </span>
                            history,
                            <span style={{ letterSpacing: "-.3pt" }}> </span>
                            and
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            financial
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            information
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "29.6pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "29.6pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            C.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Protected classification
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            characteristics under
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            California
                            <span style={{ letterSpacing: "-.25pt" }}> </span>
                            or
                            <span style={{ letterSpacing: "-.25pt" }}> </span>
                            federal
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            law
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "29.6pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Gender
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and date
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            of birth
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "29.6pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "27.55pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            D.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Commercial information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Transaction
                            <span style={{ letterSpacing: "-.4pt" }}> </span>
                            information,
                            <span style={{ letterSpacing: "-.4pt" }}> </span>
                            purchase
                            <span style={{ letterSpacing: "-.4pt" }}> </span>
                            history,
                            <span style={{ letterSpacing: "-1.45pt" }}> </span>
                            financial
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            details, and
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            payment information
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "27.55pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            E.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Biometric information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Fingerprints
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and voiceprints
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "38.65pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            F.
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            Internet
                            <span style={{ letterSpacing: "-.3pt" }}> </span>
                            or
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            other
                            <span style={{ letterSpacing: "-.3pt" }}> </span>
                            similar
                            <span style={{ letterSpacing: "-1.45pt" }}></span>
                            network
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            activity
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Browsing history, search history, online
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            behavior,
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            interest
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            data,
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            and
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            interactions
                            <span style={{ letterSpacing: "-.2pt" }}> </span>
                            with
                            <span style={{ letterSpacing: "-1.45pt" }}> </span>
                            our
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and other
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            websites, applications,
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            systems,
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and advertisements
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "27.55pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            G.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Geolocation data
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Device
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            location
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "29.2pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "29.2pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            H. Audio, electronic, visual,
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            thermal,
                            <span style={{ letterSpacing: "-.4pt" }}> </span>
                            olfactory,
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            or
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            similar
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "29.2pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Images and audio, video or call recordings
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            created
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            in connection
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            with our
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            business
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            activities
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "29.2pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "38.65pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            I. Professional or
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            employment-related
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Business contact details in order to provide
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            you our Services at a business level or job
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            title,
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            work
                            <span style={{ letterSpacing: "-.05pt" }}></span>
                            history,
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            professional
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            qualifications
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            if you
                            <span style={{ letterSpacing: "-.05pt" }}></span>
                            apply for
                            <span style={{ letterSpacing: "-.05pt" }}> </span>a
                            job
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            with us
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "38.65pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "27.55pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            J.
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            Education Information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Student
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            records and
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            directory information
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "37.85pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "37.85pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            K. Inferences drawn from
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            other
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            personal
                            <span style={{ letterSpacing: "-.35pt" }}> </span>
                            information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "37.85pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Inferences drawn from any of the collected
                            <span style={{ letterSpacing: ".05pt" }}> </span>
                            personal information listed above to create a
                            <span style={{ letterSpacing: "-1.5pt" }}> </span>
                            profile
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            or summary
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            about, for
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            example, an
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            individual’s
                            <span style={{ letterSpacing: "-.1pt" }}> </span>
                            preferences
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            and
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            characteristics
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "37.85pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            NO
                          </span>
                        </p>
                      </td>
                    </tr>
                    <tr style={{ height: "27.55pt" }}>
                      <td
                        width={201}
                        style={{
                          width: "150.95pt",
                          border: "solid black 1.0pt",
                          borderTop: "none",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            marginLeft: "10.5pt",
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            L. Sensitive Personal
                            <span style={{ letterSpacing: "-1.55pt" }}> </span>
                            Information
                          </span>
                        </p>
                      </td>
                      <td
                        width={329}
                        style={{
                          width: "246.5pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            Account
                            <span style={{ letterSpacing: "-.05pt" }}> </span>
                            login information
                          </span>
                        </p>
                      </td>
                      <td
                        width={72}
                        style={{
                          width: "53.8pt",
                          borderTop: "none",
                          borderLeft: "none",
                          borderBottom: "solid black 1.0pt",
                          borderRight: "solid black 1.0pt",
                          padding: "0in 0in 0in 0in",
                          height: "27.55pt",
                        }}
                      >
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            &nbsp;
                          </span>
                        </p>
                        <p
                          className="TableParagraph"
                          style={{
                            textAlign: "justify",
                            textJustify: "auto",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "150%",
                              fontFamily: '"Arial",sans-serif',
                            }}
                          >
                            YES
                          </span>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <b>
                    <span lang="TR" style={{ lineHeight: "150%" }}>
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We will use and retain the collected personal information as
                    needed to provide the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.35pt" }}> </span>A
                    <span style={{ letterSpacing: "-.3pt" }}> </span>-
                    <span style={{ letterSpacing: "-.35pt" }}> </span>As long
                    <span style={{ letterSpacing: "-.05pt" }}></span>as the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user has
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>B
                    -<span style={{ letterSpacing: "-.35pt" }}> </span>As long
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user has
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>C
                    -<span style={{ letterSpacing: "-.35pt" }}> </span>As long
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user has
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>G
                    -<span style={{ letterSpacing: "-.05pt" }}> </span>3 months
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>I
                    -<span style={{ letterSpacing: "-.35pt" }}> </span>As long
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user has
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>L
                    <span style={{ letterSpacing: "-.2pt" }}> </span>-
                    <span style={{ letterSpacing: "-.35pt" }}> </span>As long
                    <span style={{ letterSpacing: "-.05pt" }}></span>as the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user has
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an account
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>L
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    <span style={{ letterSpacing: "-.05pt" }}> </span>used,
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    disclosed
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>service
                    <span style={{ letterSpacing: "-.05pt" }}></span>provider
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    contractor,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    additional, specified purposes. You have the right to limit
                    the use or disclosure of your
                    <span style={{ letterSpacing: ".05pt" }}> </span>sensitive
                    <span style={{ letterSpacing: "-.05pt" }}></span>personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may also collect other personal information outside of
                    these categories through
                    <span style={{ letterSpacing: ".05pt" }}> </span>instances
                    where you interact with us in person, online, or by phone or
                    mail in the context
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>of:
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    Receiving
                    <span style={{ letterSpacing: "-.05pt" }}> </span>help
                    through
                  </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    {" "}
                    our customer support channels;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Participation
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    customer
                    <span style={{ letterSpacing: "-.05pt" }}> </span>surveys or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    contests; and
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Facilitation
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in the
                    <span style={{ letterSpacing: "-.05pt" }}></span>delivery of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and to
                    <span style={{ letterSpacing: "-.05pt" }}></span>respond to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    inquiries.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h3>
                  <span style={{ lineHeight: "150%" }}>
                    How<span style={{ letterSpacing: "-.05pt" }}> </span>do we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>share your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information?
                  </span>
                </h3>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    IMMERSIVE
                    <span style={{ letterSpacing: "-.05pt" }}> </span>DREAM
                    <span style={{ letterSpacing: "-.1pt" }}> </span>TECH,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>INC.
                    collects
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and shares
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>through:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Targeting
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    cookies/Marketing
                    <span style={{ letterSpacing: "-.2pt" }}> </span>cookies
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Social<span style={{ letterSpacing: "-.05pt" }}> </span>
                    media cookies
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Beacons/Pixels/Tags
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Click redirects: Official website link, social media link
                    (included but not limited to
                    <span style={{ letterSpacing: "-1.55pt" }}> </span>
                    instagram, twitter, linkedin, youtube, discord), product
                    page link, product sales
                    <span style={{ letterSpacing: ".05pt" }}> </span>page
                    <span style={{ letterSpacing: "-.05pt" }}> </span>link,
                    <span style={{ letterSpacing: "-.05pt" }}></span>video link,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>audio
                    link,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>image
                    link.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Social media plugins: Tweet button and Follow button by
                    Twitter, Inc., Instagram
                    <span style={{ letterSpacing: ".05pt" }}> </span>Basic
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Display
                    <span style={{ letterSpacing: "-.35pt" }}> </span>API,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>oEmbed
                    <span style={{ letterSpacing: "-.1pt" }}> </span>by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Meta
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Platforms,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Inc.,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Share
                    <span style={{ letterSpacing: "-.1pt" }}> </span>by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>LinkedIn
                    <span style={{ letterSpacing: "-.05pt" }}></span>Corp.,
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    .{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We use social media features, such as a "Like" button, and
                    widgets, such as a
                    <span style={{ letterSpacing: ".05pt" }}> </span>"Share"
                    button, in our Services. Such features may process your
                    Internet Protocol
                    <span style={{ letterSpacing: ".05pt" }}> </span>(IP)
                    address and track which page you are visiting on our
                    website. We may place a
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>cookie to
                    enable the feature to work correctly. If you are logged in
                    on a certain
                    <span style={{ letterSpacing: ".05pt" }}> </span>social
                    media platform and you interact with a widget or button
                    belonging to that
                    <span style={{ letterSpacing: ".05pt" }}> </span>social
                    media platform, this information may be recorded to your
                    profile of such
                    <span style={{ letterSpacing: ".05pt" }}> </span>social
                    media platform. To avoid this, you should log out from that
                    social media
                    <span style={{ letterSpacing: ".05pt" }}> </span>platform
                    <span style={{ letterSpacing: ".05pt" }}> </span>before
                    <span style={{ letterSpacing: ".05pt" }}> </span>accessing
                    <span style={{ letterSpacing: ".05pt" }}> </span>or
                    <span style={{ letterSpacing: ".05pt" }}> </span>using
                    <span style={{ letterSpacing: ".05pt" }}> </span>the
                    <span style={{ letterSpacing: ".05pt" }}> </span>Services.
                    <span style={{ letterSpacing: ".1pt" }}> </span>Social
                    <span style={{ letterSpacing: ".05pt" }}> </span>media
                    <span style={{ letterSpacing: ".05pt" }}> </span>features
                    <span style={{ letterSpacing: ".05pt" }}></span>and
                    <span style={{ letterSpacing: ".05pt" }}> </span>widgets may
                    be hosted by a third party or hosted directly on our
                    Services. Your
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    interactions with these features are governed by the privacy
                    notices of the
                    <span style={{ letterSpacing: ".05pt" }}> </span>companies
                    that provide them. By clicking on one of these buttons, you
                    agree to the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>use of
                    this plugin and consequently the transfer of personal
                    information to the
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    corresponding social media service. We have no control over
                    the essence and
                    <span style={{ letterSpacing: ".05pt" }}> </span>extent
                    <span style={{ letterSpacing: "-.05pt" }}></span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>these
                    transmitted
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data or
                    <span style={{ letterSpacing: "-.05pt" }}></span>their
                    additional
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    processing.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    More information about our data collection and sharing
                    practices can be found in this
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>privacy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>notice
                    <span style={{ letterSpacing: "-.05pt" }}></span>and our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Cookie
                    Notice:
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    https://atlas.space/cookies.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You may contact us by email at{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space,{" "}
                      </span>
                    </a>
                    by visiting
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    https://atlas.space/contact, or by referring to the contact
                    details at the bottom of this
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    document.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If you are using an authorized agent to exercise your right
                    to opt out we may deny a
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>request if
                    the authorized agent does not submit proof that they have
                    been validly
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    authorized
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>act on
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    behalf.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h3>
                  <span style={{ lineHeight: "150%" }}>
                    Will<span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be shared
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>anyone
                    else?
                  </span>
                </h3>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may disclose your personal information with our service
                    providers pursuant to a
                    <span style={{ letterSpacing: ".05pt" }}> </span>written
                    contract between us and each service provider. Each service
                    provider is a for-
                    <span style={{ letterSpacing: ".05pt" }}> </span>profit
                    entity that processes the information on our behalf,
                    following the same strict privacy
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    protection
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    obligations
                    <span style={{ letterSpacing: "-.05pt" }}> </span>mandated
                    by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>CCPA.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may use your personal information for our own business
                    purposes, such as for
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    undertaking internal research for technological development
                    and demonstration. This is
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    considered
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to be
                    <span style={{ letterSpacing: "-.05pt" }}></span>"selling"
                    of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    IMMERSIVE DREAM TECH, INC. has not sold or shared any
                    personal information to
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>third
                    parties for a business or commercial purpose in the
                    preceding twelve (12)
                    <span style={{ letterSpacing: ".05pt" }}> </span>months.
                    IMMERSIVE DREAM TECH, INC. has disclosed the following
                    categories of
                    <span style={{ letterSpacing: ".05pt" }}> </span>personal
                    information to third parties for a business or commercial
                    purpose in the
                    <span style={{ letterSpacing: ".05pt" }}> </span>preceding
                    <span style={{ letterSpacing: "-.05pt" }}></span>twelve
                    <span style={{ letterSpacing: "-.05pt" }}> </span>(12)
                    months:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Category<span style={{ letterSpacing: "-.05pt" }}> </span>
                    G. Geolocation
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data, such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as device
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    location.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The categories of third parties to whom we disclosed
                    personal information for a business
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>or
                    commercial purpose can be found under "WHEN AND WITH WHOM DO
                    WE SHARE
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>YOUR
                    <span style={{ letterSpacing: "-.05pt" }}> </span>PERSONAL
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    INFORMATION?".
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h3
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ lineHeight: "150%" }}>
                    Your<span style={{ letterSpacing: "-.1pt" }}> </span>
                    rights
                    <span style={{ letterSpacing: "-.1pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>respect
                    <span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.05pt" }}></span>data
                  </span>
                </h3>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      R
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      ht<span style={{ letterSpacing: "-.05pt" }}> </span>to
                      request
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      deletion of
                      <span style={{ letterSpacing: "-.05pt" }}> </span>the data
                      <span style={{ letterSpacing: "-.05pt" }}> </span>—
                      Request
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to
                      delete
                    </u>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You can ask for the deletion of your personal information.
                    If you ask us to delete your
                    <span style={{ letterSpacing: ".05pt" }}> </span>personal
                    information, we will respect your request and delete your
                    personal information,
                    <span style={{ letterSpacing: ".05pt" }}> </span>subject
                    <span style={{ letterSpacing: "-.05pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>certain
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    exceptions
                    <span style={{ letterSpacing: "-.05pt" }}> </span>provided
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>law,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>(but
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>limited
                    to)
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>exercise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>another
                    consumer of his or her right to free speech, our compliance
                    requirements
                    <span style={{ letterSpacing: ".05pt" }}> </span>resulting
                    from a legal obligation, or any processing that may be
                    required to protect
                    <span style={{ letterSpacing: ".05pt" }}> </span>against
                    <span style={{ letterSpacing: "-.05pt" }}></span>illegal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    activities.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      R
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      ht<span style={{ letterSpacing: "-.05pt" }}> </span>to be
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      informed —
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      Request to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>know
                    </u>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Depending
                    <span style={{ letterSpacing: "-.05pt" }}> </span>on the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    circumstances, you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>have a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>right to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>know:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    whether<span style={{ letterSpacing: "-.05pt" }}> </span>
                    we collect
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    the<span style={{ letterSpacing: "-.05pt" }}> </span>
                    categories of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>that we
                    <span style={{ letterSpacing: "-.05pt" }}></span>collect;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginLeft: "10.9pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    the
                    <span style={{ letterSpacing: "-.05pt" }}>
                      {" "}
                      purposes for which the collected personal information is
                      used;
                    </span>
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    whether<span style={{ letterSpacing: "-.05pt" }}> </span>
                    we sell
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or share
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to third
                    <span style={{ letterSpacing: "-.05pt" }}></span>parties;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the categories of personal information that we sold, shared,
                    or disclosed for a
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>business
                    <span style={{ letterSpacing: "-.05pt" }}> </span>purpose;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the categories of third parties to whom the personal
                    information was sold, shared,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    disclosed
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for a
                    <span style={{ letterSpacing: "-.05pt" }}></span>business
                    purpose;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the business or commercial purpose for collecting, selling,
                    or sharing personal
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    information;
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the<span style={{ letterSpacing: "-.05pt" }}> </span>
                    specific pieces
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    collected about
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    In<span style={{ letterSpacing: "-.05pt" }}> </span>
                    accordance
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    applicable
                    <span style={{ letterSpacing: "-.05pt" }}> </span>law,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    obligated
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>provide
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>delete
                    <span style={{ letterSpacing: "-.05pt" }}> </span>consumer
                    <span style={{ letterSpacing: "-1.45pt" }}></span>
                    information that is de-identified in response to a consumer
                    request or to re-identify
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    individual
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to verify
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a consumer
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      R
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      ht to Non-Discrimination for the Exercise of a Consumer’s
                      Privacy Rights
                    </u>
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-1.5pt",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We<span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    discriminate
                    <span style={{ letterSpacing: "-.05pt" }}> </span>against
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you if
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>exercise
                    your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>privacy
                    rights.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      <span style={{ textDecoration: "none" }}>&nbsp;</span>
                    </span>
                  </u>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      R
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      ht to Limit Use and Disclosure of Sensitive Personal
                      Information
                    </u>
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>If
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>business
                    collects
                    <span style={{ letterSpacing: "-.05pt" }}> </span>any of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    following:
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    social<span style={{ letterSpacing: "-.05pt" }}> </span>
                    security information,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>drivers'
                    licenses,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>state ID
                    <span style={{ letterSpacing: "-.05pt" }}> </span>cards,
                    passport numbers
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    account<span style={{ letterSpacing: "-.05pt" }}> </span>
                    login information
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    credit card numbers, financial account information, or
                    credentials allowing access
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>such
                    <span style={{ letterSpacing: "-.05pt" }}> </span>accounts
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    precise<span style={{ letterSpacing: "-.05pt" }}> </span>
                    geolocation
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    racial<span style={{ letterSpacing: "-.05pt" }}> </span>or
                    ethnic
                    <span style={{ letterSpacing: "-.05pt" }}> </span>origin,
                    religious
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    philosophical
                    <span style={{ letterSpacing: "-.05pt" }}> </span>beliefs,
                    union membership
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the contents of email and text, unless the business is the
                    intended recipient of the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    communication
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    genetic<span style={{ letterSpacing: "-.05pt" }}> </span>
                    data, biometric
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data, and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>health
                    data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    data<span style={{ letterSpacing: "-.05pt" }}> </span>
                    concerning sexual
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    orientation and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>sex life
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    you have the right to direct that business to limit its use
                    of your sensitive personal
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to that
                    <span style={{ letterSpacing: "-.05pt" }}></span>use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>which is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    necessary to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>perform
                    the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Services.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Once a business receives your request, they are no longer
                    allowed to use or disclose
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    sensitive personal information for any other purpose unless
                    you provide consent for
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    disclosure of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    sensitive personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information for
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    additional purposes.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Please note that sensitive personal information that is
                    collected or processed without the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    purpose of inferring characteristics about a consumer is not
                    covered by this right, as well
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>publicly
                    available
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    To<span style={{ letterSpacing: "-.1pt" }}> </span>
                    exercise
                    <span style={{ letterSpacing: "-.05pt" }}></span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>right
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>limit
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    disclosure
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-.1pt" }}> </span>sensitive
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.1pt" }}></span>
                    information,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>please
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>email
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space
                        <span style={{ letterSpacing: "-.05pt" }}> </span>
                      </span>
                    </a>
                    or visit:
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    <a href="https://atlas.space/data-request">
                      https://atlas.space/data-request
                    </a>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Verification
                      <span style={{ letterSpacing: "-.2pt" }}></span>process
                    </span>
                  </u>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Upon receiving your request, we will need to verify your
                    identity to determine you are the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    same person about whom we have the information in our
                    system. These verification
                    <span style={{ letterSpacing: ".05pt" }}> </span>efforts
                    require us to ask you to provide information so that we can
                    match it with
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information you have previously provided us. For instance,
                    depending on the type of
                    <span style={{ letterSpacing: ".05pt" }}> </span>request you
                    submit, we may ask you to provide certain information so
                    that we can match
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>the
                    information you provide with the information we already have
                    on file, or we may
                    <span style={{ letterSpacing: ".05pt" }}> </span>contact you
                    through a communication method (e.g., phone or email) that
                    you have
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    previously provided to us. We may also use other
                    verification methods as the
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    circumstances
                    <span style={{ letterSpacing: "-.05pt" }}> </span>dictate.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We will only use personal information provided in your
                    request to verify your identity or
                    <span style={{ letterSpacing: ".05pt" }}> </span>authority
                    to make the request. To the extent possible, we will avoid
                    requesting additional
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information from you for the purposes of verification.
                    However, if we cannot verify your
                    <span style={{ letterSpacing: ".05pt" }}> </span>identity
                    from the information already maintained by us, we may
                    request that you provide
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    additional information for the purposes of verifying your
                    identity and for security or fraud-
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    prevention purposes. We will delete such additionally
                    provided information as soon as we
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>finish
                    <span style={{ letterSpacing: "-.05pt" }}></span>verifying
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Other<span style={{ letterSpacing: "-.05pt" }}> </span>
                      privacy rights
                    </span>
                  </u>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>object
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    processing
                    <span style={{ letterSpacing: "-.1pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    correction
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.05pt" }}></span>data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>if
                    <span style={{ letterSpacing: "-.1pt" }}> </span>it
                    <span style={{ letterSpacing: "-.05pt" }}> </span>is
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    incorrect
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.1pt" }}> </span>no
                    <span style={{ letterSpacing: "-.05pt" }}> </span>longer
                    <span style={{ letterSpacing: "-1.45pt" }}></span>
                    relevant,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>ask to
                    <span style={{ letterSpacing: "-.05pt" }}></span>restrict
                    the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    processing of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    information.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.1pt" }}> </span>can
                    <span style={{ letterSpacing: "-.1pt" }}> </span>designate
                    <span style={{ letterSpacing: "-.1pt" }}> </span>an
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    authorized
                    <span style={{ letterSpacing: "-.1pt" }}> </span>agent
                    <span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>make
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a
                    <span style={{ letterSpacing: "-.1pt" }}> </span>request
                    <span style={{ letterSpacing: "-.1pt" }}></span>under
                    <span style={{ letterSpacing: "-.1pt" }}> </span>the
                    <span style={{ letterSpacing: "-.1pt" }}> </span>CCPA
                    <span style={{ letterSpacing: "-.4pt" }}> </span>on
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>behalf. We
                    may deny a request from an authorized agent that does not
                    submit
                    <span style={{ letterSpacing: ".05pt" }}> </span>proof that
                    they have been validly authorized to act on your behalf in
                    accordance
                    <span style={{ letterSpacing: ".05pt" }}></span>with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>CCPA.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You may request to opt out from future selling or sharing of
                    your personal
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>third
                    <span style={{ letterSpacing: "-.05pt" }}></span>parties.
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Upon
                    <span style={{ letterSpacing: "-.1pt" }}> </span>receiving
                    <span style={{ letterSpacing: "-.05pt" }}></span>an
                    <span style={{ letterSpacing: "-.05pt" }}> </span>opt-out
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request,
                    <span style={{ letterSpacing: "-.1pt" }}></span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-.05pt" }}> </span>act
                    <span style={{ letterSpacing: "-.05pt" }}> </span>upon
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                    request as soon as feasibly possible, but no later than
                    fifteen (15) days from the date of the request submission.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    To<span style={{ letterSpacing: "-.1pt" }}> </span>
                    exercise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>these
                    <span style={{ letterSpacing: "-.1pt" }}> </span>rights,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    <span style={{ letterSpacing: "-.1pt" }}> </span>can
                    <span style={{ letterSpacing: "-.05pt" }}> </span>contact
                    <span style={{ letterSpacing: "-.05pt" }}></span>us
                    <span style={{ letterSpacing: "-.1pt" }}> </span>by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>email
                    <span style={{ letterSpacing: "-.1pt" }}> </span>at
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space,
                        <span style={{ letterSpacing: "-.1pt" }}> </span>
                      </span>
                    </a>
                    by<span style={{ letterSpacing: "-.05pt" }}> </span>
                    visiting
                    <span style={{ letterSpacing: "-1.45pt" }}></span>
                    <a href="https://atlas.space/contact">
                      https://atlas.space/contact
                    </a>
                    , or by referring to the contact details at the bottom of
                    this
                    <span style={{ letterSpacing: ".05pt" }}> </span>document.
                    If you have a complaint about how we handle your data, we
                    would like to hear
                    <span style={{ letterSpacing: "-1.5pt" }}></span>from
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1>
                  <span lang="TR">14. </span>DO VIRGINIA RESIDENTS HAVE SPECIFIC
                  PRIVACY RIGHTS?
                </h1>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        In<span style={{ letterSpacing: "-.1pt" }}> </span>
                        Short:
                        <span style={{ letterSpacing: "-.15pt" }}></span>
                      </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      Yes,<span style={{ letterSpacing: "-.05pt" }}> </span>if
                      <span style={{ letterSpacing: "-.05pt" }}> </span>you
                      <span style={{ letterSpacing: "-.05pt" }}> </span>are
                      <span style={{ letterSpacing: "-.05pt" }}> </span>a
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      resident
                      <span style={{ letterSpacing: "-.05pt" }}></span>of
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      Virginia,
                      <span style={{ letterSpacing: "-.05pt" }}> </span>you
                      <span style={{ letterSpacing: "-.05pt" }}> </span>may
                      <span style={{ letterSpacing: "-.05pt" }}> </span>be
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      granted
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      specific
                      <span style={{ letterSpacing: "-.05pt" }}></span>rights
                      <span style={{ letterSpacing: "-1.45pt" }}> </span>
                      regarding
                      <span style={{ letterSpacing: "-.05pt" }}> </span>access
                      <span style={{ letterSpacing: "-.05pt" }}></span>to and
                      <span style={{ letterSpacing: "-.05pt" }}> </span>use of
                      <span style={{ letterSpacing: "-.05pt" }}> </span>your
                      personal
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      information.
                    </span>
                  </i>
                </p>
                <p className="MsoBodyText">
                  <i>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </i>
                </p>
                <h2>
                  <span style={{ fontSize: "10.5pt", lineHeight: "150%" }}>
                    Virginia<span style={{ letterSpacing: "-.15pt" }}> </span>
                    CDPA
                    <span style={{ letterSpacing: "-.4pt" }}> </span>Privacy
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Notice
                  </span>
                </h2>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Under<span style={{ letterSpacing: "-.1pt" }}> </span>the
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Virginia
                    <span style={{ letterSpacing: "-.1pt" }}></span>Consumer
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Data
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    Protection
                    <span style={{ letterSpacing: "-.4pt" }}> </span>Act
                    <span style={{ letterSpacing: "-.1pt" }}> </span>(CDPA):
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    "Consumer" means a natural person who is a resident of the
                    Commonwealth acting only
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>in an
                    individual or household context. It does not include a
                    natural person acting in a
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    commercial
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    employment context.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    "Personal data" means any information that is linked or
                    reasonably linkable to an
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    identified or identifiable natural person. "Personal data"
                    does not include de-identified
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>publicly
                    available
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    information.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    "Sale<span style={{ letterSpacing: "-.05pt" }}> </span>of
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data"
                    means
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    exchange
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data for
                    monetary
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    consideration.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If this definition "consumer" applies to you, we must adhere
                    to certain rights and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    obligations
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    regarding
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The information we collect, use, and disclose about you will
                    vary depending on how you
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    interact with IMMERSIVE DREAM TECH, INC. and our Services.
                    To find out more,
                    <span style={{ letterSpacing: ".05pt" }}> </span>please
                    <span style={{ letterSpacing: "-.05pt" }}></span>visit
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    following
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    sections
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    :
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Personal<span style={{ letterSpacing: "-.05pt" }}> </span>
                    data we
                    <span style={{ letterSpacing: "-.05pt" }}></span>collect
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    How<span style={{ letterSpacing: "-.05pt" }}> </span>we use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginLeft: "10.9pt" }}>
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    When<span style={{ letterSpacing: "-.05pt" }}> </span>and
                    with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>whom we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>share your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Your<span style={{ letterSpacing: "-.1pt" }}> </span>r
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      hts<span style={{ letterSpacing: "-.1pt" }}> </span>with
                      <span style={{ letterSpacing: "-.1pt" }}> </span>respect
                      <span style={{ letterSpacing: "-.1pt" }}> </span>to
                      <span style={{ letterSpacing: "-.1pt" }}> </span>your
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      personal
                      <span style={{ letterSpacing: "-.1pt" }}></span>data
                    </u>
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right<span style={{ letterSpacing: "-.05pt" }}> </span>to be
                    <span style={{ letterSpacing: "-.05pt" }}></span>informed
                    whether
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we are
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    processing your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right<span style={{ letterSpacing: "-.05pt" }}> </span>to
                    access
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right<span style={{ letterSpacing: "-.05pt" }}> </span>to
                    correct
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    inaccuracies in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right<span style={{ letterSpacing: "-.05pt" }}> </span>to
                    request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>deletion
                    of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    personal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>data
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right<span style={{ letterSpacing: "-.05pt" }}> </span>to
                    obtain
                    <span style={{ letterSpacing: "-.05pt" }}> </span>a copy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>personal
                    data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    previously
                    <span style={{ letterSpacing: "-.05pt" }}> </span>shared
                    with
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "12.0pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span style={{ fontSize: "12.0pt", lineHeight: "150%" }}>
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Right to opt out of the processing of your personal data if
                    it is used for targeted
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    advertising, the sale of personal data, or profiling in
                    furtherance of decisions that
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>produce
                    <span style={{ letterSpacing: "-.05pt" }}> </span>legal
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    similarly
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    significant effects
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    ("profiling")
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    IMMERSIVE DREAM TECH, INC. has not sold any personal data to
                    third parties for
                    <span style={{ letterSpacing: ".05pt" }}> </span>business or
                    commercial purposes. IMMERSIVE DREAM TECH, INC. will not
                    sell
                    <span style={{ letterSpacing: ".05pt" }}> </span>personal
                    <span style={{ letterSpacing: "-.1pt" }}></span>data
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>future
                    <span style={{ letterSpacing: "-.1pt" }}> </span>belonging
                    <span style={{ letterSpacing: "-.05pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>website
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    visitors,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>users,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>other
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    consumers.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Exercise
                      <span style={{ letterSpacing: "-.1pt" }}> </span>your
                      <span style={{ letterSpacing: "-.1pt" }}> </span>rights
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      provided
                      <span style={{ letterSpacing: "-.1pt" }}> </span>under
                      <span style={{ letterSpacing: "-.1pt" }}> </span>the
                      <span style={{ letterSpacing: "-.1pt" }}> </span>
                      Virginia
                      <span style={{ letterSpacing: "-.1pt" }}></span>CDPA
                    </span>
                  </u>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    More information about our data collection and sharing
                    practices can be found in this
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>privacy
                    <span style={{ letterSpacing: "-.05pt" }}> </span>notice
                    <span style={{ letterSpacing: "-.05pt" }}></span>and our
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Cookie
                    Notice:
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    https://atlas.space/cookies.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.1pt" }}> </span>contact
                    <span style={{ letterSpacing: "-.15pt" }}></span>us
                    <span style={{ letterSpacing: "-.1pt" }}> </span>by
                    <span style={{ letterSpacing: "-.15pt" }}> </span>email
                    <span style={{ letterSpacing: "-.1pt" }}> </span>at
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space,
                        <span style={{ letterSpacing: "-.1pt" }}> </span>
                      </span>
                    </a>
                    by<span style={{ letterSpacing: "-.1pt" }}> </span>
                    visiting
                    <span style={{ letterSpacing: "-.15pt" }}></span>
                    https://atlas.space/data-
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>
                    request,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or by
                    <span style={{ letterSpacing: "-.05pt" }}></span>referring
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>contact
                    details
                    <span style={{ letterSpacing: "-.05pt" }}> </span>at the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>bottom of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>this
                    document.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If you are using an authorized agent to exercise your
                    rights, we may deny a request if the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    authorized agent does not submit proof that they have been
                    validly authorized to act on
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>behalf.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Verification
                      <span style={{ letterSpacing: "-.2pt" }}></span>process
                    </span>
                  </u>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We may request that you provide additional information
                    reasonably necessary to verify
                    <span style={{ letterSpacing: ".05pt" }}> </span>you and
                    your consumer's request. If you submit the request through
                    an authorized agent,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>we may
                    need to collect additional information to verify your
                    identity before processing
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Upon<span style={{ letterSpacing: "-.1pt" }}> </span>
                    receiving
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-.05pt" }}> </span>respond
                    <span style={{ letterSpacing: "-.05pt" }}></span>without
                    <span style={{ letterSpacing: "-.05pt" }}> </span>undue
                    <span style={{ letterSpacing: "-.05pt" }}> </span>delay,
                    <span style={{ letterSpacing: "-.05pt" }}></span>but
                    <span style={{ letterSpacing: "-.05pt" }}> </span>in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>all
                    <span style={{ letterSpacing: "-.1pt" }}> </span>cases,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>within
                    <span style={{ letterSpacing: "-1.45pt" }}></span>
                    forty-five
                    <span style={{ letterSpacing: "-.05pt" }}> </span>(45) days
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    receipt.
                    <span style={{ letterSpacing: "-.15pt" }}> </span>The
                    response
                    <span style={{ letterSpacing: "-.05pt" }}> </span>period may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    extended
                    <span style={{ letterSpacing: "-.05pt" }}> </span>once by
                    <span style={{ letterSpacing: "-.05pt" }}></span>
                    forty-five
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    (45) additional days when reasonably necessary. We will
                    inform you of any such
                    <span style={{ letterSpacing: ".05pt" }}> </span>extension
                    within the initial 45-day response period, together with the
                    reason for the
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    extension.
                  </span>
                </p>
                <p className="MsoBodyText">
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText">
                  <u>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      R
                    </span>
                  </u>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ig
                    <u>
                      ht<span style={{ letterSpacing: "-.05pt" }}> </span>to
                      appea
                    </u>
                    l
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    If{" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    we decline to take action regarding your request, we will
                    inform you of our decision and
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    reasoning behind it. If you wish to appeal our decision,
                    please email us at
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space.{" "}
                      </span>
                    </a>
                    Within sixty (60) days of receipt of an appeal, we will
                    inform you in
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>writing of
                    any action taken or not taken in response to the appeal,
                    including a written
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    explanation
                    <span style={{ letterSpacing: ".05pt" }}> </span>of
                    <span style={{ letterSpacing: ".05pt" }}> </span>the
                    <span style={{ letterSpacing: ".05pt" }}> </span>reasons
                    <span style={{ letterSpacing: ".05pt" }}></span>for
                    <span style={{ letterSpacing: ".1pt" }}> </span>the
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    decisions.
                    <span style={{ letterSpacing: ".05pt" }}> </span>If
                    <span style={{ letterSpacing: ".05pt" }}> </span>your
                    <span style={{ letterSpacing: ".1pt" }}> </span>appeal
                    <span style={{ letterSpacing: ".05pt" }}> </span>if
                    <span style={{ letterSpacing: ".05pt" }}> </span>denied,
                    <span style={{ letterSpacing: ".05pt" }}> </span>you
                    <span style={{ letterSpacing: ".1pt" }}> </span>may
                    <span style={{ letterSpacing: ".05pt" }}> </span>contact
                    <span style={{ letterSpacing: ".05pt" }}></span>the
                    <span style={{ letterSpacing: "-.35pt" }}> </span>
                    <a href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">
                      Attorney
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      General to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>submit a
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      complaint
                    </a>
                    .
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <h1>15. DO WE MAKE UPDATES TO THIS NOTICE?</h1>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                    </i>
                  </b>
                </p>
                <p className="MsoNormal">
                  <b>
                    <i>
                      <span style={{ lineHeight: "150%" }}>
                        In<span style={{ letterSpacing: "-.05pt" }}> </span>
                        Short:
                        <span style={{ letterSpacing: "-.05pt" }}></span>
                      </span>
                    </i>
                  </b>
                  <i>
                    <span style={{ lineHeight: "150%" }}>
                      Yes,<span style={{ letterSpacing: "-.05pt" }}> </span>we
                      <span style={{ letterSpacing: "-.05pt" }}> </span>will
                      <span style={{ letterSpacing: "-.05pt" }}> </span>update
                      <span style={{ letterSpacing: "-.05pt" }}></span>this
                      <span style={{ letterSpacing: "-.05pt" }}> </span>notice
                      <span style={{ letterSpacing: "-.05pt" }}> </span>as
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      necessary
                      <span style={{ letterSpacing: "-.05pt" }}> </span>to
                      <span style={{ letterSpacing: "-.05pt" }}> </span>stay
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      compliant
                      <span style={{ letterSpacing: "-.05pt" }}> </span>with
                      <span style={{ letterSpacing: "-.05pt" }}> </span>
                      relevant
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>laws.
                    </span>
                  </i>
                </p>
                <p className="MsoNormal">
                  <i>
                    <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                  </i>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>
                    We may update this privacy notice from time to time. The
                    updated version will be
                    <span style={{ letterSpacing: ".05pt" }}> </span>indicated
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by
                    <span style={{ letterSpacing: "-.05pt" }}> </span>an updated
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    "Revised"
                    <span style={{ letterSpacing: "-.05pt" }}> </span>date and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>updated
                    version
                    <span style={{ letterSpacing: "-.05pt" }}> </span>will
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    effective
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as
                    <span style={{ letterSpacing: "-.05pt" }}> </span>soon
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>as it is
                    accessible. If we make material changes to this privacy
                    notice, we may notify you
                    <span style={{ letterSpacing: ".05pt" }}> </span>either by
                    prominently posting a notice of such changes or by directly
                    sending you a
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    notification. We encourage you to review this privacy notice
                    frequently to be informed of
                    <span style={{ letterSpacing: ".05pt" }}> </span>how
                    <span style={{ letterSpacing: "-.05pt" }}> </span>we
                    <span style={{ letterSpacing: "-.05pt" }}> </span>are
                    protecting
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    information.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <h1>16. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h1>
                <p className="MsoNormal">
                  <b>
                    <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>
                    If you have questions or comments about this notice, you may
                    email us at
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    <a href="mailto:connect@atlas.space">
                      <span
                        style={{
                          color: "windowtext",
                          textDecoration: "none",
                        }}
                      >
                        connect@atlas.space
                        <span style={{ letterSpacing: "-.05pt" }}> </span>
                      </span>
                    </a>
                    or<span style={{ letterSpacing: "-.05pt" }}> </span>
                    contact us
                    <span style={{ letterSpacing: "-.05pt" }}> </span>by post
                    <span style={{ letterSpacing: "-.05pt" }}> </span>at:
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>
                    IMMERSIVE
                    <span style={{ letterSpacing: "-.05pt" }}> </span>DREAM
                    <span style={{ letterSpacing: "-.1pt" }}> </span>TECH,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>INC.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>651 N Broad St,</span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    SUITE 206,
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    {" "}
                    Middletown, DE 19709,{" "}
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <h1>
                  17. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                  FROM YOU?
                </h1>
                <p className="MsoNormal">
                  <b>
                    <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>
                    Based<span style={{ letterSpacing: "-.1pt" }}> </span>on
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    applicable
                    <span style={{ letterSpacing: "-.05pt" }}> </span>laws
                    <span style={{ letterSpacing: "-.05pt" }}> </span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>your
                    <span style={{ letterSpacing: "-.05pt" }}> </span>country,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>have
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>right
                    <span style={{ letterSpacing: "-.05pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>request
                    <span style={{ letterSpacing: "-.05pt" }}> </span>access
                  </span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span style={{ lineHeight: "150%" }}>
                    to the personal information we collect from you, change that
                    information, or delete it. To request to review, update, or
                    delete your personal information, please visit:{" "}
                    <a href="https://atlas.space/data-request">
                      https://atlas.space/data-request
                    </a>
                    .
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
              </div>
              <div className="WordSection1">
                <p className="MsoTitle" style={{ marginTop: "0in" }}>
                  <h1 style={{ textAlign: "center" }} lang="TR">
                    TERMS AND CONDITIONS
                  </h1>
                </p>
                <p className="MsoNormal">
                  <b>
                    <span
                      style={{
                        display: "block",
                        textAlign: "center",
                        lineHeight: "150%",
                      }}
                    >
                      Last updated May 01, 2023
                    </span>
                  </b>
                </p>

                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>
                    We<span style={{ letterSpacing: "-.3pt" }}> </span>are
                  </span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span style={{ lineHeight: "150%" }}>
                    IMMERSIVE<span style={{ letterSpacing: "-.05pt" }}> </span>
                    DREAM
                    <span style={{ letterSpacing: "-.2pt" }}> </span>TECH,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>INC.
                    <span style={{ letterSpacing: "-.1pt" }}> </span>doing
                    <span style={{ letterSpacing: "-.05pt" }}> </span>business
                    <span style={{ letterSpacing: "-.1pt" }}> </span>as
                    <span style={{ letterSpacing: "-.35pt" }}> </span>Atlas
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Space
                  </span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span style={{ lineHeight: "150%" }}>
                    ("
                    <b>
                      Atlas<span style={{ letterSpacing: ".05pt" }}> </span>
                      Space
                    </b>
                    ,"
                  </span>
                  <span
                    lang="TR"
                    style={{ lineHeight: "150%", letterSpacing: ".05pt" }}
                  >
                    {" "}
                    “<b>Platform</b>,"{" "}
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    "<b>we</b>,"<span style={{ letterSpacing: ".1pt" }}> </span>
                    "<b>us</b>,"
                    <span style={{ letterSpacing: ".05pt" }}> </span>or
                    <span style={{ letterSpacing: ".1pt" }}> </span>"<b>our</b>
                    ")
                  </span>
                  <span style={{ lineHeight: "150%" }}> </span>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    a company registered in Delaware, United States at 651 N
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    Broad st, SUİTE 206, Middletown, DE 19709. our VAT number is
                    6218517.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    We operate the website https://atlas.space (the "<b>Site</b>
                    "),{" "}
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the web application{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    the mobile application Atlas Space (the "<b>App</b>"
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    , “<b>Platform</b>”)
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    , as well as any other related products and services that
                    refer or link
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    to<span style={{ letterSpacing: "-.15pt" }}> </span>these
                    <span style={{ letterSpacing: "2.0pt" }}> </span>legal terms
                    (the "<b>Legal Terms</b>") (collectively, the "
                    <b>Services</b>").
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.2pt" }}> </span>can
                    <span style={{ letterSpacing: "-.15pt" }}> </span>contact us{" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    by email at
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  ></span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: ".05pt",
                    }}
                  >
                    <a href="mailto:connect@atlas.space?subject=Privacy%20Policy">
                      connect@atlas.space
                    </a>
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: ".05pt",
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    or by mail to 651 N Broad st, SU
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    I
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    TE 206, Middletown, United States.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    These Legal
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Terms
                    constitute a legally binding agreement made between you,
                    whether
                    <span style={{ letterSpacing: "2.0pt" }}> </span>personally
                    or on behalf of an entity ("<b>you</b>"), and IMMERSIVE
                    <span style={{ letterSpacing: "-.05pt" }}> </span>DREAM
                    <span style={{ letterSpacing: "-.2pt" }}> </span>TECH,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>INC
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    . Aka{" "}
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Atlas Space
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    ,<span style={{ letterSpacing: "-.45pt" }}> </span>
                    concerning
                    <span style={{ letterSpacing: "-.4pt" }}> </span>your
                    <span style={{ letterSpacing: "-.4pt" }}> </span>access
                    <span style={{ letterSpacing: "-.4pt" }}> </span>to
                    <span style={{ letterSpacing: "2.0pt" }}> </span>and use of
                    the Services. You agree that by accessing the Services, you
                    have read,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>understood,
                    and agreed to be bound by all of these Legal Terms. IF YOU
                    DO NOT
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    AGREE<span style={{ letterSpacing: "-.05pt" }}> </span>WITH
                    <span style={{ letterSpacing: "-.35pt" }}> </span>ALL
                    <span style={{ letterSpacing: "-.2pt" }}> </span>OF
                    <span style={{ letterSpacing: "-.15pt" }}> </span>THESE
                    LEGAL
                    <span style={{ letterSpacing: "-.35pt" }}> </span>TERMS,
                    <span style={{ letterSpacing: "-.1pt" }}> </span>THEN
                    <span style={{ letterSpacing: "-.15pt" }}> </span>YOU
                    <span style={{ letterSpacing: "-.3pt" }}> </span>ARE
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    EXPRESSLY
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    PROHIBITED<span style={{ letterSpacing: "-.35pt" }}> </span>
                    FROM
                    <span style={{ letterSpacing: "-.25pt" }}> </span>USING
                    <span style={{ letterSpacing: "-.35pt" }}> </span>THE
                    <span style={{ letterSpacing: "-.25pt" }}> </span>SERVICES
                    <span style={{ letterSpacing: "-.45pt" }}> </span>AND
                    <span style={{ letterSpacing: "-.3pt" }}> </span>YOU
                    <span style={{ letterSpacing: "-.25pt" }}> </span>MUST
                    <span style={{ letterSpacing: "-.35pt" }}> </span>
                    DISCONTINUE
                    <span style={{ letterSpacing: "-.25pt" }}> </span>USE
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>IMMEDIATELY.</span>
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>
                    We will provide you with prior notice of any scheduled
                    changes to the Services you are using. The modified Legal
                    Terms will become effective upon posting or notifying you by
                    connect@atlas.space, as stated in the email message. By
                    continuing to use the Services after the effective date of
                    any changes, you agree to be bound by the modified terms.
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>
                    The Services are intended for users who are at least 13
                    years of age. All users who are minors in the jurisdiction
                    in which they reside (generally under the age of 18) must
                    have the permission of, and be directly supervised by, their
                    parent or guardian to use the
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>
                    Services. If you are a minor, you must have your parent or
                    guardian read and agree to these Legal Terms prior to you
                    using the Services.
                  </span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>&nbsp;</span>
                </p>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%" }}>
                    We recommend that you print a copy of these Legal Terms for
                    your records
                  </span>
                  <span style={{ lineHeight: "150%" }}>
                    <br style={{ pageBreakBefore: "always" }} />
                  </span>
                </p>
                <h1>TABLE OF CONTENTS</h1>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  <span lang="TR">1. </span>OUR SERVICES
                </p>
                <p className="MsoNormal">
                  <span lang="TR">2. </span>INTELLECTUAL PROPERTY RIGHTS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">3. </span>USER REPRESENTATIONS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">4. </span>PROHIBITED ACTIVITIES
                </p>
                <p className="MsoNormal">
                  <span lang="TR">5. </span>USER GENERATED CONTRIBUTIONS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">6. </span>CONTRIBUTION LICENSE
                </p>
                <p className="MsoNormal">
                  <span lang="TR">7. </span>SERVICES MANAGEMENT
                </p>
                <p className="MsoNormal">
                  <span lang="TR">8. </span>TERM AND TERMINATION
                </p>
                <p className="MsoNormal">
                  <span lang="TR">9. </span>MODIFICATIONS AND INTERRUPTIONS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">10. </span>GOVERNING LAW
                </p>
                <p className="MsoNormal">
                  <span lang="TR">11. </span>DISPUTE RESOLUTION
                </p>
                <p className="MsoNormal">
                  <span lang="TR">12. </span>CORRECTIONS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">13. </span>DISCLAIMER
                </p>
                <p className="MsoNormal">
                  <span lang="TR">14. </span>LIMITATIONS OF LIABILITY
                </p>
                <p className="MsoNormal">
                  <span lang="TR">15. </span>INDEMNIFICATION
                </p>
                <p className="MsoNormal">
                  <span lang="TR">16. </span>USER DATA
                </p>
                <p className="MsoNormal">
                  <span lang="TR">17. </span>ELECTRONIC COMMUNICATIONS,
                  TRANSACTIONS, AND SIGNATURES
                </p>
                <p className="MsoNormal">
                  <span lang="TR">18. </span>MISCELLANEOUS
                </p>
                <p className="MsoNormal">
                  <span lang="TR">19. </span>CONTACT US
                </p>
                <span
                  style={{
                    fontSize: "10.5pt",
                    lineHeight: "150%",
                    fontFamily: '"Arial",sans-serif',
                  }}
                >
                  <br style={{ pageBreakBefore: "always" }} />
                </span>
                <p className="MsoNormal">&nbsp;</p>
                <h1
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR">1. </span>OUR SERVICES
                </h1>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  The information provided when using the Services is not
                  intended for distribution to or use by any person or entity in
                  any jurisdiction or country where such distribution or use
                  would be contrary to law or regulation or which would subject
                  us to any registration requirement within such jurisdiction or
                  country. Accordingly, those persons who choose to access the
                  Services from other locations do so on their own initiative
                  and are solely responsible for compliance with local laws, if
                  and to the extent local laws are applicable.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  The Services are not tailored to comply with industry-specific
                  regulations (Health Insurance Portability and Accountability
                  Act (HIPAA), Federal Information Security Management Act
                  (FISMA), etc.), so if your interactions would be subjected to
                  such laws, you may not use the Services. You may not use the
                  Services in a way that would violate the Gramm-Leach-Bliley
                  Act (GLBA).
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">2. </span>INTELLECTUAL
                  <span style={{ letterSpacing: "1.9pt" }}> </span>PROPERTY
                  <span style={{ letterSpacing: "1.9pt" }}></span>
                  <span style={{ letterSpacing: "-.1pt" }}>RIGHTS</span>
                </h1>
                <p className="MsoNormal">&nbsp;</p>
                <h2>Our intellectual property</h2>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  <span lang="TR">W</span>e are the owner or the licensee of all
                  intellectual property rights in our Services,
                </p>
                <p className="MsoNormal">
                  including all source code, databases, functionality, software,
                  website designs, audio, video, text, photographs, and graphics
                  in the Services (collectively, the "Content"), as well as the
                  trademarks, service marks, and logos contained therein (the
                  "Marks").
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  Our Content and Marks are protected by copyright and trademark
                  laws (and various other intellectual property rights and
                  unfair competition laws) and treaties in the United States and
                  around the world.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  The Content and Marks are provided in or through the Services
                  "AS IS" for your personal, non-commercial use or internal
                  business purpose only.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h2>Your use of our Services</h2>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  Subject to your compliance with these Legal Terms, including
                  the "PROHIBITED
                </p>
                <p className="MsoNormal">
                  ACTIVITIES" section below, we grant you a non-exclusive,
                  non-transferable, revocable license to:
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>access the
                  Services; and
                </p>
                <p className="MsoNormal" style={{ marginLeft: "10.9pt" }}>
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>download or print
                  a copy of any portion of the Content to which you have
                  properly gained access.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  solely for your personal, non-commercial use or internal
                  business purpose.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  Except as set out in this section or elsewhere in our Legal
                  Terms, no part of the Services and no Content or Marks may be
                  copied, reproduced, aggregated, republished, uploaded, posted,
                  publicly displayed, encoded, translated, transmitted,
                  distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior
                  written permission.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  If you wish to make any use of the Services, Content, or Marks
                  other than as set out in this section or elsewhere in our
                  Legal Terms, please address your request to:{" "}
                  <span lang="TR">
                    <a href="mailto:connect@atlas.space">connect@atlas.space</a>
                    .{" "}
                  </span>
                  If we ever grant you the permission to post, reproduce, or
                  publicly display any part of our Services or Content, you must
                  identify us as the owners or licensors of the Services,
                  Content, or Marks and ensure that any copyright or proprietary
                  notice appears or is visible on posting, reproducing, or
                  displaying our Content.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  We reserve all rights not expressly granted to you in and to
                  the Services, Content, and Marks.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  Any breach of these Intellectual Property Rights will
                  constitute a material breach of our Legal Terms and your right
                  to use our Services will terminate immediately.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h2>
                  Your submissions<span lang="TR"> and contributions</span>
                </h2>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginRight: "-2.5pt",
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Please<span style={{ letterSpacing: "-.2pt" }}> </span>
                    review
                    <span style={{ letterSpacing: "-.2pt" }}> </span>this
                    <span style={{ letterSpacing: "-.2pt" }}> </span>section
                    <span style={{ letterSpacing: "-.2pt" }}> </span>and
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>"PROHIBITED
                    <span style={{ letterSpacing: "-.45pt" }}> </span>
                    ACTIVITIES"
                    <span style={{ letterSpacing: "-.15pt" }}> </span>section
                    <span style={{ letterSpacing: "-.2pt" }}> </span>carefully
                    <span style={{ letterSpacing: "-.2pt" }}></span>prior
                    <span style={{ letterSpacing: "-.2pt" }}> </span>to
                    <span style={{ letterSpacing: "2.0pt" }}> </span>using
                    <span style={{ letterSpacing: "-.15pt" }}> </span>our
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}></span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>understand
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(a)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>rights
                    <span style={{ letterSpacing: "-.15pt" }}></span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>give
                    <span style={{ letterSpacing: "-.15pt" }}> </span>us
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(b)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    obligations
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>have
                    <span style={{ letterSpacing: "2.0pt" }}> </span>when you
                    post or upload any content through the Services.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      Submissions:{" "}
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    By directly sending us any question, comment, suggestion,
                    idea,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>feedback,
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>other
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.15pt" }}> </span>about
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}></span>
                    ("Submissions"),
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>agree
                    <span style={{ letterSpacing: "-.15pt" }}></span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>assign
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "2.0pt" }}> </span>us all
                    intellectual property rights in such Submission. You agree
                    that we shall own this
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Submission and be entitled to its unrestricted use and
                    dissemination for any lawful
                    <span style={{ letterSpacing: "2.0pt" }}> </span>purpose,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>commercial
                    <span style={{ letterSpacing: "-.25pt" }}> </span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>otherwise,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>without
                    <span style={{ letterSpacing: "-.25pt" }}></span>
                    acknowledgment
                    <span style={{ letterSpacing: "-.25pt" }}> </span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    compensation
                    <span style={{ letterSpacing: "-.25pt" }}> </span>to
                    <span style={{ letterSpacing: "-.25pt" }}> </span>you.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                        color: "#585858",
                      }}
                    >
                      Contributions:
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      color: "#585858",
                    }}
                  >
                    The Services may invite you to chat, contribute to, or
                    participate in blogs,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>message
                    boards, online forums, and other functionality during which
                    you may create,
                    <span style={{ letterSpacing: ".05pt" }}> </span>submit,
                    post, display, transmit, publish, distribute, or broadcast
                    content and materials to
                    <span style={{ letterSpacing: ".05pt" }}> </span>us
                    <span style={{ letterSpacing: "-.05pt" }}> </span>or through
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    Services,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>including
                    but
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    limited
                    <span style={{ letterSpacing: "-.05pt" }}> </span>to text,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>writings,
                    video,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>audio
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      color: "#585858",
                    }}
                  >
                    {" "}
                    photographs, music, graphics, comments, reviews, rating
                    suggestions, personal information, or other material
                    ("Contributions"). Any Submission that is publicly posted
                    shall also be treated as a Contribution.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-.95pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: ".05pt",
                    marginRight: "-.95pt",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                    lineHeight: "136%",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "136%",
                      fontFamily: '"Arial",sans-serif',
                      color: "#585858",
                    }}
                  >
                    You<span style={{ letterSpacing: "-.1pt" }}> </span>
                    understand
                    <span style={{ letterSpacing: "-.05pt" }}> </span>that
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    Contributions
                    <span style={{ letterSpacing: "-.1pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>be
                    <span style={{ letterSpacing: "-.05pt" }}> </span>viewable
                    <span style={{ letterSpacing: "-.05pt" }}></span>by
                    <span style={{ letterSpacing: "-.1pt" }}> </span>other
                    <span style={{ letterSpacing: "-.05pt" }}> </span>users
                    <span style={{ letterSpacing: "-.05pt" }}></span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.1pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-1.45pt" }}> </span>possibly
                    <span style={{ letterSpacing: "-.05pt" }}> </span>through
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    third-party websites.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-.95pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginRight: "-.95pt", lineHeight: "136%" }}
                >
                  <b>
                    <span style={{ lineHeight: "136%", color: "#585858" }}>
                      When you post Contributions, you grant us a license
                      (including use of your name,
                      <span style={{ letterSpacing: "-1.5pt" }}> </span>
                      trademarks, and logos):{" "}
                    </span>
                  </b>
                  <span style={{ lineHeight: "136%", color: "#585858" }}>
                    By posting any Contributions, you grant us an unrestricted,
                    <span style={{ letterSpacing: ".05pt" }}> </span>unlimited,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    irrevocable, perpetual,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    non-exclusive, transferable,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    royalty-free, fully-paid,
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{ marginRight: "-.95pt", lineHeight: "136%" }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "136%",
                      fontFamily: '"Arial",sans-serif',
                      color: "#585858",
                    }}
                  >
                    worldwide right, and license to: use, copy, reproduce,
                    distribute, sell, resell, publish,
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    broadcast, retitle, store, publicly perform, publicly
                    display, reformat, translate, excerpt (in
                    <span style={{ letterSpacing: ".05pt" }}> </span>
                    whole or in part), and exploit your Contributions
                    (including, without limitation, your image,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>
                    name, and voice) for any purpose, commercial, advertising,
                    or otherwise, to prepare
                    <span style={{ letterSpacing: ".05pt" }}> </span>derivative
                    works of, or incorporate into other works, your
                    Contributions, and to sublicense
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>the
                    licenses granted in this section. Our use and distribution
                    may occur in any media
                    <span style={{ letterSpacing: ".05pt" }}> </span>formats
                    <span style={{ letterSpacing: "-.05pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>through
                    any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>media
                    channels.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: ".05pt",
                    marginRight: "-.95pt",
                    marginBottom: ".0001pt",
                    marginLeft: "0in",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-.95pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      color: "#585858",
                    }}
                  >
                    This license includes our use of your name, company name,
                    and franchise name, as
                    <span style={{ letterSpacing: ".05pt" }}> </span>applicable,
                    and any of the trademarks, service marks, trade names,
                    logos, and personal
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>and
                    <span style={{ letterSpacing: "-.05pt" }}> </span>commercial
                    <span style={{ letterSpacing: "-.05pt" }}> </span>images you
                    <span style={{ letterSpacing: "-.05pt" }}> </span>provide.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ color: "#585858" }}>&nbsp;</span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      You<span style={{ letterSpacing: "-.2pt" }}> </span>are
                      <span style={{ letterSpacing: "-.2pt" }}> </span>
                      responsible
                      <span style={{ letterSpacing: "-.2pt" }}> </span>for
                      <span style={{ letterSpacing: "-.2pt" }}> </span>what
                      <span style={{ letterSpacing: "-.2pt" }}> </span>you
                      <span style={{ letterSpacing: "-.2pt" }}> </span>post
                      <span style={{ letterSpacing: "-.2pt" }}> </span>or
                      <span style={{ letterSpacing: "-.2pt" }}> </span>upload:
                      <span style={{ letterSpacing: "-.2pt" }}> </span>
                    </span>
                  </b>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    By<span style={{ letterSpacing: "-.2pt" }}> </span>sending
                    <span style={{ letterSpacing: "-.2pt" }}> </span>us
                    <span style={{ letterSpacing: "-.2pt" }}> </span>Submissions
                    <span style={{ letterSpacing: "-.2pt" }}> </span>through
                    <span style={{ letterSpacing: "2.0pt" }}> </span>any part of
                    the Services you:
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    confirm<span style={{ letterSpacing: "-.2pt" }}> </span>that
                    <span style={{ letterSpacing: "-.2pt" }}> </span>you
                    <span style={{ letterSpacing: "-.2pt" }}> </span>have
                    <span style={{ letterSpacing: "-.2pt" }}> </span>read
                    <span style={{ letterSpacing: "-.2pt" }}> </span>and
                    <span style={{ letterSpacing: "-.2pt" }}> </span>agree
                    <span style={{ letterSpacing: "-.2pt" }}> </span>with
                    <span style={{ letterSpacing: "-.2pt" }}> </span>our
                    <span style={{ letterSpacing: "-.2pt" }}> </span>"PROHIBITED
                    <span style={{ letterSpacing: "-.45pt" }}> </span>
                    ACTIVITIES"
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "2.0pt" }}> </span>will not
                    post, send, publish, upload, or transmit through the
                    Services any
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Submission that is illegal, harassing, hateful, harmful,
                    defamatory, obscene,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>bullying,
                    <span style={{ letterSpacing: "-.25pt" }}></span>abusive,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    discriminatory,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    threatening
                    <span style={{ letterSpacing: "-.25pt" }}> </span>to
                    <span style={{ letterSpacing: "-.25pt" }}> </span>any
                    <span style={{ letterSpacing: "-.25pt" }}> </span>person
                    <span style={{ letterSpacing: "-.25pt" }}></span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>group,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>sexually
                    <span style={{ letterSpacing: "2.0pt" }}></span>explicit,
                    false, inaccurate, deceitful, or misleading;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    to<span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>extent
                    <span style={{ letterSpacing: "-.2pt" }}> </span>permissible
                    <span style={{ letterSpacing: "-.2pt" }}> </span>by
                    <span style={{ letterSpacing: "-.2pt" }}> </span>applicable
                    <span style={{ letterSpacing: "-.2pt" }}> </span>law,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>waive
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>and
                    <span style={{ letterSpacing: "-.2pt" }}> </span>all
                    <span style={{ letterSpacing: "-.2pt" }}> </span>moral
                    <span style={{ letterSpacing: "-.2pt" }}> </span>rights
                    <span style={{ letterSpacing: "-.2pt" }}> </span>to
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "2.0pt" }}> </span>such
                    <span style={{ letterSpacing: "-.45pt" }}> </span>
                    Submission;
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    warrant that any such Submission are original to you or that
                    you have the
                    <span style={{ letterSpacing: "2.0pt" }}> </span>necessary
                    <span style={{ letterSpacing: "-.15pt" }}></span>rights
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>licenses
                    <span style={{ letterSpacing: "-.15pt" }}></span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>submit
                    <span style={{ letterSpacing: "-.2pt" }}> </span>such
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    Submissions
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>that
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>have
                    <span style={{ letterSpacing: "-.2pt" }}> </span>full
                    <span style={{ letterSpacing: "2.0pt" }}> </span>authority
                    <span style={{ letterSpacing: "-.1pt" }}> </span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>grant
                    <span style={{ letterSpacing: "-.1pt" }}> </span>us
                    <span style={{ letterSpacing: "-.1pt" }}> </span>the
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    above-mentioned
                    <span style={{ letterSpacing: "-.1pt" }}> </span>rights
                    <span style={{ letterSpacing: "-.1pt" }}> </span>in
                    <span style={{ letterSpacing: "-.1pt" }}> </span>relation
                    <span style={{ letterSpacing: "-.1pt" }}></span>to
                    <span style={{ letterSpacing: "-.1pt" }}> </span>your
                    <span style={{ letterSpacing: "-.1pt" }}> </span>
                    Submissions;
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.2pt" }}>and</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    warrant<span style={{ letterSpacing: "-.2pt" }}> </span>and
                    <span style={{ letterSpacing: "-.2pt" }}> </span>represent
                    <span style={{ letterSpacing: "-.2pt" }}> </span>that
                    <span style={{ letterSpacing: "-.2pt" }}> </span>your
                    <span style={{ letterSpacing: "-.2pt" }}> </span>Submissions
                    <span style={{ letterSpacing: "-.2pt" }}> </span>do
                    <span style={{ letterSpacing: "-.2pt" }}> </span>not
                    <span style={{ letterSpacing: "-.2pt" }}> </span>constitute
                    <span style={{ letterSpacing: "-.2pt" }}> </span>
                    confidential
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>information</span>
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    .
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You are solely responsible for your Submissions and you
                    expressly agree to reimburse
                    <span style={{ letterSpacing: "2.0pt" }}> </span>us
                    <span style={{ letterSpacing: "-.15pt" }}> </span>for
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>all
                    <span style={{ letterSpacing: "-.15pt" }}> </span>losses
                    <span style={{ letterSpacing: "-.15pt" }}> </span>that
                    <span style={{ letterSpacing: "-.15pt" }}> </span>we
                    <span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.15pt" }}> </span>suffer
                    <span style={{ letterSpacing: "-.15pt" }}> </span>because
                    <span style={{ letterSpacing: "-.15pt" }}></span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>your
                    <span style={{ letterSpacing: "-.15pt" }}> </span>breach
                    <span style={{ letterSpacing: "-.15pt" }}></span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(a)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>this
                    <span style={{ letterSpacing: "-.15pt" }}> </span>section,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(b)
                    <span style={{ letterSpacing: "2.0pt" }}> </span>any third
                    party’s intellectual property rights, or (c) applicable law.
                  </span>
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  <b>We may remove or edit your Content:</b> Although we have no
                  obligation to monitor any Contributions, we shall have the
                  right to remove or edit any Contributions at any time
                </p>
                <p className="MsoNormal">
                  without notice if in our reasonable opinion we consider such
                  Contributions harmful or in breach of these Legal Terms. If we
                  remove or edit any such Contributions, we may also suspend or
                  disable your account and report you to the authorities.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h2
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  Copyright<span style={{ letterSpacing: ".05pt" }}> </span>
                  infringement
                </h2>
                <p className="MsoBodyText">
                  <b>
                    <span
                      style={{
                        fontSize: "10.0pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoNormal">
                  <span style={{ color: "#585858" }}>
                    We respect the intellectual property rights of others. If
                    you believe that any material
                    <span style={{ letterSpacing: ".05pt" }}> </span>available
                    on or through the Services infringes upon any copyright you
                    own or control,
                    <span style={{ letterSpacing: "-1.5pt" }}> </span>please
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    immediately
                    <span style={{ letterSpacing: "-.05pt" }}> </span>refer
                    <span style={{ letterSpacing: "-.05pt" }}></span>to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>"
                  </span>
                  <span style={{ color: "#2F2FF1" }}>
                    COPYRIGHT<span style={{ letterSpacing: "-.15pt" }}> </span>
                    INFRINGEMENTS
                  </span>
                  <span style={{ color: "#585858" }}>
                    "<span style={{ letterSpacing: "-.05pt" }}> </span>section
                    <span style={{ letterSpacing: "-.05pt" }}> </span>below.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span style={{ color: "#585858" }}>&nbsp;</span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">3. </span>USER
                  <span style={{ letterSpacing: "-.3pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    REPRESENTATIONS
                  </span>
                </h1>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    By<span style={{ letterSpacing: "-.15pt" }}> </span>using
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>represent
                    <span style={{ letterSpacing: "-.15pt" }}> </span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>warrant
                    <span style={{ letterSpacing: "-.15pt" }}> </span>that:
                    <span style={{ letterSpacing: "-.15pt" }}></span>(1)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>have
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>legal
                    <span style={{ letterSpacing: "-.15pt" }}></span>capacity
                    <span style={{ letterSpacing: "2.0pt" }}> </span>and you
                    agree to comply with these Legal Terms; (2) you are not a
                    minor in the
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    jurisdiction
                    <span style={{ letterSpacing: "-.15pt" }}> </span>in
                    <span style={{ letterSpacing: "-.15pt" }}> </span>which
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>reside;
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(3)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>will
                    <span style={{ letterSpacing: "-.15pt" }}> </span>not
                    <span style={{ letterSpacing: "-.15pt" }}> </span>access
                    <span style={{ letterSpacing: "-.15pt" }}></span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}> </span>through
                    <span style={{ letterSpacing: "-.15pt" }}></span>automated
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>non-human
                    means, whether through a bot, script or otherwise; (4) you
                    will not use the
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Services<span style={{ letterSpacing: "-.15pt" }}> </span>
                    for
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>illegal
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    unauthorized
                    <span style={{ letterSpacing: "-.15pt" }}> </span>purpose;
                    <span style={{ letterSpacing: "-.15pt" }}></span>and
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(5)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>your
                    <span style={{ letterSpacing: "-.15pt" }}> </span>use
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}></span>will
                    <span style={{ letterSpacing: "-.15pt" }}> </span>not
                    <span style={{ letterSpacing: "2.0pt" }}> </span>violate any
                    applicable law or regulation.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    If<span style={{ letterSpacing: "-.15pt" }}> </span>you
                    <span style={{ letterSpacing: "-.15pt" }}> </span>provide
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.15pt" }}> </span>that
                    <span style={{ letterSpacing: "-.15pt" }}> </span>is
                    <span style={{ letterSpacing: "-.15pt" }}> </span>untrue,
                    <span style={{ letterSpacing: "-.15pt" }}></span>inaccurate,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>not
                    <span style={{ letterSpacing: "-.15pt" }}> </span>current,
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    incomplete,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>we
                    <span style={{ letterSpacing: "2.0pt" }}> </span>have the
                    right to suspend or terminate your account and refuse any
                    and all current or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>future use
                    of the Services (or any portion thereof)
                  </span>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    .
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    lang="TR"
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    4.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    USER REGISTRATION
                  </span>
                </h1>
                <p className="MsoNormal">
                  You may be required to register to use the Services. You agree
                  to keep your password confidential and will be responsible for
                  all use of your account and password. We reserve the right to
                  remove, reclaim, or change a username you select if we
                  determine, in our sole discretion, that such username is
                  inappropriate, obscene, or otherwise objectionable.
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">&nbsp;</span>
                </h1>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    5.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>PRODUCTS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    All products are subject to availability. We reserve the
                    right to discontinue any products at any time for any
                    reason. Prices for all products are subject to change.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    6.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    PURCHASES AND PAYMENT
                  </span>
                </h1>
                <p className="MsoNormal">
                  We accept the following forms of payment:
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>Visa
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>PayPal
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>Metamask
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>Google Pay
                </p>
                <p className="MsoNormal">
                  <span style={{ lineHeight: "150%" }}>•</span>
                  <span style={{ lineHeight: "150%" }}> </span>Apple Pay
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  You agree to provide current, complete, and accurate purchase
                  and account information for all purchases made via the
                  Services. You further agree to promptly update account and
                  payment information, including email address, payment method,
                  and payment card expiration date, so that we can complete your
                  transactions and contact you as needed. Sales tax will be
                  added to the price of purchases as deemed required by us. We
                  may change prices at any time. All payments shall be in US
                  dollars.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  You agree to pay all charges at the prices then in effect for
                  your purchases and any applicable shipping fees, and you
                  authorize us to charge your chosen payment provider for any
                  such amounts upon placing your order. If your order is subject
                  to recurring charges, then you consent to our charging your
                  payment method on a recurring basis
                </p>
                <p className="MsoNormal">
                  without requiring your prior approval for each recurring
                  charge, until such time as you cancel the applicable order. We
                  reserve the right to correct any errors or mistakes in
                  pricing, even if we have already requested or received
                  payment.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  We reserve the right to refuse any order placed through the
                  Services. We may, in our sole discretion, limit or cancel
                  quantities purchased per person, per household, or per order.
                  These restrictions may include orders placed by or under the
                  same customer account, the same payment method, and/or orders
                  that use the same billing or shipping address. We reserve the
                  right to limit or prohibit orders that, in our sole judgment,
                  appear to be placed by dealers, resellers, or distributors.
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span style={{ letterSpacing: "-.1pt" }}>7.</span>
                  <span style={{ letterSpacing: "-.1pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>REFUNDS POLICY</span>
                </h1>
                <p className="MsoNormal">
                  All sales are final and no refund will be issued.
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    8.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>SOFTWARE</span>
                </h1>
                <p className="MsoNormal">
                  We may include software for use in connection with our
                  Services. If such software is accompanied by an end user
                  license agreement ("EULA"), the terms of the EULA will govern
                  your use of the software. If such software is not accompanied
                  by a EULA, then
                </p>
                <p className="MsoNormal">
                  we grant to you a non-exclusive, revocable, personal, and
                  non-transferable license to use such software solely in
                  connection with our services and in accordance with these
                  Legal Terms. Any software and any related documentation is
                  provided "AS IS" without warranty of any kind, either express
                  or implied, including, without limitation, the implied
                  warranties of merchantability, fitness for a particular
                  purpose, or non-infringement. You accept any and all risk
                  arising out of use or performance of any software. You may not
                  reproduce or redistribute any software except in accordance
                  with the EULA or these Legal Terms<span lang="TR">.</span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">&nbsp;</span>
                </h1>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">9. </span>PROHIBITED
                  <span style={{ letterSpacing: "1.65pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>ACTIVITIES</span>
                </h1>
                <p className="MsoNormal" style={{ marginRight: "-2.5pt" }}>
                  <span style={{ lineHeight: "150%", letterSpacing: "-.1pt" }}>
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You<span style={{ letterSpacing: "-.05pt" }}> </span>may
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not
                    <span style={{ letterSpacing: "-.05pt" }}> </span>access
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    <span style={{ letterSpacing: "-.05pt" }}> </span>any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>purpose
                    <span style={{ letterSpacing: "-.05pt" }}></span>other
                    <span style={{ letterSpacing: "-.05pt" }}> </span>than
                    <span style={{ letterSpacing: "-.05pt" }}> </span>that
                    <span style={{ letterSpacing: "-.05pt" }}> </span>for
                    <span style={{ letterSpacing: "-.05pt" }}> </span>which
                    <span style={{ letterSpacing: "-.05pt" }}></span>we
                    <span style={{ letterSpacing: "2.0pt" }}> </span>make the
                    Services available. The Services may not be used in
                    connection with any
                    <span style={{ letterSpacing: "2.0pt" }}> </span>commercial
                    <span style={{ letterSpacing: "-.2pt" }}> </span>endeavors
                    <span style={{ letterSpacing: "-.2pt" }}> </span>except
                    <span style={{ letterSpacing: "-.2pt" }}> </span>those
                    <span style={{ letterSpacing: "-.2pt" }}> </span>that
                    <span style={{ letterSpacing: "-.2pt" }}> </span>are
                    <span style={{ letterSpacing: "-.2pt" }}> </span>
                    specifically
                    <span style={{ letterSpacing: "-.2pt" }}> </span>endorsed
                    <span style={{ letterSpacing: "-.2pt" }}></span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>approved
                    <span style={{ letterSpacing: "-.2pt" }}> </span>by
                    <span style={{ letterSpacing: "-.2pt" }}> </span>us.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    As<span style={{ letterSpacing: "-.05pt" }}> </span>a
                    <span style={{ letterSpacing: "-.05pt" }}> </span>user of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the
                    Services,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>you agree
                    <span style={{ letterSpacing: "-.05pt" }}> </span>not{" "}
                    <span style={{ letterSpacing: "-.25pt" }}>to:</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Systematically retrieve data or other content from the
                    Services to create or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>compile,
                    <span style={{ letterSpacing: "-.25pt" }}></span>directly
                    <span style={{ letterSpacing: "-.25pt" }}> </span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    indirectly,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>a
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    collection,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    compilation,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>database,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>directory
                    <span style={{ letterSpacing: "2.0pt" }}> </span>without
                    written permission from us.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Trick,<span style={{ letterSpacing: "-.2pt" }}> </span>
                    defraud,
                    <span style={{ letterSpacing: "-.2pt" }}></span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>mislead
                    <span style={{ letterSpacing: "-.2pt" }}> </span>us
                    <span style={{ letterSpacing: "-.2pt" }}> </span>and
                    <span style={{ letterSpacing: "-.2pt" }}> </span>other
                    <span style={{ letterSpacing: "-.2pt" }}> </span>users,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>especially
                    <span style={{ letterSpacing: "-.2pt" }}> </span>in
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>attempt
                    <span style={{ letterSpacing: "-.2pt" }}></span>to
                    <span style={{ letterSpacing: "-.2pt" }}> </span>learn
                    <span style={{ letterSpacing: "2.0pt" }}> </span>sensitive
                    account information such as user passwords.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Circumvent,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>disable,
                    <span style={{ letterSpacing: "-.25pt" }}></span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>otherwise
                    <span style={{ letterSpacing: "-.25pt" }}> </span>interfere
                    <span style={{ letterSpacing: "-.25pt" }}> </span>with
                    <span style={{ letterSpacing: "-.25pt" }}> </span>
                    security-related
                    <span style={{ letterSpacing: "-.25pt" }}> </span>features
                    <span style={{ letterSpacing: "-.25pt" }}></span>of
                    <span style={{ letterSpacing: "-.25pt" }}> </span>the
                    <span style={{ letterSpacing: "2.0pt" }}> </span>Services,
                    including features that prevent or restrict the use or
                    copying of any
                    <span style={{ letterSpacing: "2.0pt" }}> </span>Content or
                    enforce limitations on the use of the Services and/or the
                    Content
                    <span style={{ letterSpacing: "2.0pt" }}> </span>contained
                    <span style={{ letterSpacing: "-.45pt" }}></span>therein.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Disparage,<span style={{ letterSpacing: "-.05pt" }}> </span>
                    tarnish,
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    otherwise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>harm, in
                    <span style={{ letterSpacing: "-.05pt" }}> </span>our
                    opinion,
                    <span style={{ letterSpacing: "-.05pt" }}> </span>us and/or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>the{" "}
                    <span style={{ letterSpacing: "-.1pt" }}>Services.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Use<span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    information
                    <span style={{ letterSpacing: "-.15pt" }}> </span>obtained
                    <span style={{ letterSpacing: "-.15pt" }}></span>from
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}></span>in
                    <span style={{ letterSpacing: "-.15pt" }}> </span>order
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>harass,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>abuse,
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>harm
                    <span style={{ letterSpacing: "2.0pt" }}> </span>another
                    <span style={{ letterSpacing: "-.45pt" }}></span>person.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Make<span style={{ letterSpacing: "-.15pt" }}> </span>
                    improper
                    <span style={{ letterSpacing: "-.15pt" }}></span>use
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>our
                    <span style={{ letterSpacing: "-.15pt" }}> </span>support
                    <span style={{ letterSpacing: "-.15pt" }}> </span>services
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>submit
                    <span style={{ letterSpacing: "-.15pt" }}> </span>false
                    <span style={{ letterSpacing: "-.15pt" }}></span>reports
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>abuse
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>misconduct.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Use<span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}> </span>in
                    <span style={{ letterSpacing: "-.15pt" }}> </span>a
                    <span style={{ letterSpacing: "-.15pt" }}> </span>manner
                    <span style={{ letterSpacing: "-.15pt" }}></span>
                    inconsistent
                    <span style={{ letterSpacing: "-.15pt" }}> </span>with
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>applicable
                    <span style={{ letterSpacing: "-.15pt" }}> </span>laws
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    regulations.
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Engage in unauthorized framing of or linking to the
                    Services.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Upload or transmit (or attempt to upload or to transmit)
                    viruses, Trojan horses, or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>other
                    material, including excessive use of capital letters and
                    spamming
                    <span style={{ letterSpacing: "2.0pt" }}> </span>(continuous
                    <span style={{ letterSpacing: "-.2pt" }}> </span>posting
                    <span style={{ letterSpacing: "-.2pt" }}></span>of
                    <span style={{ letterSpacing: "-.2pt" }}> </span>repetitive
                    <span style={{ letterSpacing: "-.2pt" }}> </span>text),
                    <span style={{ letterSpacing: "-.2pt" }}> </span>that
                    <span style={{ letterSpacing: "-.2pt" }}> </span>interferes
                    <span style={{ letterSpacing: "-.2pt" }}> </span>with
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>party’s
                    <span style={{ letterSpacing: "-.2pt" }}></span>
                    uninterrupted
                    <span style={{ letterSpacing: "2.0pt" }}> </span>use and
                    enjoyment of the Services or modifies, impairs, disrupts,
                    alters, or
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    interferes<span style={{ letterSpacing: "-.2pt" }}> </span>
                    with
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>use,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>features,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>functions,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>operation,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>maintenance
                    <span style={{ letterSpacing: "-.2pt" }}> </span>of
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>Services.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Engage in any automated use of the system, such as using
                    scripts to send
                    <span style={{ letterSpacing: "2.0pt" }}> </span>comments
                    <span style={{ letterSpacing: "-.2pt" }}></span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>messages,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>using
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>data
                    <span style={{ letterSpacing: "-.2pt" }}> </span>mining,
                    <span style={{ letterSpacing: "-.2pt" }}></span>robots,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>similar
                    <span style={{ letterSpacing: "-.2pt" }}></span>data
                    <span style={{ letterSpacing: "-.2pt" }}> </span>gathering
                    <span style={{ letterSpacing: "2.0pt" }}> </span>and
                    extraction tools.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Delete<span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>copyright
                    or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>other
                    proprietary
                    <span style={{ letterSpacing: "-.05pt" }}> </span>rights
                    notice
                    <span style={{ letterSpacing: "-.05pt" }}> </span>from any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>Content.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Attempt<span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>
                    impersonate
                    <span style={{ letterSpacing: "-.15pt" }}> </span>another
                    <span style={{ letterSpacing: "-.15pt" }}></span>user
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>person
                    <span style={{ letterSpacing: "-.15pt" }}></span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>use
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>username
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>another
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>user.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Upload<span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>transmit
                    <span style={{ letterSpacing: "-.15pt" }}> </span>(or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>attempt
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>upload
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>to
                    <span style={{ letterSpacing: "-.15pt" }}> </span>transmit)
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>material
                    <span style={{ letterSpacing: "-.15pt" }}> </span>that
                    <span style={{ letterSpacing: "-.15pt" }}> </span>acts
                    <span style={{ letterSpacing: "-.15pt" }}> </span>as
                    <span style={{ letterSpacing: "-.15pt" }}> </span>a
                    <span style={{ letterSpacing: "2.0pt" }}> </span>passive or
                    active information collection or transmission mechanism,
                    including
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    without<span style={{ letterSpacing: "-.2pt" }}> </span>
                    limitation,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>clear
                    <span style={{ letterSpacing: "-.2pt" }}> </span>graphics
                    <span style={{ letterSpacing: "-.2pt" }}></span>interchange
                    <span style={{ letterSpacing: "-.2pt" }}> </span>formats
                    <span style={{ letterSpacing: "-.2pt" }}> </span>("gifs"),
                    <span style={{ letterSpacing: "-.2pt" }}> </span>1×1
                    <span style={{ letterSpacing: "-.2pt" }}> </span>pixels,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>web
                    <span style={{ letterSpacing: "-.2pt" }}> </span>bugs,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>cookies, or
                    other similar devices (sometimes referred to as "spyware" or
                    "passive
                    <span style={{ letterSpacing: "2.0pt" }}> </span>collection
                    mechanisms" or "pcms").
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Interfere<span style={{ letterSpacing: "-.15pt" }}> </span>
                    with,
                    <span style={{ letterSpacing: "-.15pt" }}></span>disrupt,
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>create
                    <span style={{ letterSpacing: "-.15pt" }}></span>an
                    <span style={{ letterSpacing: "-.15pt" }}> </span>undue
                    <span style={{ letterSpacing: "-.15pt" }}> </span>burden
                    <span style={{ letterSpacing: "-.15pt" }}></span>on
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>networks
                    <span style={{ letterSpacing: "2.0pt" }}> </span>or services
                    connected to the Services.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Harass,<span style={{ letterSpacing: "-.2pt" }}> </span>
                    annoy,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>intimidate,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>threaten
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>of
                    <span style={{ letterSpacing: "-.2pt" }}> </span>our
                    <span style={{ letterSpacing: "-.2pt" }}> </span>employees
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>agents
                    <span style={{ letterSpacing: "-.2pt" }}> </span>engaged
                    <span style={{ letterSpacing: "-.2pt" }}></span>in
                    <span style={{ letterSpacing: "2.0pt" }}> </span>providing
                    any portion of the Services to you.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Attempt<span style={{ letterSpacing: "-.2pt" }}> </span>to
                    <span style={{ letterSpacing: "-.2pt" }}> </span>bypass
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>measures
                    <span style={{ letterSpacing: "-.2pt" }}> </span>of
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.2pt" }}></span>designed
                    <span style={{ letterSpacing: "-.2pt" }}> </span>to
                    <span style={{ letterSpacing: "-.2pt" }}> </span>prevent
                    <span style={{ letterSpacing: "-.2pt" }}></span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>restrict
                    <span style={{ letterSpacing: "2.0pt" }}> </span>access to
                    the Services, or any portion of the Services.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Copy<span style={{ letterSpacing: "-.25pt" }}> </span>or
                    <span style={{ letterSpacing: "-.25pt" }}> </span>adapt
                    <span style={{ letterSpacing: "-.25pt" }}> </span>the
                    <span style={{ letterSpacing: "-.25pt" }}> </span>Services'
                    <span style={{ letterSpacing: "-.25pt" }}> </span>software,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>including
                    <span style={{ letterSpacing: "-.25pt" }}> </span>but
                    <span style={{ letterSpacing: "-.25pt" }}> </span>not
                    <span style={{ letterSpacing: "-.25pt" }}> </span>limited
                    <span style={{ letterSpacing: "-.25pt" }}> </span>to
                    <span style={{ letterSpacing: "-.25pt" }}> </span>Flash,
                    <span style={{ letterSpacing: "-.25pt" }}> </span>PHP,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>HTML,
                    JavaScript, or other code.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Except as permitted by applicable law, decipher, decompile,
                    disassemble, or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>reverse
                    <span style={{ letterSpacing: "-.15pt" }}></span>engineer
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>software
                    <span style={{ letterSpacing: "-.15pt" }}></span>comprising
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>in
                    <span style={{ letterSpacing: "-.15pt" }}> </span>any
                    <span style={{ letterSpacing: "-.15pt" }}> </span>way
                    <span style={{ letterSpacing: "-.15pt" }}> </span>making
                    <span style={{ letterSpacing: "-.15pt" }}> </span>up
                    <span style={{ letterSpacing: "-.15pt" }}> </span>a
                    <span style={{ letterSpacing: "-.15pt" }}> </span>part
                    <span style={{ letterSpacing: "-.15pt" }}> </span>of
                    <span style={{ letterSpacing: "2.0pt" }}> </span>the
                    <span style={{ letterSpacing: "-.45pt" }}> </span>Services.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Except<span style={{ letterSpacing: "-.15pt" }}> </span>as
                    <span style={{ letterSpacing: "-.15pt" }}> </span>may
                    <span style={{ letterSpacing: "-.15pt" }}> </span>be
                    <span style={{ letterSpacing: "-.15pt" }}> </span>the
                    <span style={{ letterSpacing: "-.15pt" }}> </span>result
                    <span style={{ letterSpacing: "-.15pt" }}></span>of
                    <span style={{ letterSpacing: "-.15pt" }}> </span>standard
                    <span style={{ letterSpacing: "-.15pt" }}> </span>search
                    <span style={{ letterSpacing: "-.15pt" }}></span>engine
                    <span style={{ letterSpacing: "-.15pt" }}> </span>or
                    <span style={{ letterSpacing: "-.15pt" }}> </span>Internet
                    <span style={{ letterSpacing: "-.15pt" }}></span>browser
                    <span style={{ letterSpacing: "-.15pt" }}> </span>usage,
                    <span style={{ letterSpacing: "2.0pt" }}> </span>use,
                    launch, develop, or distribute any automated system,
                    including without
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    limitation,<span style={{ letterSpacing: "-.3pt" }}> </span>
                    any
                    <span style={{ letterSpacing: "-.3pt" }}> </span>spider,
                    <span style={{ letterSpacing: "-.3pt" }}> </span>robot,
                    <span style={{ letterSpacing: "-.3pt" }}> </span>cheat
                    <span style={{ letterSpacing: "-.3pt" }}> </span>utility,
                    <span style={{ letterSpacing: "-.3pt" }}></span>scraper,
                    <span style={{ letterSpacing: "-.3pt" }}> </span>or
                    <span style={{ letterSpacing: "-.3pt" }}> </span>offline
                    <span style={{ letterSpacing: "-.3pt" }}></span>reader
                    <span style={{ letterSpacing: "-.3pt" }}> </span>that
                    <span style={{ letterSpacing: "-.3pt" }}> </span>accesses
                    <span style={{ letterSpacing: "2.0pt" }}></span>the
                    Services, or use or launch any unauthorized script or other
                    software.
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Use a buying agent or purchasing agent to make purchases on
                    the Services.
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Make<span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>
                    unauthorized
                    <span style={{ letterSpacing: "-.2pt" }}> </span>use
                    <span style={{ letterSpacing: "-.2pt" }}> </span>of
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>Services,
                    <span style={{ letterSpacing: "-.2pt" }}> </span>including
                    <span style={{ letterSpacing: "-.2pt" }}></span>collecting
                    <span style={{ letterSpacing: "-.2pt" }}> </span>usernames
                    <span style={{ letterSpacing: "-.2pt" }}> </span>and/or
                    <span style={{ letterSpacing: "2.0pt" }}> </span>email
                    addresses of users by electronic or other means for the
                    purpose of sending
                    <span style={{ letterSpacing: "2.0pt" }}> </span>unsolicited
                    email, or creating user accounts by automated means or under
                    false
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>pretenses.</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Use<span style={{ letterSpacing: "-.05pt" }}> </span>the
                    <span style={{ letterSpacing: "-.05pt" }}> </span>Services
                    <span style={{ letterSpacing: "-.05pt" }}> </span>as part
                    <span style={{ letterSpacing: "-.05pt" }}></span>of
                    <span style={{ letterSpacing: "-.05pt" }}> </span>any
                    <span style={{ letterSpacing: "-.05pt" }}> </span>effort to
                    <span style={{ letterSpacing: "-.05pt" }}> </span>compete
                    <span style={{ letterSpacing: "-.05pt" }}> </span>with us
                    <span style={{ letterSpacing: "-.05pt" }}></span>or
                    <span style={{ letterSpacing: "-.05pt" }}> </span>otherwise
                    <span style={{ letterSpacing: "-.05pt" }}> </span>use{" "}
                    <span style={{ letterSpacing: "-.25pt" }}>the</span>
                  </span>
                </p>
                <p
                  className="MsoBodyText"
                  style={{
                    marginTop: "0in",
                    marginRight: "-2.5pt",
                    marginBottom: ".0001pt",
                    marginLeft: "10.9pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Services<span style={{ letterSpacing: "-.2pt" }}> </span>
                    and/or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>the
                    <span style={{ letterSpacing: "-.2pt" }}> </span>Content
                    <span style={{ letterSpacing: "-.2pt" }}></span>for
                    <span style={{ letterSpacing: "-.2pt" }}> </span>any
                    <span style={{ letterSpacing: "-.2pt" }}> </span>
                    revenue-generating
                    <span style={{ letterSpacing: "-.2pt" }}> </span>endeavor
                    <span style={{ letterSpacing: "-.2pt" }}> </span>or
                    <span style={{ letterSpacing: "-.2pt" }}> </span>commercial
                    <span style={{ letterSpacing: "2.0pt" }}> </span>
                    <span style={{ letterSpacing: "-.1pt" }}>enterprise</span>
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontFamily: '"Arial",sans-serif',
                      letterSpacing: "-.1pt",
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    10.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>USER</span>
                  <span style={{ letterSpacing: "-.05pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>GENERATED</span>
                  <span style={{ letterSpacing: "-.05pt" }}></span>
                  <span style={{ letterSpacing: "-.1pt" }}>CONTRIBUTIONS</span>
                </h1>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <b>
                    <span
                      style={{
                        fontSize: "10.5pt",
                        lineHeight: "150%",
                        fontFamily: '"Arial",sans-serif',
                      }}
                    >
                      &nbsp;
                    </span>
                  </b>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The Services may invite you to chat, contribute to, or
                    participate in blogs, message boards, online forums, and
                    other functionality, and may provide you with the
                    opportunity to create, submit, post, display, transmit,
                    perform, publish, distribute, or broadcast content and
                    materials to us or on the Services, including but not
                    limited to text, writings, video, audio, photographs,
                    graphics, comments, suggestions, or personal information or
                    other material (collectively, "Contributions").
                    Contributions may be viewable by other users of the Services
                    and through third-party websites. As such, any Contributions
                    you transmit may be treated as non-confidential and
                    non-proprietary. When you create or make available any
                    Contributions, you thereby represent and warrant that:
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    The creation, distribution, transmission, public display, or
                    performance, and the accessing, downloading, or copying of
                    your Contributions do not and will not
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    infringe the proprietary rights, including but not limited
                    to the copyright, patent, trademark, trade secret, or moral
                    rights of any third party.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You are the creator and owner of or have the necessary
                    licenses, rights, consents, releases, and permissions to use
                    and to authorize us, the Services, and other users of the
                    Services to use your Contributions in any manner
                    contemplated by the Services and these Legal Terms.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    You have the written consent, release, and/or permission of
                    each and every
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    identifiable individual person in your Contributions to use
                    the name or likeness of each and every such identifiable
                    individual person to enable inclusion and use of your
                    Contributions in any manner contemplated by the Services and
                    these Legal Terms.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions are not false, inaccurate, or misleading.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions are not unsolicited or unauthorized
                    advertising, promotional materials, pyramid schemes, chain
                    letters, spam, mass mailings, or other forms of
                    solicitation.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions are not obscene, lewd, lascivious,
                    filthy, violent, harassing, libelous, slanderous, or
                    otherwise objectionable (as determined by us).
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not ridicule, mock, disparage,
                    intimidate, or abuse anyone.{" "}
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions are not used to harass or threaten (in
                    the legal sense of those terms) any other person and to
                    promote violence against a specific person or class of
                    people.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not violate any applicable law,
                    regulation, or rule.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not violate the privacy or publicity
                    rights of any third party.{" "}
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not violate any applicable law
                    concerning child pornography, or otherwise intended to
                    protect the health or well-being of minors.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not include any offensive comments
                    that are connected to race, national origin, gender, sexual
                    preference, or physical handicap.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    •
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    {" "}
                  </span>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Your Contributions do not otherwise violate, or link to
                    material that violates, any provision of these Legal Terms,
                    or any applicable law or regulation.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Any use of the Services in violation of the foregoing
                    violates these Legal Terms and may result in, among other
                    things, termination or suspension of your rights to use the
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    Services.
                  </span>
                </p>
                <p className="MsoBodyText" style={{ marginRight: "-2.5pt" }}>
                  <span
                    style={{
                      fontSize: "10.5pt",
                      lineHeight: "150%",
                      fontFamily: '"Arial",sans-serif',
                    }}
                  >
                    &nbsp;
                  </span>
                </p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR">11. </span>CONTRIBUTION
                  <span style={{ letterSpacing: "2.25pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>LICENSE</span>
                </h1>
                <p className="MsoNormal">
                  By posting your Contributions to any part of the Services or
                  making Contributions accessible to the Services by linking
                  your account from the Services to any of your social
                  networking accounts, you automatically grant, and you
                  represent and warrant that you have the right to grant, to us
                  an unrestricted, unlimited, irrevocable, perpetual, non-
                  exclusive, transferable, royalty-free, fully-paid, worldwide
                  right, and license to host, use, copy, reproduce, disclose,
                  sell, resell, publish, broadcast, retitle, archive, store,
                  cache, publicly perform, publicly display, reformat,
                  translate, transmit, excerpt (in whole or in part), and
                  distribute such Contributions (including, without limitation,
                  your image and voice) for any purpose, commercial,
                  advertising, or otherwise, and to prepare derivative works of,
                  or incorporate into other works, such Contributions, and grant
                  and authorize sublicenses of the foregoing. The use and
                  distribution may occur in any media formats and through any
                  media channels.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  This license will apply to any form, media, or technology now
                  known or hereafter developed, and includes our use of your
                  name, company name, and franchise name, as applicable, and any
                  of the trademarks, service marks, trade names, logos, and
                  personal and commercial images you provide. You waive all
                  moral rights in your Contributions, and you warrant that moral
                  rights have not otherwise been asserted in your Contributions.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area on the Services. You are solely responsible
                  for your Contributions to the Services and you expressly agree
                  to exonerate us from any and all responsibility and to refrain
                  from any legal action against us regarding your Contributions.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  We have the right, in our sole and absolute discretion, (1) to
                  edit, redact, or otherwise change any Contributions; (2) to
                  re-categorize any Contributions to place them in more
                  appropriate locations on the Services; and (3) to pre-screen
                  or delete any Contributions at any time and for any reason,
                  without notice. We have no obligation to monitor your
                </p>
                <p className="MsoNormal">Contributions.</p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    &nbsp;
                  </span>
                </h1>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span style={{ letterSpacing: "-.1pt" }}>12.</span>
                  <span style={{ letterSpacing: "-.1pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    GUIDELINES FOR REVIEWS
                  </span>
                </h1>
                <p className="MsoNormal">
                  We may provide you areas on the Services to leave reviews or
                  ratings. When posting a review, you must comply with the
                  following criteria: (1) you should have firsthand experience
                  with the person/entity being reviewed; (2) your reviews should
                  not contain offensive profanity, or abusive, racist,
                  offensive, or hateful language; (3) your reviews should not
                  contain discriminatory references based on religion, race,
                  gender, national origin, age, marital status, sexual
                  orientation, or disability; (4) your reviews should not
                  contain references to illegal activity; (5) you should not be
                  affiliated with competitors if posting negative reviews; (6)
                  you should not make any conclusions as to the legality of
                  conduct; (7) you may not post any false or misleading
                  statements; and (8) you may not organize a campaign
                  encouraging others to post reviews, whether positive or
                  negative.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <p className="MsoNormal">
                  We may accept, reject, or remove reviews in our sole
                  discretion. We have absolutely no obligation to screen reviews
                  or to delete reviews, even if anyone considers reviews
                  objectionable or inaccurate. Reviews are not endorsed by us,
                  and do not necessarily represent our opinions or the views of
                  any of our affiliates or partners. We do not assume liability
                  for any review or for any claims, liabilities, or losses
                  resulting from any review. By posting a review, you hereby
                  grant to us a perpetual, non-exclusive, worldwide, royalty-
                  free, fully paid, assignable, and sublicensable right and
                  license to reproduce, modify, translate, transmit by any
                  means, display, perform, and/or distribute all content
                  relating to review.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span style={{ letterSpacing: "-.1pt" }}>13.</span>
                  <span style={{ letterSpacing: "-.1pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    MOBILE APPLICATION LICENSE
                  </span>
                </h1>
                <h2>Use License</h2>
                <p className="MsoNormal">
                  If you access the Services via the App, then we grant you a
                  revocable, non-exclusive, non-transferable, limited right to
                  install and use the App on wireless electronic devices owned
                  or controlled by you, and to access and use the App on such
                  devices strictly in accordance with the terms and conditions
                  of this mobile application license contained in these Legal
                  Terms. You shall not: (1) except as permitted by applicable
                  law, decompile, reverse engineer, disassemble, attempt to
                  derive the source code of, or decrypt the App;
                </p>
                <p className="MsoNormal">
                  (2) make any modification, adaptation, improvement,
                  enhancement, translation, or derivative work from the App; (3)
                  violate any applicable laws, rules, or regulations in
                  connection with your access or use of the App; (4) remove,
                  alter, or obscure any proprietary notice (including any notice
                  of copyright or trademark) posted by us or the
                </p>
                <p className="MsoNormal">
                  licensors of the App; (5) use the App for any
                  revenue-generating endeavor, commercial enterprise, or other
                  purpose for which it is not designed or intended; (6) make the
                  App available over a network or other environment permitting
                  access or use by multiple devices or users at the same time;
                  (7) use the App for creating a product, service, or software
                  that is, directly or indirectly, competitive with or in any
                  way a substitute for the
                </p>
                <p className="MsoNormal">
                  App; (8) use the App to send automated queries to any website
                  or to send any unsolicited commercial email; or (9) use any
                  proprietary information or any of our interfaces or our other
                  intellectual property in the design, development, manufacture,
                  licensing, or distribution of any applications, accessories,
                  or devices for use with the App
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h2>Apple and Android Devices</h2>
                <p className="MsoNormal">
                  The following terms apply when you use the App obtained from
                  either the Apple Store or Google Play (each an "App
                  Distributor") to access the Services: (1) the license granted
                  to you for our App is limited to a non-transferable license to
                  use the application on a device that utilizes the Apple iOS or
                  Android operating systems, as applicable, and in accordance
                  with the usage rules set forth in the applicable App
                  Distributor’s terms of service; (2) we are responsible for
                  providing any maintenance and support services with respect to
                  the App as specified in the terms and conditions of this
                  mobile application license contained in these Legal Terms or
                  as otherwise required under applicable law, and you
                  acknowledge that each App Distributor has no obligation
                  whatsoever to furnish any maintenance and support services
                  with respect to the App; (3) in the event of any failure of
                  the App to conform to any applicable warranty, you may notify
                  the applicable App Distributor, and the App Distributor, in
                  accordance with its terms and policies, may refund the
                  purchase price, if any, paid for the App, and to the maximum
                  extent permitted by applicable law, the App Distributor will
                  have no other warranty obligation whatsoever with respect to
                  the App; (4) you represent and warrant that (i) you are not
                  located in a country that is subject to a US government
                  embargo, or that has been designated by the US government as a
                  "terrorist supporting" country and (ii) you are not listed on
                  any US government list of prohibited or restricted parties;
                  (5) you must comply with applicable third-party terms of
                  agreement when using the App, e.g., if you have a VoIP
                  application, then you must not be in violation of their
                  wireless data service agreement when using the App; and (6)
                  you acknowledge and agree that the App Distributors are
                  third-party beneficiaries of the terms and conditions in this
                  mobile application license contained in these Legal Terms, and
                  that each App Distributor will have the right (and will be
                  deemed to have accepted the right) to enforce the terms and
                  conditions in this mobile application license contained in
                  these Legal Terms against you as a third-party beneficiary
                  thereof.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span style={{ letterSpacing: "-.1pt" }}>14.</span>
                  <span style={{ letterSpacing: "-.1pt" }}> </span>
                  <span style={{ letterSpacing: "-.1pt" }}>SOCIAL MEDIA</span>
                </h1>
                <p className="MsoNormal">
                  As part of the functionality of the Services, you may link
                  your account with online accounts you have with third-party
                  service providers (each such account, a "Third-Party Account")
                  by either: (1) providing your Third-Party Account login
                  information through the Services; or (2) allowing us to access
                  your Third-Party Account, as is permitted under the applicable
                  terms and conditions that govern your use of each Third-Party
                  Account. You represent and warrant that you are entitled to
                  disclose your Third-Party Account login information to us
                  and/or grant us access to your Third-Party Account, without
                  breach by you of any of the terms and conditions that govern
                  your use of the applicable Third-Party Account, and without
                  obligating us to pay any fees or making us subject to any
                  usage limitations imposed by the third-party service provider
                  of the Third-Party Account. By granting us access to any
                  Third-Party Accounts, you understand that (1) we may access,
                  make available, and store (if applicable) any content that you
                  have provided to and stored in your Third-Party Account (the
                  "Social Network Content") so that it is available on and
                  through the Services via your account, including without
                  limitation any friend lists and (2) we may submit to and
                  receive from your Third-Party Account additional information
                  to the extent you are notified when you link your account with
                  the Third-Party Account.{" "}
                </p>
                <p className="MsoNormal">
                  Depending on the Third-Party Accounts you choose and subject
                  to the privacy settings that you have set in such Third-Party
                  Accounts, personally identifiable information that you post to
                  your Third-Party Accounts may be available on and through your
                  account on the Services. Please note that if a Third-Party
                  Account or associated service becomes unavailable or our
                  access to such Third-Party Account is terminated by the
                  third-party service provider, then Social Network Content may
                  no longer be available on and through the Services. You will
                  have the ability to disable the connection between your
                  account on the Services and your Third-Party Accounts at any
                  time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY
                  SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS
                  GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY
                  SERVICE PROVIDERS. We make no effort to review any Social
                  Network Content for any purpose, including but not limited to,
                  for accuracy, legality, or non-infringement, and we are not
                  responsible for any Social Network Content. You acknowledge
                  and agree that we may access your email address book
                  associated with a Third-Party Account and your contacts list
                  stored on your mobile device or tablet computer solely for
                  purposes of identifying and informing you of those contacts
                  who have also registered to use the Services. You can
                  deactivate the connection between the Services and your
                  Third-Party Account by contacting us using the contact
                  information below or through your account settings (if
                  applicable). We will attempt to delete any information stored
                  on our servers that was obtained through such Third-Party
                  Account, except the username and profile picture that become
                  associated with your account.
                </p>
                <p className="MsoNormal">&nbsp;</p>
                <h1 style={{ marginRight: "-2.5pt" }}>
                  <span lang="TR" style={{ letterSpacing: "-.1pt" }}>
                    15.{" "}
                  </span>
                  <span style={{ letterSpacing: "-.1pt" }}>
                    THIRD-PARTY WEBSITES AND CONTENT
                  </span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    The Services may contain (or you may be sent via the Site or
                    App) links to other websites ("Third-Party Websites") as
                    well as articles, photographs, text, graphics, pictures,
                    designs, music, sound, video, information, applications,
                    software, and other content or items belonging to or
                    originating from third parties ("Third-Party Content"). Such
                    Third- Party Websites and Third-Party Content are not
                    investigated, monitored, or checked for accuracy,
                    appropriateness, or completeness by us, and we are not
                    responsible for any Third-Party Websites accessed through
                    the Services or any Third-Party Content posted on, available
                    through, or installed from the Services, including the
                    content, accuracy, offensiveness, opinions, reliability,
                    privacy practices, or other policies of or contained in the
                    Third-Party Websites or the Third-Party Content. Inclusion
                    of, linking to, or permitting the use or installation of any
                    Third-Party Websites or any Third-Party Content does not
                    imply approval or endorsement thereof by us. If you decide
                    to leave the Services and access the Third-Party Websites or
                    to use or install any Third-Party Content, you do so at your
                    own risk, and you should be aware these Legal Terms no
                    longer govern. You should review the applicable terms and
                    policies, including privacy and data gathering practices, of
                    any website to which you navigate from the Services or
                    relating to any applications you use or install from the
                    Services. Any purchases you make through Third-Party
                    Websites will be through other websites and from other
                    companies, and we take no responsibility whatsoever in
                    relation to such purchases which are exclusively between you
                    and the applicable third party. You agree and acknowledge
                    that we do not endorse the products or services offered on
                    Third-Party Websites and you shall hold us blameless from
                    any harm caused by your purchase of such products or
                    services. Additionally, you shall hold us blameless from any
                    losses sustained by you or harm caused to you relating to or
                    resulting in any way from any Third-Party Content or any
                    contact with Third-Party Websites.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">16. ADVERTISERS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    We allow advertisers to display their advertisements and
                    other information in certain areas of the Services, such as
                    sidebar advertisements or banner advertisements. We simply
                    provide the space to place such advertisements, and we have
                    no other relationship with advertisers.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">17. SERVICES MANAGEMENT</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    We reserve the right, but not the obligation, to: (1)
                    monitor the Services for violations of these Legal Terms;
                    (2) take appropriate legal action against anyone who, in our
                    sole discretion, violates the law or these Legal Terms,
                    including without limitation, reporting such user to law
                    enforcement authorities; (3) in our sole discretion and
                    without limitation, refuse, restrict access to, limit the
                    availability of, or disable (to the extent technologically
                    feasible) any of your Contributions or any portion thereof;
                    (4) in our sole discretion and without limitation, notice,
                    or liability, to remove from the Services or otherwise
                    disable all files and content that are excessive in size or
                    are in any way burdensome to our systems; and (5) otherwise
                    manage the Services in a manner designed to protect our
                    rights and property and to facilitate the proper functioning
                    of the Services.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">18. PRIVACY POLICY</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    We care about data privacy and security. Please review our
                    Privacy Policy:{" "}
                    <a href="https://atlas.space/privacy-policy">
                      https://atlas.space/privacy-policy
                    </a>
                    . By using the Services, you agree to be bound by our
                    Privacy Policy, which is incorporated into these Legal
                    Terms. Please be advised the Services are hosted in the
                    United States. If you access the Services from any other
                    region of the world with laws or other requirements
                    governing personal data collection, use, or disclosure that
                    differ from applicable laws in the United States, then
                    through your continued use of the Services, you are
                    transferring your data to the United States, and you
                    expressly consent to have your data transferred to and
                    processed in the United States. Further, we do not knowingly
                    accept, request, or solicit information from children or
                    knowingly market to children. Therefore, in accordance with
                    the U.S.{" "}
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    Children’s Online Privacy Protection Act, if we receive
                    actual knowledge that anyone under the age of 13 has
                    provided personal information to us without the requisite
                    and verifiable parental consent, we will delete that
                    information from the Services as quickly as is reasonably
                    practical.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">19. COPYRIGHT INFRINGEMENTS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    We respect the intellectual property rights of others. If
                    you believe that any material available on or through the
                    Services infringes upon any copyright you own or control,
                    please immediately notify us using the contact information
                    provided below (a "Notification"). A copy of your
                    Notification will be sent to the person who posted or stored
                    the material addressed in the Notification. Please be
                    advised that pursuant to applicable law you may be held
                    liable for damages if you make material misrepresentations
                    in a Notification. Thus, if you are not sure that material
                    located on or linked to by the Services infringes your
                    copyright, you should consider first contacting an attorney.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">20. TERM AND TERMINATION</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    These Legal Terms shall remain in full force and effect
                    while you use the Services. WITHOUT LIMITING ANY OTHER
                    PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN
                    OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY
                    ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING
                    CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR
                    NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                    REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
                    LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                    TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR
                    DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU
                    POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    If we terminate or suspend your account for any reason, you
                    are prohibited from registering and creating a new account
                    under your name, a fake or borrowed name, or the name of any
                    third party, even if you may be acting on behalf of the
                    third party. In addition to terminating or suspending your
                    account, we reserve the right to take appropriate legal
                    action, including without limitation pursuing civil,
                    criminal, and injunctive redress.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">21. MODIFICATIONS AND INTERRUPTIONS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    We reserve the right to change, modify, or remove the
                    contents of the Services at any time or for any reason at
                    our sole discretion without notice. However, we have no
                    obligation to update any information on our Services. We
                    also reserve the right to modify or discontinue all or part
                    of the Services without notice at any time. We will not be
                    liable to you or any third party for any modification, price
                    change, suspension, or discontinuance of the Services.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    We cannot guarantee the Services will be available at all
                    times. We may experience hardware, software, or other
                    problems or need to perform maintenance related to the
                    Services, resulting in interruptions, delays, or errors. We
                    reserve the right to change, revise, update, suspend,
                    discontinue, or otherwise modify the Services at any time or
                    for any reason without notice to you. You agree that we have
                    no liability whatsoever for any loss, damage, or
                    inconvenience caused by your inability to access or use the
                    Services during any downtime or discontinuance of the
                    Services. Nothing in these Legal Terms will be construed to
                    obligate us to maintain and support the Services or to
                    supply any corrections, updates, or releases in connection
                    therewith.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">22. GOVERNING LAW</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    These Legal Terms and your use of the Services are governed
                    by and construed in accordance with the laws of the State of
                    Delaware applicable to agreements made and to be entirely
                    performed within the State of Delaware, without regard to
                    its conflict of law principles.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">23. DISPUTE RESOLUTION</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h2>
                  <span lang="TR">Binding Arbitration</span>
                </h2>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    If the Parties are unable to resolve a Dispute through
                    informal negotiations, the Dispute (except those Disputes
                    expressly excluded below) will be finally and exclusively
                    resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT
                    THIS PROVISION, YOU&nbsp; WOULD HAVE THE RIGHT TO SUE IN
                    COURT AND HAVE A JURY TRIAL. The arbitration shall be
                    commenced and conducted under the Commercial Arbitration
                    Rules of the American Arbitration Association ("AAA") and,
                    where appropriate, the AAA’s Supplementary Procedures for
                    Consumer Related Disputes ("AAA Consumer Rules"), both of
                    which are available at the{" "}
                    <a href="http://www.adr.org/">
                      American Arbitration Association (AAA) website
                    </a>
                    . Your arbitration fees and your share of arbitrator
                    compensation shall be governed by the AAA Consumer Rules
                    and, where appropriate, limited by the AAA Consumer Rules.
                    The arbitration may be conducted in person, through the
                    submission of documents, by phone, or online. The arbitrator
                    will make a decision in writing, but need not provide a
                    statement of reasons unless requested by either Party. The
                    arbitrator must follow applicable law, and any award may be
                    challenged if the arbitrator fails to do so. Except where
                    otherwise required by the applicable AAA rules or applicable
                    law, the arbitration will take place in USA, Delaware.
                    Except as otherwise provided herein, the Parties may
                    litigate in court to compel arbitration, stay proceedings
                    pending arbitration, or to confirm, modify, vacate, or enter
                    judgment on the award entered by the arbitrator.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    If for any reason, a Dispute proceeds in court rather than
                    arbitration, the Dispute shall be commenced or prosecuted in
                    the state and federal courts located in USA, Delaware, and
                    the Parties hereby consent to, and waive all defenses of
                    lack of personal jurisdiction, and forum non conveniens with
                    respect to venue and jurisdiction in such state and federal
                    courts. Application of the United Nations Convention on
                    Contracts for the International
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    Sale of Goods and the Uniform Computer Information
                    Transaction Act (UCITA) are excluded from these Legal Terms.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    In no event shall any Dispute brought by either Party
                    related in any way to the Services be commenced more than
                    one (1) years after the cause of action arose. If this
                    provision is found to be illegal or unenforceable, then
                    neither Party will elect to arbitrate any
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    Dispute falling within that portion of this provision found
                    to be illegal or unenforceable and such Dispute shall be
                    decided by a court of competent jurisdiction within the
                    courts listed for jurisdiction above, and the Parties agree
                    to submit to the personal jurisdiction of that court.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h2>
                  <span lang="TR">Restrictions</span>
                </h2>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    The Parties agree that any arbitration shall be limited to
                    the Dispute between the Parties individually. To the full
                    extent permitted by law, (a) no arbitration shall be joined
                    with any other proceeding; (b) there is no right or
                    authority for any Dispute to be arbitrated on a class-action
                    basis or to utilize class action procedures; and (c) there
                    is no right or
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    authority for any Dispute to be brought in a purported
                    representative capacity on behalf of the general public or
                    any other persons.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h2>
                  <span lang="TR">Exceptions to Arbitration</span>
                </h2>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    The Parties agree that the following Disputes are not
                    subject to the above provisions concerning binding
                    arbitration: (a) any Disputes seeking to enforce or protect,
                    or concerning the validity of, any of the intellectual
                    property rights of a Party; (b) any Dispute related to, or
                    arising from, allegations of theft, piracy, invasion of
                    privacy, or unauthorized use; and (c) any claim for
                    injunctive relief. If this provision is found to be illegal
                    or unenforceable, then neither Party will elect to arbitrate
                    any Dispute falling within that portion of this provision
                    found to be illegal or unenforceable and such Dispute shall
                    be decided by a court of competent jurisdiction within the
                    courts listed for jurisdiction above, and the Parties agree
                    to submit to the personal jurisdiction of that court.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">24. CORRECTIONS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    There may be information on the Services that contains
                    typographical errors,
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    inaccuracies, or omissions, including descriptions, pricing,
                    availability, and various other information. We reserve the
                    right to correct any errors, inaccuracies, or omissions and
                    to change or update the information on the Services at any
                    time, without prior notice.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">25. DISCLAIMER</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE
                    BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT
                    YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE
                    DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                    WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT
                    LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                    FITNESS FOR A PARTICULAR PURPOSE, AND NON- INFRINGEMENT. WE
                    MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
                    COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY
                    WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND
                    WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
                    ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
                    (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                    SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
                    SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
                    AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                    INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
                    SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
                    WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                    THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
                    CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                    INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                    TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES.
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                    RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR
                    OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY
                    HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                    FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT
                    BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                    ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                    PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                    SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
                    USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE
                    APPROPRIATE.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">26. LIMITATIONS OF LIABILITY</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                    BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                    PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                    OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE
                    SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
                    SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY
                    CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                    ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF
                    ANY, BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO
                    ANY CAUSE OF ACTION ARISING OR $200.00 USD. CERTAIN US STATE
                    LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON
                    IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN
                    DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                    ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                    YOU MAY HAVE ADDITIONAL RIGHTS.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">27. INDEMNIFICATION</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    You agree to defend, indemnify, and hold us harmless,
                    including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from
                    and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys’ fees and expenses, made by
                    any third party due to or arising out of: (1) your
                    Contributions; (2) use of the Services; (3) breach of these
                    Legal Terms; (4) any breach of your representations and
                    warranties set forth in these Legal Terms; (5) your
                    violation of the rights of a third party, including but not
                    limited to intellectual property rights; or (6) any overt
                    harmful act toward any other user of the Services with whom
                    you connected via the Services. Notwithstanding the
                    foregoing, we reserve the right, at your expense, to assume
                    the exclusive defense and control of any matter for which
                    you are required to indemnify us, and you agree to
                    cooperate, at your expense, with our defense of such claims.
                    We will use reasonable efforts to notify you of any such
                    claim, action, or proceeding which is subject to this
                    indemnification upon becoming aware of it.{" "}
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">28. USER DATA</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">
                    We will maintain certain data that you transmit to the
                    Services for the purpose of managing the performance of the
                    Services, as well as data relating to your use of the
                    Services. Although we perform regular routine backups of
                    data, you are solely responsible for all data that you
                    transmit or that relates to any activity you have undertaken
                    using the Services. You agree that we shall have no
                    liability to you for any loss or corruption of any such
                    data, and you hereby waive any right of action against us
                    arising from any such loss or corruption of such data.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">
                    29. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                  </span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    Visiting the Services, sending us emails, and completing
                    online forms constitute electronic communications. You
                    consent to receive electronic communications, and you agree
                    that all agreements, notices, disclosures, and other
                    communications we provide to you electronically, via email
                    and on the Services, satisfy any legal requirement that such
                    communication be in writing. YOU HEREBY AGREE TO THE USE OF
                    ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS,
                    AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
                    OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
                    SERVICES. You hereby waive any rights or requirements under
                    any statutes, regulations, rules, ordinances, or other laws
                    in any jurisdiction which require an original signature or
                    delivery or retention of non-electronic records, or to
                    payments or the granting of credits by any means other than
                    electronic means.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">30. CALIFORNIA USERS AND RESIDENTS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    If any complaint with us is not satisfactorily resolved, you
                    can contact the Complaint Assistance Unit of the Division of
                    Consumer Services of the California Department of Consumer
                    Affairs in writing at 1625 North Market Blvd., Suite N 112,
                    Sacramento, California 95834 or by telephone at (800)
                    952-5210 or (916) 445-1254.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">31. MISCELLANEOUS</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    These Legal Terms and any policies or operating rules posted
                    by us on the Services or in respect to the Services
                    constitute the entire agreement and understanding between
                    you and us. Our failure to exercise or enforce any right or
                    provision of these Legal Terms shall not operate as a waiver
                    of such right or provision. These Legal Terms operate to the
                    fullest extent permissible by law. We may assign any or all
                    of our rights and obligations to others at any time. We
                    shall not be responsible or liable for any loss, damage,
                    delay, or failure to act caused by any cause beyond our
                    reasonable control. If any provision or part of a provision
                    of these Legal Terms is determined to be unlawful, void, or
                    unenforceable, that provision or part of the provision is
                    deemed severable from these Legal Terms and does not affect
                    the validity and enforceability of any remaining provisions.
                    There is no joint venture, partnership, employment or agency
                    relationship created between you and us as a result of these
                    Legal Terms or use of the Services. You agree that these
                    Legal Terms will not be construed against us by virtue of
                    having drafted them. You hereby waive any and all defenses
                    you may have based on the electronic form of these Legal
                    Terms and the lack of signing by the parties hereto to
                    execute these Legal Terms.
                  </span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <h1>
                  <span lang="TR">32. CONTACT US</span>
                </h1>
                <p className="MsoNormal">
                  <span lang="TR">&nbsp;</span>
                </p>
                <p className="MsoNormal">
                  <span lang="TR">
                    In order to resolve a complaint regarding the Services or to
                    receive further information regarding use of the Services,
                    please contact us at:
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    &nbsp;
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    IMMERSIVE DREAM TECH, INC.
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    651 N Broad St, SUITE 206,{" "}
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    USA Middletown, DE 19709
                  </span>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    textAlign: "justify",
                    textJustify: "auto",
                  }}
                >
                  <span lang="TR" style={{ lineHeight: "150%" }}>
                    connect@atlas.space
                  </span>
                </p>
              </div>
            </div>
            <div className="accept">
              <input type="checkbox" name="checkbox" onChange={checkControll} />
              <span className="" style={{ paddingLeft: "20px" }}>
                I Agree with the terms &amp; conditions and the privacy policy.
              </span>
            </div>
          </p>

          <p
            className={
              isChecked === true ? " terms-button" : " terms-button disabled"
            }
            onClick={() => setShowRegister(true)}
          >
            Accept and Start Exploring
          </p>
        </div>
      </PrivacyBackground>
    </Container>
  );
};

export default Index;

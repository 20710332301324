import Back from "components/BackButton/Back";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import React from "react";
import MetamaskReminderView from "views/MetamaskReminderView/MetamaskReminderView";

const index = () => {
  return (
    <Background>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <Container>
          <Back link="/" />
          <MetamaskReminderView />
        </Container>
      </div>
    </Background>
  );
};

export default index;

import Web3Modal from "web3modal";
import { Web3Provider } from "@ethersproject/providers";
import { NavigateFunction } from "react-router-dom";
import { getMyUserInfo } from "api/getMyUserInfo/GetMyUserInfo";
import { unityAddAvatar } from "utils/unityIntegration/unityAddAvatar";
import { isMetamaskPublicAddress } from "api/isMetamaskAddress/isMetamaskAddress";
import { loginWithMetamask } from "api/loginWithMetamask/LoginWithMetamask";

import ReactGA from "react-ga4";

type SubComponent = "home" | "login" | "register" | "metamaskregister";

// interface IConnectWithMetamask {
//   changeSubComponent: (subComponent: SubComponent) => void;
// }

export const connectWithMetamask = async (navigate: NavigateFunction, setSocialLoading: any) => {
  ReactGA.event({
    category: "Login",
    action: "metamaskLogin",
    label: "metamaskLogin",
  });
  var isOpera = navigator.userAgent.indexOf(" OPR/") >= 0;

  if (isOpera) {
    try {
      let accounts = await window.ethereum.request({
        /* New */ method: "eth_requestAccounts" /* New */,
      });
      const requestFromAddress = {
        publicAddress: accounts[0],
      };
      console.log(requestFromAddress);

      sessionStorage.setItem("publicAddress", accounts[0]);
      const response = await isMetamaskPublicAddress(accounts[0].toLowerCase());
      if (response.data.metamaskUserIsExists === true) {
        const response = await loginWithMetamask(accounts[0].toLowerCase());
        if (response.status === "Success") {
          localStorage.setItem("tokenAtlas", JSON.stringify(response));
          if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
            navigate("/create-avatar");
          } else {
            navigate("/meta");
          }
        }
      } else {
        console.log("else");
        navigate("/metamaskregister", {
          state: accounts[0],
        });
      }
    } catch (error) {
      console.log(error);
      return;
    }
  }

  try {
    setSocialLoading(true);
    const web3modal = new Web3Modal({ cacheProvider: true });
    const instance = await web3modal.connect();
    setSocialLoading(false);
    const provider = new Web3Provider(instance);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const response = await isMetamaskPublicAddress(address.toLowerCase());
    if (response.data.metamaskUserIsExists === true) {
      const response = await loginWithMetamask(address.toLowerCase());
      if (response.status === "Success") {
        localStorage.setItem("tokenAtlas", JSON.stringify(response));
        if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
          navigate("/create-avatar");
        } else {
          navigate("/meta");
        }
      }
    } else {
      console.log("else");
      navigate("/metamaskregister", {
        state: address,
      });
    }
  } catch (error) {
    console.log(error);
    setSocialLoading(false);
    return;
  }
};

export const connectWithMetamaskIngame = async (navigate: NavigateFunction, changeSubComponent: any, setAuthModal?: any) => {
  try {
    var isOpera = navigator.userAgent.indexOf(" OPR/") >= 0;

    if (isOpera) {
      let accounts = await window.ethereum.request({
        /* New */ method: "eth_requestAccounts" /* New */,
      });
      const requestFromAddress = {
        publicAddress: accounts[0],
      };

      console.log(requestFromAddress);

      sessionStorage.setItem("publicAddress", accounts[0]);
      const response = await isMetamaskPublicAddress(accounts[0].toLowerCase());
      if (response.data.metamaskUserIsExists === true) {
        const response = await loginWithMetamask(accounts[0].toLowerCase());
        if (response.status === "Success") {
          localStorage.setItem("tokenAtlas", JSON.stringify(response));
          if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
            window.location.assign("/create-avatar");
          } else {
            // *** Mouse kontrolü için sayfa reload ettiriliyor *** //
            window.localStorage.setItem("tokenAtlas", JSON.stringify(response));
            window.location.assign("/meta");
            // *** Mouse kontrolü için kaldırıldı ***//
            // const userInfo = await getMyUserInfo();
            // console.log(userInfo);

            // unityAddAvatar({
            //   token: response.data.token.accessToken,
            //   loginAsAGuest: false,
            //   userInfo: userInfo,
            // });

            // (window as any).addAvatar();
            // setAuthModal!;
          }
        }
      } else {
        if (accounts[0]) {
          changeSubComponent("metamaskregister");
        }
      }
    }
  } catch (error) {
    return;
  }

  try {
    const web3modal = new Web3Modal({ cacheProvider: true });
    const instance = await web3modal.connect();
    const provider = new Web3Provider(instance);
    const signer = provider.getSigner();
    const address = await signer.getAddress();

    const response = await isMetamaskPublicAddress(address.toLowerCase());
    console.log("response", response);

    if (response.data.metamaskUserIsExists === true) {
      const response = await loginWithMetamask(address.toLowerCase());
      if (response.status === "Success") {
        localStorage.setItem("tokenAtlas", JSON.stringify(response));
        if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
          window.location.assign("/create-avatar");
        } else {
          // *** Mouse kontrolü için sayfa reload ettiriliyor *** //
          window.localStorage.setItem("tokenAtlas", JSON.stringify(response));
          window.location.assign("/meta");

          // *** Mouse kontrolü için kaldırıldı ***//
          // const userInfo = await getMyUserInfo();
          // console.log(userInfo);

          // unityAddAvatar({
          //   token: response.data.token.accessToken,
          //   loginAsAGuest: false,
          //   userInfo: userInfo,
          // });

          // (window as any).addAvatar();
          // setAuthModal!(false);
        }
      }
    } else {
      console.log("else");
      changeSubComponent("metamaskregister");
    }
  } catch (error) {
    return;
  }
};

import Inputs from "components/Inputs/Inputs";
import Metamask from "assets/Svg/Metamask";
import AuthCards from "components/AuthCards/AuthCards";
import "./MetamaskRegisterCode.css";
import { ChangeEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Localstorage } from "utils/localstorage/Localstorage";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";
const MetamaskRegisterCode = () => {
  const { state } = useLocation();
  const localstorage = new Localstorage();

  const navigate = useNavigate();

  const [registerCode, setRegisterCode] = useState<{
    confirmationCode: string;
  }>({
    confirmationCode: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterCode((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const metamaskRegisterCode = async () => {
    console.log(state.data.registerFormId);

    const request = await fetch(
      `${apiUrl}/Metamask/ConfirmMetaMaskUserRegisterCode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          registerFormId: state.data.registerFormId,
          confirmationCode: registerCode.confirmationCode,
        }),
      }
    );
    const response = await request.json();
    if (response.status === "Success") {
      await localstorage.setLocalstorage(response);

      if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
        navigate("/create-avatar");
      } else {
        navigate("/meta");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="metamaskregister-email"
    >
      <div className="metamaskregister-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p>Please enter your register Code That Has Been Sent To Your Email</p>
      </div>
      <div className="metamaskregister-flex">
        <div className="metamaskregister-left">
          <AuthCards title="Log in with Metamask" url="/">
            <Metamask />
          </AuthCards>
        </div>
        <div className="metamaskregister-account error">
          <h6>Register Code</h6>
          <Inputs
            name="confirmationCode"
            title="Code"
            onChange={handleChange}
          />
          <small className="wrong-code">Confirm Code Not Found</small>
        </div>
      </div>
      <div className="metamaskregister-accept">
        <button onClick={metamaskRegisterCode}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default MetamaskRegisterCode;

import { motion } from "framer-motion";
import "./AuthInGameModal.css";
import Inputs from "components/Inputs/Inputs";

import Mail from "assets/Svg/Mail";
import Metamask from "assets/Svg/Metamask";
import Social from "assets/Svg/Social";
import Close from "assets/Svg/Close";
import User from "assets/Svg/Register/User";
import PasswordKey from "assets/Svg/Register/Key";
import { ChangeEvent, useEffect, useState } from "react";
import { LoginRequest } from "services/AuthService/types/LoginTypes";
import { IUserInfo } from "views/UnityContext/IUserInfo";
import { apiUrl } from "api/apiUrl";
import { unityAddAvatar } from "utils/unityIntegration/unityAddAvatar";
import { LoginError } from "utils/authCustomError/LoginError/LoginError";
import { connectWithMetamaskIngame } from "utils/metamaskLogin/MetamaskLogin";
import { useNavigate } from "react-router-dom";
import { customError } from "utils/authCustomError/RegisterError/RegisterError";
import { Localstorage } from "utils/localstorage/Localstorage";
import { getMyUserInfo } from "api/getMyUserInfo/GetMyUserInfo";
import { MetamaskRegisterRequest } from "services/AuthService/types/MetamaskTypes";
import { MetamaskErrorCustom } from "utils/authCustomError/Metamask/MetamaskError";
import Torus from "@toruslabs/torus-embed";
import { TorusRegisterError } from "utils/authCustomError/Torus/TorusError";
import { TorusRegisterCodeRequest } from "services/AuthService/types/TorusTypes/TorusRegisterCodeTypes";
import { unityContext } from "components/UnityComponent/UnityComponent";
import { isMetamaskPublicAddress } from "api/isMetamaskAddress/isMetamaskAddress";
import ReactGA from "react-ga4";

import { loginWithMetamask } from "api/loginWithMetamask/LoginWithMetamask";

type SubComponent = "home" | "login" | "register" | "metamaskregister" | "metamaskregistercode" | "torusregister" | "torusregistercode";

const AuthInGameHomeModal = ({
  changeSubComponent,
  setAuthModal,
}: {
  changeSubComponent: (subComponent: SubComponent) => void;
  setAuthModal: (value: boolean) => void;
}) => {
  const navigate = useNavigate();
  const torus = new Torus();

  //How to use Torus Login function in React

  const loginMetamask = async () => {

      ReactGA.event({
        category: "Login",
        action: "metamaskLogin",
        label: "metamaskLogin",
      });
    if (typeof window.ethereum === "undefined") {
      window.open("https://metamask.io/download.html", "_blank");
    } else {
      try {
        let accounts = await window.ethereum.request({
          /* New */ method: "eth_requestAccounts" /* New */,
        });

        const account = accounts[0];

        const response = await isMetamaskPublicAddress(account.toLowerCase());
        if (response.data.metamaskUserIsExists === true) {
          const response = await loginWithMetamask(account.toLowerCase());
          if (response.status === "Success") {
            localStorage.setItem("tokenAtlas", JSON.stringify(response));
            if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
              window.location.assign("/create-avatar");
            } else {
              // *** Mouse kontrolü için sayfa reload ettiriliyor *** //
              window.localStorage.setItem("tokenAtlas", JSON.stringify(response));
              window.location.assign("/meta");
              // *** Mouse kontrolü için kaldırıldı ***//
              // const userInfo = await getMyUserInfo();
              // console.log(userInfo);

              // unityAddAvatar({
              //   token: response.data.token.accessToken,
              //   loginAsAGuest: false,
              //   userInfo: userInfo,
              // });

              // (window as any).addAvatar();
              // setAuthModal!;
            }
          }
        } else {
          changeSubComponent("metamaskregister");
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const socialLogin = async () => {
    try {
      await torus.init();
      await torus.login();
      const userInfo: any = await torus.getUserInfo("userInformation");
      const publicAddress = torus.provider.selectedAddress;
      torus.buttonPosition = "top-left";
      torus.showTorusButton();
      const response = await checkTorusPublicAddress(publicAddress!);

      const info = {
        publicAddress: publicAddress,
        email: userInfo.email,
        socialMediaVerifier: userInfo.typeOfLogin,
      };

      if (response.data.torusUserIsExists === true) {
        const responseTorusLogin = await userSocialLogin(publicAddress!);
        console.log(responseTorusLogin);

        if (responseTorusLogin.status === "Success") {
          new Localstorage().setLocalstorage(responseTorusLogin);
          if (responseTorusLogin.data.avatarUrl === "" || responseTorusLogin.data.avatarUrl === null) {
            navigate("/create-avatar");
          } else {
            const userInfo = await getMyUserInfo();

            unityAddAvatar({
              token: responseTorusLogin?.data?.token?.accessToken,
              loginAsAGuest: false,
              userInfo: userInfo,
            });
            (window as any).addAvatar();
          }
        }
      } else {
        sessionStorage.setItem("torus", JSON.stringify(info));
        changeSubComponent("torusregister");
      }
    } catch (error) {
      return;
    }
  };

  const checkTorusPublicAddress = async (address: string) => {
    const response = await fetch(`${apiUrl}/Torus/CheckTorusUserExists?publicAddress=${address}`);
    const data = await response.json();
    return data;
  };

  const userSocialLogin = async (publicAddress: string) => {
    const request = await fetch(`${apiUrl}/Torus/LoginTorusUser`, {
      method: "POST",
      headers: {
        "CONTENT-TYPE": "application/json",
      },
      body: JSON.stringify({
        publicAddress: publicAddress,
      }),
    });
    const response = await request.json();
    return response;
  };

  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Join To The Atlas Space For Free</p>
      <div className="auth-ingame-cards">
        <div className="auth-ingame-card" onClick={() => changeSubComponent("login")}>
          <div className="auth-ingame-card-image">
            <Mail />
          </div>
          <h6>Login With Email</h6>
        </div>
        <div onClick={loginMetamask} className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Metamask />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div onClick={socialLogin} className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Social />
          </div>
          <h6>Login With Socials</h6>
        </div>
      </div>
      <div className="auth-ingame-account">
        <h6>Don't have a account</h6>
        <button onClick={() => changeSubComponent("register")}>Sign up</button>
      </div>
    </>
  );
};

const AuthInGameLoginModal = ({ setAuthModal }: { setAuthModal: (state: boolean) => void }) => {
  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState<LoginRequest>({
    usernameOrEmail: "",
    password: "",
  });

  const onChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    setLoginUser((prev: LoginRequest) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const loginUserWithEmail = async () => {
    const request = await fetch(`${apiUrl}/Auth/LoginUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginUser),
    });
    const response = await request.json();
    if (response.status === "Success") {
      // *** Mouse kontrolü için sayfa reload ettiriliyor *** //
      window.localStorage.setItem("tokenAtlas", JSON.stringify(response));
      window.location.assign("/meta");

      // *** Mouse kontrolü için kaldırıldı ***//
      // const userInfo = await getMyUserInfo(response.data.token.accessToken);
      // unityAddAvatar({
      //   token: response.data.token.accessToken,
      //   loginAsAGuest: false,
      //   userInfo: userInfo,
      // });

      // (window as any).addAvatar();
      // setAuthModal(false);
    } else if (response.status === "Error") {
      console.log(response.errors);

      LoginError(response);
    }
  };

  const getMyUserInfo = async (token: string): Promise<IUserInfo> => {
    const request = await fetch(`${apiUrl}/User/GetMyUserInfo`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const response: IUserInfo = await request.json();

    return response;
  };

  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Join To The Atlas Space For Free</p>
      <div className="ingame-login">
        <div className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Mail />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div className="ingame-login-label">
          <h6>Account</h6>
          <div className="login-account">
            <Inputs
              title="Username"
              name="usernameOrEmail"
              className="title-padding"
              style={{ paddingLeft: "45px", color: "#fff!important" }}
              onChange={onChangeLogin}
            >
              <User />
            </Inputs>

            <Inputs
              title="Password"
              name="password"
              className="title-padding"
              style={{ paddingLeft: "45px", color: "#fff!important" }}
              type="password"
              onChange={onChangeLogin}
            >
              <PasswordKey />
            </Inputs>

            <button onClick={loginUserWithEmail}>Accept and Start Exploring</button>
          </div>
        </div>
      </div>
    </>
  );
};

const AuthInGameRegisterModal = ({ changeSubComponent }: { changeSubComponent: (subComponent: SubComponent) => void }) => {
  type RegisterType = {
    firstName: string;
    surName: string;
    userName: string;
    email: string;
    password: string;
    companyName: string;
    confirmPassword: string;
  };

  const [registerData, setRegisterData] = useState<RegisterType>({
    firstName: "",
    surName: "",
    userName: "",
    password: "",
    companyName: "",
    email: "",
    confirmPassword: "",
  });

  const registerInputHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev: RegisterType) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const signup = async () => {
    let requestBody: Omit<RegisterType, "confirmPassword"> = {
      firstName: registerData.firstName,
      surName: registerData.surName,
      email: registerData.email,
      companyName: registerData.companyName,
      password: registerData.password,
      userName: registerData.userName,
    };

    console.log(requestBody);

    const request = await fetch(`${apiUrl}/Auth/CreateUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const response = await request.json();

    if (response && response.status === "Success") {
      changeSubComponent("login");
    } else {
      customError(response);
    }
  };

  return (
    <>
      <h1>Create an Account</h1>
      <p>Please enter your information to sign up</p>
      <div className="ingame-register-modal">
        <div className="ingame-register-modal-label">
          <h6>Information</h6>
          <div style={{ display: "flex", gap: "15px" }}>
            <Inputs title="Name" name="firstName" style={{ color: "#fff!important" }} onChange={registerInputHandleChange}></Inputs>
            <Inputs title="Surname" name="surName" style={{ color: "#fff!important" }} onChange={registerInputHandleChange}></Inputs>
          </div>

          <Inputs title="Email" name="email" style={{ color: "#fff!important" }} onChange={registerInputHandleChange}></Inputs>
          <Inputs title="Company" name="companyName" style={{ color: "#fff!important" }} onChange={registerInputHandleChange}></Inputs>
        </div>
        <div className="ingame-register-modal-label">
          <h6>Account</h6>
          <Inputs
            title="Username"
            name="userName"
            className="title-padding"
            style={{ paddingLeft: "45px", color: "#fff!important" }}
            onChange={registerInputHandleChange}
          >
            <User />
          </Inputs>
          <Inputs
            title="Password"
            name="password"
            className="title-padding"
            style={{ paddingLeft: "45px", color: "#fff!important" }}
            type="password"
            onChange={registerInputHandleChange}
          >
            <PasswordKey />
          </Inputs>
          <Inputs
            title="Confirm Password"
            name="confirmPassword"
            className="title-padding"
            style={{ paddingLeft: "45px", color: "#fff!important" }}
            type="password"
            onChange={registerInputHandleChange}
          >
            <PasswordKey />
          </Inputs>
        </div>
      </div>
      <div className="ingame-register-button">
        <button onClick={signup}>Continue</button>
      </div>

      <div className="auth-ingame-account">
        <h6>Don't have a account</h6>
        <button onClick={() => changeSubComponent("login")}>Sign up</button>
      </div>
    </>
  );
};

const MetamaskRegisterIngame = ({ changeSubComponent }: { changeSubComponent: (subComponent: SubComponent) => void }) => {
  const navigate = useNavigate();
  const [address, setAddress] = useState<string>("");

  useEffect(() => {
    const getAddress = async () => {
      let accounts = await window.ethereum.request({
        /* New */ method: "eth_requestAccounts" /* New */,
      });

      setAddress(accounts[0]);
    };
    getAddress();
  }, [address]);

  const [data, setData] = useState<MetamaskRegisterRequest>({
    publicAddress: address?.toLowerCase(),
    email: "",
    userName: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const metamaskRegister = async () => {
    console.log(address?.toLowerCase());

    if (data.email === "") {
      const request = await fetch(`${apiUrl}/Metamask/CreateMetamaskUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          publicAddress: address?.toLowerCase(),
        }),
      });
      const response = await request.json();

      if (response.status === "Success") {
        localStorage.setItem("tokenAtlas", JSON.stringify(response));
        navigate("/create-avatar");
      } else {
        MetamaskErrorCustom(response);
      }
    }

    if (data.email !== "") {
      const request = await fetch(`${apiUrl}/Metamask/CreateMetaMaskUserRegisterForm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
          publicAddress: address?.toLowerCase(),
        }),
      });
      const response = await request.json();
      if (response.status === "Success") {
        sessionStorage.setItem("metaRegister", JSON.stringify(response));
        changeSubComponent("metamaskregistercode");
      } else {
        MetamaskErrorCustom(response);
      }
    }
  };

  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Set your Username and Password</p>
      <div className="ingame-login">
        <div className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Metamask />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div className="ingame-login-label">
          <h6>Account</h6>
          <div className="login-account metamask-account">
            <Inputs
              title="Username"
              name="userName"
              className="title-padding"
              style={{ paddingLeft: "45px", color: "#fff!important" }}
              onChange={handleChange}
            >
              <User />
            </Inputs>

            <Inputs
              title="Email (Optional)"
              name="email"
              className="title-padding"
              style={{ paddingLeft: "45px", color: "#fff!important" }}
              onChange={handleChange}
            >
              <Mail width={18} height={18} />
            </Inputs>

            <button onClick={metamaskRegister}>Start Exploring</button>
          </div>
        </div>
      </div>
    </>
  );
};

const MetamaskRegisterCodeIngame = () => {
  const navigate = useNavigate();
  const [registerCode, setRegisterCode] = useState<{
    confirmationCode: string;
  }>({
    confirmationCode: "",
  });
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterCode((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const localstorage = new Localstorage();

  const metamaskRegisterCode = async () => {
    const formID = JSON.parse(sessionStorage.getItem("metaRegister")!);

    const request = await fetch(`${apiUrl}/Metamask/ConfirmMetaMaskUserRegisterCode`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        registerFormId: formID.data.registerFormId,
        confirmationCode: registerCode.confirmationCode,
      }),
    });

    const response = await request.json();
    if (response.status === "Success") {
      await localstorage.setLocalstorage(response);

      if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
        navigate("/create-avatar");
      } else {
        const userInfo = await getMyUserInfo(response.data.token.accessToken);

        unityAddAvatar({
          token: response.data.token.accessToken,
          loginAsAGuest: false,
          userInfo: userInfo,
        });

        (window as any).addAvatar();

        sessionStorage.removeItem("metaRegister");
      }
    }
  };

  const getMyUserInfo = async (token: string): Promise<IUserInfo> => {
    const request = await fetch(`${apiUrl}/User/GetMyUserInfo`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    const response: IUserInfo = await request.json();

    return response;
  };
  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Please enter your register Code That Has Been Sent To Your Email</p>
      <div className="ingame-login">
        <div className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Metamask />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div className="ingame-login-label">
          <h6>Register Code</h6>
          <div className="login-account">
            <Inputs title="Confirmation Code" name="confirmationCode" style={{ color: "#fff!important" }} onChange={handleChange}></Inputs>

            <button onClick={metamaskRegisterCode}>Start Exploring</button>
          </div>
        </div>
      </div>
    </>
  );
};

const TorusRegisterIngame = ({ changeSubComponent }: { changeSubComponent: (subComponent: SubComponent) => void }) => {
  const [registerTorus, setRegisterTorus] = useState<{ userName: string }>({
    userName: "",
  });

  const torusRegister = async () => {
    const state = JSON.parse(sessionStorage.getItem("torus")!);
    console.log(state);
    const request = await fetch(`${apiUrl}/Torus/CreateTorusUserRegister`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        publicAddress: state.publicAddress,
        email: state.email,
        socialMediaVerifier: state.socialMediaVerifier,
        userName: registerTorus.userName,
      }),
    });
    const response = await request.json();
    console.log(response);

    if (response.status === "Success") {
      sessionStorage.setItem("torusRegister", JSON.stringify(response));
      changeSubComponent("torusregistercode");
    } else {
      TorusRegisterError(response.errors);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setRegisterTorus((prev: { userName: string }) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Set your Username and Password</p>
      <div className="ingame-login">
        <div className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Social />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div className="ingame-login-label">
          <h6>Account</h6>
          <div className="login-account">
            <Inputs
              title="Username"
              name="userName"
              className="title-padding"
              style={{ paddingLeft: "45px", color: "#fff!important" }}
              onChange={handleChange}
            >
              <User />
            </Inputs>

            <button onClick={torusRegister}>Start Exploring</button>
          </div>
        </div>
      </div>
    </>
  );
};

const TorusRegisterCodeIngame = () => {
  const navigate = useNavigate();
  const [registerCode, setRegisterCode] = useState<TorusRegisterCodeRequest>({
    registerFormId: "",
    confirmationCode: "",
  });

  const onChangeRegister = (e: ChangeEvent<HTMLInputElement>) => {
    setRegisterCode((prev: TorusRegisterCodeRequest) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const torusRegister = async () => {
    const state = JSON.parse(sessionStorage.getItem("torusRegister")!);

    const request = await fetch(`${apiUrl}/Torus/ConfirmTorusUserRegister`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        registerFormId: state.data.registerFormId,
        confirmationCode: registerCode.confirmationCode,
      }),
    });
    const response = await request.json();
    console.log("response", response);

    if (response?.status === "Success") {
      await new Localstorage().setLocalstorage(response);
      if (response.data.avatarUrl === "" || response.data.avatarUrl === null) {
        sessionStorage.removeItem("torusRegister");
        sessionStorage.removeItem("torus");
        window.location.assign("/create-avatar");
      } else {
        sessionStorage.removeItem("torusRegister");
        sessionStorage.removeItem("torus");

        // *** Mouse kontrolü için sayfa reload ettiriliyor *** //
        window.localStorage.setItem("tokenAtlas", JSON.stringify(response));
        window.location.assign("/meta");

        // *** Mouse kontrolü için kaldırıldı ***//
        // const userInfo = await getMyUserInfo();
        // unityAddAvatar({
        //   token: response.data.token.accessToken,
        //   loginAsAGuest: false,
        //   userInfo: userInfo,
        // });

        // (window as any).addAvatar();
      }
    } else {
      TorusRegisterError(response.errors);
    }
  };

  return (
    <>
      <h1>WELCOME TO ATLAS SPACE</h1>
      <p>Please enter your register Code That Has Been Sent To Your Email</p>
      <div className="ingame-login">
        <div className="auth-ingame-card">
          <div className="auth-ingame-card-image">
            <Social />
          </div>
          <h6>Login With Metamask</h6>
        </div>
        <div className="ingame-login-label">
          <h6>Register Code</h6>
          <div className="login-account">
            <Inputs title="Code" name="confirmationCode" style={{ color: "#fff!important" }} onChange={onChangeRegister}></Inputs>

            <button onClick={torusRegister}>Start Exploring</button>
          </div>
        </div>
      </div>
    </>
  );
};

const AuthInGameModal = ({ setAuthModal }: { setAuthModal: (state: boolean) => void }) => {
  const [subComponent, setSubComponent] = useState<SubComponent>("home");
  const changeSubComponent = (subComponent: SubComponent) => {
    setSubComponent(subComponent);
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="auth-ingame">
      <div className="auth-ingame-close">
        <span onClick={() => setAuthModal(false)}>
          <Close />
        </span>
      </div>
      <div className="auth-ingame-wrapper">
        {subComponent === "home" && <AuthInGameHomeModal changeSubComponent={changeSubComponent} setAuthModal={setAuthModal} />}

        {subComponent === "login" && <AuthInGameLoginModal setAuthModal={setAuthModal} />}

        {subComponent === "register" && <AuthInGameRegisterModal changeSubComponent={changeSubComponent} />}

        {subComponent === "metamaskregister" && <MetamaskRegisterIngame changeSubComponent={changeSubComponent} />}

        {subComponent === "metamaskregistercode" && <MetamaskRegisterCodeIngame />}

        {subComponent === "torusregister" && <TorusRegisterIngame changeSubComponent={changeSubComponent} />}

        {subComponent === "torusregistercode" && <TorusRegisterCodeIngame />}
      </div>
    </motion.div>
  );
};

export default AuthInGameModal;

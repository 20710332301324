import React from "react";

function Icon2() {
  return (
    <svg
      width={80}
      height={80}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1870_42271"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={80}
        height={80}
      >
        <rect width={80} height={80} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1870_42271)">
        <path
          d="M39.9943 39.1341C35.8558 39.1341 32.499 37.8387 29.9241 35.248C27.3491 32.6573 26.0616 29.3031 26.0616 25.1852C26.0616 21.0674 27.3466 17.7178 29.9166 15.1365C32.4865 12.5551 35.8408 11.2644 39.9793 11.2644C44.1179 11.2644 47.4857 12.5533 50.0828 15.1312C52.6798 17.709 53.9783 21.0682 53.9783 25.2088C53.9783 29.3109 52.6823 32.6573 50.0902 35.248C47.4982 37.8387 44.1329 39.1341 39.9943 39.1341ZM11.75 69.1667V59.7632C11.75 57.3919 12.3511 55.3392 13.5533 53.605C14.7555 51.8708 16.3114 50.5532 18.2211 49.6522C22.023 47.8744 25.7028 46.5628 29.2606 45.7173C32.8183 44.8719 36.3884 44.4492 39.9708 44.4492C43.6135 44.4492 47.1933 44.8937 50.7102 45.7826C54.2271 46.6715 57.8865 47.9613 61.6884 49.6522C63.662 50.512 65.2556 51.8138 66.4693 53.5577C67.6831 55.3015 68.2899 57.3659 68.2899 59.751V69.1667H11.75Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default Icon2;

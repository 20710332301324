import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AtlasLogo from "assets/images/atlasspacelogo.png";
import Apple from "assets/images/apple.png";
import Android from "assets/images/android.png";
import "./Launch.css";
const Launch = () => {
  const [mouseDownMouseX, setMouseDownMouseX] = React.useState(0);
  const [mouseUpMouseX, setMouseUpMouseX] = React.useState(0);
  const [checkAndroid, setCheckAndroid] = React.useState(false);
  const [checkIos, setCheckIos] = React.useState(false);
  const downloadAppRef = React.useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: MouseEvent) => {
    setMouseDownMouseX(event.clientX);
  };

  const handleMouseUp = (event: MouseEvent) => {
    console.log("mouseup", event);
    setMouseUpMouseX(event.clientX);
  };

  const mouseMove = () => {
    if (mouseUpMouseX > mouseDownMouseX) {
      if (downloadAppRef.current) {
        downloadAppRef.current.classList.add("active");
      }
    } else {
      if (downloadAppRef.current) {
        downloadAppRef.current.classList.remove("active");
      }
    }
  };

  console.log(mouseDownMouseX, mouseUpMouseX);

  useEffect(() => {
    function checkResponsive() {
      var ua = navigator.userAgent;

      // if android
      var android = /Android/i.test(ua);

      // if ios
      var ios = /iPhone|iPad|iPod/i.test(ua);

      if (android) {
        setCheckAndroid(true);
      }

      if (ios) {
        setCheckIos(true);
      }
    }
    checkResponsive();
    mouseMove();

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);
    // window.addEventListener("mousemove", mouseMove);
  });
  return (
    <div
      style={{ userSelect: "none" }}
      ref={downloadAppRef}
      className="slider-download-app active"
    >
      <div className="slider-download-content">
        <div className="slider-gray-line"></div>
        <div>
          <img src={AtlasLogo} alt="" width={50} height={50} />
        </div>
        <h1>Explore Atlas Space in our mobile app!</h1>
        <div className="download-app-content-line"></div>
        {checkAndroid && (
          <Link
            to={
              "https://play.google.com/store/apps/details?id=com.AtlasSpace.AtlasSpaceMobile"
            }
          >
            <span className="download-app-button">
              <div>
                <img src={Android} alt="" />
              </div>
              Continue in App
            </span>
          </Link>
        )}

        {checkIos && (
          <Link
            to={"https://apps.apple.com/tr/app/atlas-space/id1669209813?l=tr"}
          >
            <span className="download-app-button">
              <div>
                <img src={Apple} alt="" />
              </div>
              Continue in App
            </span>
          </Link>
        )}

        <p>Please use the app for a better experience.</p>
      </div>
      {/* <div className="slider-download-line" style={{ cursor: "pointer" }}></div> */}
    </div>
  );
};

export default Launch;

import React from "react";
import { Route, Routes } from "react-router-dom";
import CreateAvatarView from "views/CreateAvatar/CreateAvatar";
const CreateAvatar = () => {
  return (
    <Routes>
      <Route path="/create-avatar" element={<CreateAvatarView />} />
    </Routes>
  );
};

export default CreateAvatar;

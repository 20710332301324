import { IRegisterError } from "./IRegisterError";

export const customError = ({ ...response }: IRegisterError): void => {
  console.log("response", response);

  response.errors.forEach((error) => {
    if (error.key === "Email Already Registered") {
      const errorElement = document.getElementsByClassName("register");
      errorElement[0].classList.add("error");

      const emailElement = document.getElementsByTagName("label")[2];
      emailElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-small");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      emailElement.insertAdjacentHTML("afterend", createSmallElement.outerHTML);
      const wrongPasswordElement = document.getElementById("wrong-small");
      // const smallPasswordElement =
      //   document.getElementsByClassName("wrong-password");
      // smallPasswordElement[0].classList.add("error");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        emailElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }

    if (error.key === "Password") {
      const errorElement = document.getElementsByClassName("register");
      errorElement[0].classList.add("error");

      const labelPasswordElement = document.getElementsByTagName("label")[5];
      labelPasswordElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-small");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      labelPasswordElement.insertAdjacentHTML(
        "afterend",
        createSmallElement.outerHTML
      );
      const wrongPasswordElement = document.getElementById("wrong-small");
      // const smallPasswordElement =
      //   document.getElementsByClassName("wrong-password");
      // smallPasswordElement[0].classList.add("error");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        labelPasswordElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }

    if (error.key === "SurName") {
      const errorElement = document.getElementsByClassName("register");
      errorElement[0].classList.add("error");

      const divEl = document.querySelector(".input-flex");

      const surnameElement = document.getElementsByTagName("label")[1];
      surnameElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-password");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      divEl?.insertAdjacentHTML("afterend", createSmallElement.outerHTML);
      const wrongPasswordElement = document.getElementById("wrong-password");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        surnameElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }

    if (error.key === "FirstName") {
      const errorElement = document.getElementsByClassName("register");
      errorElement[0].classList.add("error");

      const divEl = document.querySelector(".input-flex");

      const labelPasswordElement = document.getElementsByTagName("label")[0];
      labelPasswordElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-password");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      divEl?.insertAdjacentHTML("afterend", createSmallElement.outerHTML);
      const wrongPasswordElement = document.getElementById("wrong-password");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        labelPasswordElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }

    if (error.key === "UserName" || "Username Already Registered") {
      const errorElement = document.getElementsByClassName("register");
      errorElement[0].classList.add("error");

      const usernameElement = document.getElementsByTagName("label")[4];
      usernameElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-username");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      usernameElement?.insertAdjacentHTML(
        "afterend",
        createSmallElement.outerHTML
      );
      const wrongPasswordElement = document.getElementById("wrong-username");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        usernameElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }
  });
  return;
};

import { BaseService } from "services/BaseService";
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
} from "./types/ResetPasswordTypes";
import axios, { AxiosResponse } from "axios";
import { BaseResponse } from "services/BaseResponse";
import { ResetPasswordError } from "utils/authCustomError/resetPasswordError/ResetPasswordError";
export class ResetPasswordService {
  private userService: BaseService;
  private readonly url: string = "Auth/ChangePasswordWithResetCode";

  constructor() {
    this.userService = new BaseService();
  }

  public async resetPassword(
    data: ResetPasswordRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.userService.post<
        ResetPasswordRequest,
        AxiosResponse<BaseResponse<ResetPasswordResponse>>
      >(this.url, data);

      return response;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        ResetPasswordError(error.response?.data);
        console.log("error", error.response?.data);
      } else {
        console.log(error);
      }
    }
  }
}

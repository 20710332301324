import React from "react";

function Mail({
  width = 80,
  height = 80,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1860_13347"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={80}
        height={80}
      >
        <rect width={80} height={80} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1860_13347)">
        <path
          d="M12.025 68.2456C10.1764 68.2456 8.55903 67.5447 7.17292 66.1428C5.78681 64.7411 5.09375 63.1316 5.09375 61.3143V18.684C5.09375 16.8539 5.78681 15.2332 7.17292 13.8222C8.55903 12.4112 10.1764 11.7057 12.025 11.7057H67.967C69.8286 11.7057 71.457 12.4112 72.8523 13.8222C74.2476 15.2332 74.9453 16.8539 74.9453 18.684V61.3143C74.9453 63.1316 74.2476 64.7411 72.8523 66.1428C71.457 67.5447 69.8286 68.2456 67.967 68.2456H12.025ZM39.996 43.2637L67.967 24.7166V18.684L39.996 36.9231L12.025 18.684V24.7166L39.996 43.2637Z"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default Mail;

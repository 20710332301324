import { unityContext } from "components/UnityComponent/UnityComponent";

const getUnityVideoNumber = () => {
  (window as { [key: string]: any })["get_video_number_and_call"] = async (
    sceneName: any,
    func: any
  ) => {
    var result = await (window as any).getCurrentVideo(sceneName);
    if (result === undefined) console.log("Result is undefined!");
    if (result !== undefined) func(sceneName + "-" + String(result));
  };
};

export const unityGetCurrentVideo = () => {
  getUnityVideoNumber();

  (window as { [key: string]: any })["get_current_video"] = async (
    sceneName: any
  ) => {
    (window as any).get_video_number_and_call(sceneName, (_n: any) => {
      unityContext.send("NetworkManager", "OnVideoNumberUpdated", _n);
    });
  };
};

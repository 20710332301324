import axios, { AxiosResponse } from "axios";
import { BaseResponse } from "../../BaseResponse";
import { BaseService } from "../../BaseService";
import {
  TorusLoginCodeRequest,
  TorusLoginCodeResponse,
} from "../types/TorusTypes/TorusLoginCodeTypes";
import { TorusErrorCustom } from "utils/authCustomError/Torus/TorusError";

export class TorusLoginCodeService {
  private userService: BaseService;
  private readonly url: string = "Torus/ConfirmTorusUserLogin";

  constructor() {
    this.userService = new BaseService();
  }

  public async torusLogin(
    loginData: TorusLoginCodeRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.userService.post<
        TorusLoginCodeRequest,
        AxiosResponse<BaseResponse<TorusLoginCodeResponse>>
      >(this.url, loginData);

      if (response.status === 200) {
        return response;
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.log("error", error.response?.data);
        TorusErrorCustom(error.response?.data);
      } else {
        console.log(error);
      }
    }
  }
}

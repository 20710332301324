import Back from "components/BackButton/Back";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import React from "react";
import PassView from "views/ForgotPass";

interface IProps {
  setForgotEmail: (state: string) => void;
}

const index: React.FC<IProps> = ({ setForgotEmail }) => {
  return (
    <Background>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />

          <PassView setForgotEmail={setForgotEmail} />
        </Container>
      </div>
    </Background>
  );
};

export default index;

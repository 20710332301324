import React, { useEffect } from "react";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import LoginView from "views/Login/Login";
import Back from "components/BackButton/Back";
import DenizBankLogin from "views/DenizbankLogin";
import { useNavigate } from "react-router-dom";
import { domainName, hostname } from "../index";

const DenizBankLoginPage = () => {
    const navigate = useNavigate()


    useEffect(() => {
        console.log("hostname", hostname);

        if (!domainName.includes(hostname)) {
            navigate("/")
        }
    }, [])

    return (
        <Background>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Container>
                    <DenizBankLogin />
                </Container>
            </div>
        </Background>
    );
};

export default DenizBankLoginPage;

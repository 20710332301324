import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import LoginHome from "./LoginHome";
import SocialVerified from "./SocialVerified";
import Register from "./Register/Register";
import Login from "./Login/Login";
import ForgotPass from "./ForgotPassword";
import ResetPass from "./ResetPassword";
import LoginSocial from "./Torus/LoginSocial/LoginSocial";
import SocialFactor from "./Torus/SocialFactor";
import MetamaskRegister from "./Metamask/MetamaskRegister/MetamaskRegister";
import MetamaskRegisterCode from "./Metamask/MetamaskRegisterCode/MetamaskRegisterCode";
import MetamaskLoginCode from "./Metamask/MetamaskLoginCode/MetamaskLoginCode";
import SocialRegisterCode from "./Torus/SocialRegisterCode";
import MetamaskReminder from "./MetamaskReminder";
import { useMetamaskReminder } from "../../store/useMetamaskReminder";
import { AnimatePresence } from "framer-motion";
import DenizBankLoginPage from "./DenizBankLogin";

interface IProps {
    setLoginAsGuest: (state: boolean) => void;
}


export const hostname = window.location.host;
export const domainName = ["denizbank.atlas.space", "ugur.atlas.space"]

const Auth: React.FC<IProps> = ({ setLoginAsGuest }) => {
    const [forgotEmail, setForgotEmail] = useState<string>("");
    const [socialAdress, setSocialAdress] = useState<string>("");
    const [socialRegisterAdress, setSocialRegisterAdress] = useState<string>("");
    const [socialRegisterMail, setSocialRegisterMail] = useState<string>("");
    const [socialRegisterFormId, setSocialRegisterFormId] = useState<string>("");
    const reminders = useMetamaskReminder((state) => state.reminders);
    const fetchMetamaskReminder = useMetamaskReminder(
        (state) => state.fecthMetamaskReminder
    );

    const location = useLocation();

    useEffect(() => {
        fetchMetamaskReminder();
    }, [fetchMetamaskReminder]);




    return (
        <AnimatePresence>

            <Routes location={location} key={location.pathname}>
                {/* Web2 Auth Route */}
                <Route path="/login" element={<Login />} />


                <Route path="/metaversespacesignin" element={<DenizBankLoginPage />} />


                <Route path="/register" element={<Register />} />

                <Route
                    path="/forgotpassword"
                    element={<ForgotPass setForgotEmail={setForgotEmail} />}
                />

                <Route
                    path="/resetpassword"
                    element={<ResetPass forgotEmail={forgotEmail} />}
                />

                <Route
                    path="/"
                    element={
                        <LoginHome
                            setSocialAdress={setSocialAdress}
                            setSocialRegisterAdress={setSocialRegisterAdress}
                            setSocialRegisterMail={setSocialRegisterMail}
                            setLoginAsGuest={setLoginAsGuest}
                        />
                    }
                />

                {/* Web3 Auth Route */}
                <Route path="/socialverified" element={<SocialVerified />} />

                <Route
                    path="/socialregister"
                    element={
                        <LoginSocial
                            socialRegisterAdress={socialRegisterAdress}
                            socialRegisterMail={socialRegisterMail}
                            setSocialRegisterFormId={setSocialRegisterFormId}
                        />
                    }
                />

                <Route
                    path="/socialregistercode"
                    element={<SocialFactor socialRegisterFormId={socialRegisterFormId} />}
                />

                <Route
                    path="/sociallogincode"
                    element={<SocialRegisterCode socialAdress={socialAdress} />}
                />

                {/* Web3 Metamask auth route */}
                <Route path="/metamaskregister" element={<MetamaskRegister />} />
                <Route
                    path="/metamaskregistercode"
                    element={<MetamaskRegisterCode />}
                />
                <Route path="/metamasklogincode" element={<MetamaskLoginCode />} />
                <Route
                    path="/metamaskreminder"
                    element={
                        reminders.metamask === true ? (
                            <MetamaskReminder />
                        ) : (
                            <Navigate to="/meta" />
                        )
                    }
                />
            </Routes>
        </AnimatePresence>
    );
};

export default Auth;

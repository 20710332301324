export const unityReadyUnity = (
  setReady: React.Dispatch<React.SetStateAction<"ready" | "not ready">>
) => {
  (window as { [key: string]: any })["ready_unity"] = (
    value: "ready" | "not ready"
  ) => {
    if (value === "ready") {
      setReady(value);
      console.log("ready_unity");
      return value;
    }
  };
};

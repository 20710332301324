import Background from "components/Background/Background";
import Container from "components/Container/Container";
import RegisterView from "views/Register/Register";
import React from "react";
import Back from "components/BackButton/Back";

const Register = () => {
  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />
          <RegisterView />
        </Container>
      </div>
    </Background>
  );
};

export default Register;

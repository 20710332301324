import { IForgotPassword } from "./IForgotPasswordError";
export const ForgotPasswordError = ({ ...response }: IForgotPassword): void => {
  switch (response.errors[0].key) {
    case "User Not Found":
      console.log("user");

      const usernameElement = document.getElementsByClassName("forgot-account");
      usernameElement[0].classList.add("error");

      const labelUsernameElement = document.getElementsByTagName("label")[0];
      labelUsernameElement.classList.add("username");

      const smallUsernameElement =
        document.getElementsByClassName("wrong-username");
      smallUsernameElement[0].classList.add("error");

      setTimeout(() => {
        usernameElement[0].classList.remove("error");
        labelUsernameElement.classList.remove("username");
        smallUsernameElement[0].classList.remove("error");
      }, 2000);

      break;

    default:
      break;
  }
};

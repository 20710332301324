import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityDrop = () => {
  (window as { [key: string]: any })["drop"] = (ev: any) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      (window as any)["file"] = ev.dataTransfer.items[0].getAsFile();
      unityContext.send(
        "NetworkManager",
        "OnFileDropped",
        (window as any).file.name + "/" + (window as any).file.size
      );
    }
  };
};

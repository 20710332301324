import React from "react";

function Icon4() {
  return (
    <svg
      width={80}
      height={81}
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1870_13258"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={80}
        height={81}
      >
        <rect y="0.5" width={80} height={80} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1870_13258)"></g>
      <g clipPath="url(#clip0_1870_13258)">
        <path
          d="M71.7857 8.83337L43.1797 29.9998L48.4992 17.5258L71.7857 8.83337Z"
          fill="#E17726"
        />
        <path
          d="M8.27422 8.8584L31.4984 17.5274L36.5495 30.1649L8.27422 8.8584ZM60.2713 53.8485L72.9148 54.0891L68.4961 69.1L53.0682 64.8524L60.2713 53.8485ZM19.719 53.8485L26.8953 64.8524L11.4935 69.1003L7.10156 54.0891L19.719 53.8485Z"
          fill="#E27625"
        />
        <path
          d="M35.8664 26.9458L36.3831 43.6341L20.9219 42.9307L25.3198 36.2958L25.3755 36.232L35.8664 26.9458ZM43.9713 26.7596L54.6224 36.2326L54.6776 36.2961L59.0755 42.931L43.6177 43.6341L43.9713 26.7596ZM27.3518 53.8969L35.794 60.4747L25.9872 65.2094L27.3518 53.8969ZM52.6476 53.8958L53.9838 65.2096L44.2044 60.4742L52.6476 53.8958Z"
          fill="#E27625"
        />
        <path
          d="M44.4185 59.8547L54.3422 64.6599L45.1112 69.0469L45.207 66.1475L44.4185 59.8547ZM35.5729 59.8568L34.8154 66.1001L34.8776 69.0436L25.625 64.6599L35.5729 59.8568Z"
          fill="#D5BFB2"
        />
        <path
          d="M32.7044 45.8123L35.2977 51.2623L26.4688 48.6758L32.7044 45.8123ZM47.2839 45.8128L53.549 48.6758L44.6912 51.2615L47.2839 45.8128Z"
          fill="#233447"
        />
        <path
          d="M28.0216 53.8412L26.5945 65.5703L18.9453 54.0977L28.0216 53.8412ZM51.9669 53.8414L61.0435 54.0977L53.3656 65.5708L51.9669 53.8414ZM59.294 42.2675L52.6885 48.9995L47.5956 46.6721L45.1573 51.7982L43.5588 42.9833L59.294 42.2675ZM20.6906 42.2675L36.4289 42.9833L34.8302 51.7982L32.3914 46.6729L27.3255 48.9997L20.6906 42.2675Z"
          fill="#CC6228"
        />
        <path
          d="M20.25 40.886L27.7234 48.4696L27.9823 55.9563L20.25 40.886ZM59.7544 40.8724L52.0081 55.9696L52.2997 48.4696L59.7544 40.8724ZM36.068 41.348L36.3688 43.2412L37.112 47.9576L36.6341 62.4433L34.375 50.8066L34.3742 50.6862L36.068 41.348ZM43.925 41.3217L45.6232 50.6862L45.6224 50.8066L43.3576 62.4724L43.268 59.5545L42.9146 47.8717L43.925 41.3217Z"
          fill="#E27525"
        />
        <path
          d="M52.9664 48.1682L52.7135 54.6729L44.8297 60.8154L43.2359 59.6893L45.0224 50.4875L52.9664 48.1682ZM27.0625 48.1682L34.9789 50.4875L36.7654 59.6893L35.1716 60.8154L27.2875 54.6724L27.0625 48.1682Z"
          fill="#F5841F"
        />
        <path
          d="M24.1172 63.2235L34.2036 68.0027L34.1609 65.9618L35.005 65.2209H44.9878L45.8622 65.9592L45.7977 67.9985L55.8203 63.2355L50.9432 67.2657L45.0461 71.3159H34.924L29.0307 67.249L24.1172 63.2235Z"
          fill="#C0AC9D"
        />
        <path
          d="M43.6956 59.2185L45.1216 60.2261L45.9573 66.8937L44.7479 65.8727H35.249L34.0625 66.9143L34.8708 60.2266L36.2974 59.2185H43.6956Z"
          fill="#161616"
        />
        <path
          d="M69.8969 9.41931L73.3307 19.7206L71.1862 30.1365L72.7133 31.3144L70.6469 32.8909L72.2 34.0904L70.1435 35.9631L71.406 36.8774L68.0555 40.7904L54.3133 36.7891L54.1943 36.7253L44.2914 28.3717L69.8969 9.41931ZM10.0979 9.41931L35.7036 28.3717L25.8005 36.7253L25.6815 36.7891L11.9393 40.7904L8.5888 36.8774L9.85026 35.9638L7.79505 34.0904L9.34505 32.8922L7.24766 31.3112L8.83229 30.1326L6.66406 19.7211L10.0979 9.41931Z"
          fill="#763E1A"
        />
        <path
          d="M53.6383 35.9141L68.199 40.1534L72.9294 54.7331H60.4492L51.8503 54.8415L58.1039 42.6519L53.6383 35.9141ZM26.3508 35.9141L21.8844 42.6519L28.1388 54.8415L19.5438 54.7331H7.08594L11.7898 40.1537L26.3508 35.9141ZM49.2091 17.4563L45.1365 28.456L44.2721 43.3154L43.9414 47.973L43.9151 59.8709H36.0737L36.0482 47.9954L35.7164 43.3115L34.8518 28.456L30.7797 17.4563H49.2091Z"
          fill="#F5841F"
        />
      </g>
      <defs>
        <clipPath id="clip0_1870_13258">
          <rect
            width="66.6667"
            height="62.5"
            fill="white"
            transform="translate(6.66406 8.83337)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon4;

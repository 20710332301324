import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityStart = () => {
  (window as { [key: string]: any })["unityStart"] = (
    p1: string,
    p2: string,
    p3?: string | number | boolean
  ) => {
    unityContext.send(p1, p2, p3);
  };
};

import { apiUrl } from "api/apiUrl";
import { Localstorage } from "utils/localstorage/Localstorage";
import { getMyUserInfo } from "api/getMyUserInfo/GetMyUserInfo";

export const createAvatar = async (avatarImageUrl: string) => {
  const getTokenInLocalStorage = await new Localstorage().getLocalstorage();

  await fetch(`${apiUrl}/User/UpdateUserAvatar`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getTokenInLocalStorage.data.token.accessToken,
    },
    body: JSON.stringify({ avatarUrl: avatarImageUrl }),
  });

  const userInfo = await getMyUserInfo();

  getTokenInLocalStorage.data.avatarUrl = userInfo?.data?.avatarUrl;
  localStorage.setItem("tokenAtlas", JSON.stringify(getTokenInLocalStorage));

  const canvas = document.getElementById(
    "ready-player-me-container"
  ) as HTMLCanvasElement;
  canvas.style.display = "none";
};

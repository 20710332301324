import { apiUrl } from "api/apiUrl";

import { create } from "zustand";

export interface IGuestLoginResponse {
  status: string;
  errors: any;
  message: any;
  data: Data;
}

export interface Data {
  userId: string;
  role: string[];
  token: Token;
}

export interface Token {
  accessToken: string;
  expiration: string;
}

interface IGuestLoginState {
  data: IGuestLoginResponse;
  guestLogin: () => Promise<void>;
}

export const useGuestLogin = create<IGuestLoginState>()((set) => ({
  data: {
    status: "",
    errors: {},
    message: {},
    data: {
      userId: "",
      role: [],
      token: {
        accessToken: "",
        expiration: "",
      },
    },
  },

  guestLogin: async () => {
    const request = await fetch(`${apiUrl}/Auth/CreateGuestUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    });
    const response = await request.json();
    localStorage.clear();
    localStorage.setItem("tokenAtlas", JSON.stringify(response));
    set(() => ({ data: response }));
  },
}));

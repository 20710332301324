import { IMetamask } from "./IMetamaskError";

export const MetamaskErrorCustom = ({ ...response }: IMetamask): void => {
  response.errors.forEach((error) => {
    if (error.key === "UserName") {
      const codeElement = document.getElementsByClassName("metamask-account");
      codeElement[0].classList.add("error");

      const labelCodeElement = document.getElementsByTagName("label")[0];
      labelCodeElement.classList.add("code");

      const smallElement = document.createElement("small");
      smallElement.style.color = "white";
      smallElement.style.fontWeight = "bold";
      smallElement.innerText = error.description;

      codeElement[0].appendChild(smallElement);

      setTimeout(() => {
        codeElement[0].classList.remove("error");
        labelCodeElement.classList.remove("code");
        codeElement[0].removeChild(smallElement);
      }, 2000);
    } else if (error.key === "Public Address Already Exists") {
      const codeElement = document.getElementsByClassName("metamask-account");

      const smallElement = document.createElement("small");
      smallElement.style.color = "white";
      smallElement.style.fontWeight = "bold";
      smallElement.innerText = error.description;

      codeElement[0].appendChild(smallElement);

      setTimeout(() => {
        codeElement[0].removeChild(smallElement);
      }, 2000);
    } else if (error.key === "Username Already Registered") {
      const codeElement = document.getElementsByClassName("metamask-account");
      codeElement[0].classList.add("error");

      const labelCodeElement = document.getElementsByTagName("label")[0];
      labelCodeElement.classList.add("code");

      const smallElement = document.createElement("small");
      smallElement.style.color = "white";
      smallElement.style.fontWeight = "bold";
      smallElement.innerText = error.description;

      codeElement[0].appendChild(smallElement);

      setTimeout(() => {
        codeElement[0].classList.remove("error");
        labelCodeElement.classList.remove("code");
        codeElement[0].removeChild(smallElement);
      }, 2000);
    }
  });
};

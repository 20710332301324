import React from "react";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import LoginSocialView from "views/Torus/LoginSocial/LoginSocial";
import Back from "components/BackButton/Back";

interface IProps {
  socialRegisterAdress: string;
  socialRegisterMail: string;
  setSocialRegisterFormId: (state: string) => void;
}

const LoginSocial: React.FC<IProps> = ({
  socialRegisterAdress,
  socialRegisterMail,
  setSocialRegisterFormId,
}) => {
  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />

          <LoginSocialView />
        </Container>
      </div>
    </Background>
  );
};

export default LoginSocial;

import React, { useEffect, useLayoutEffect, useState } from "react";
import { IResponse } from "./IResponse";
import { IUserInfo } from "./IUserInfo";
import Loading from "components/Loading/Loading";
import { unityLoadingOpen } from "utils/unityIntegration/unityLoadingOpen";
import { unityLoadingOff } from "utils/unityIntegration/unityLoadingOff";
import { unityLoadingValue } from "utils/unityIntegration/unityLoadingValue";
import UnityComponent, { unityContext } from "components/UnityComponent/UnityComponent";
import { unityOpenAI } from "utils/unityIntegration/unityOpenAI";
import { unityInsertReadyPlayerMe } from "utils/unityIntegration/unityInsertReadyPlayerMe";
import { unitySetAudio } from "utils/unityIntegration/unitySetAudio";
import { unityListFiles } from "utils/unityIntegration/unityListFiles";
import { unityListAndCall } from "utils/unityIntegration/unityListAndCall";

import { apiUrl } from "api/apiUrl";
import AuthInGameModal from "components/AuthInGameModal/AuthInGameModal";
import { torus } from "views/LoginHome";
import { unityLoginPressed } from "utils/unityIntegration/unityLoginPressed";
import { unityCheckUrl } from "utils/unityIntegration/unityChatBot";
import { unityGetCurrentVideo } from "utils/unityIntegration/unityGetCurrentVideo";
import { unityStart } from "utils/unityIntegration/unityStart";
import { unityStartContext } from "utils/unityIntegration/unityStartContext";
import { unitySendSceneName } from "utils/unityIntegration/unitySendSceneName";
import { unityReadyUnity } from "utils/unityIntegration/unityReadyUnity";
import { unityReactLoginUI } from "utils/unityIntegration/unityReactLoginUI";
import { unityDownloadFile } from "utils/unityIntegration/unityDownloadFile";
import { unityDrop } from "utils/unityIntegration/unityDrop";
import { unityOpenFileDialogFile } from "utils/unityIntegration/unityOpenFileDialogFile";
import { unityUploadAndCall } from "utils/unityIntegration/unityUploadAndCall";
import { unityUploadDroppedFile } from "utils/unityIntegration/unityUploadDroppedFile";
import { unityUploadFileDialogVideo } from "utils/unityIntegration/unityUploadFileDialogVideo";
import { unityGetBuilderScenes } from "utils/unityIntegration/unityGetBuilderScenes";
import { unityEnableUnityDebugMode } from "utils/unityIntegration/unityDebugMode";
import Welcome from "components/Welcome/Welcome";
import MetamaskReminder from "components/MetamaskReminder/MetamaskReminder";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { unitySetSceneInformation } from "utils/unityIntegration/unitySceneInformation";
import { unityOpenSiteModal } from "utils/unityIntegration/unityOpenSiteModal";

interface IProps {
    loginAsAGuest: boolean;
    setLoginAsGuest: React.Dispatch<React.SetStateAction<boolean>>;
}

const UnityContextView: React.FC<IProps> = ({ loginAsAGuest, setLoginAsGuest }) => {
    const { state } = useLocation();

    console.log("state", state);

    const [loading, setLoading] = useState(true);
    const [loadingValue, setLoadingValue] = useState<number>();
    const [loadingInformation, setLoadingInformation] = useState<boolean>(true);
    const [userInformation, setUserInformation] = useState<IUserInfo>();
    const [authModal, setAuthModal] = useState<boolean>(false);
    const [sceneName, setSceneName] = useState<string>("Atlas Space World");
    const [ready, setReady] = useState<"ready" | "not ready">("not ready");
    const [showWelcome, setShowWelcome] = useState<boolean>(true);
    const [showWelcomeDonot, setShowWelcomeDonot] = useState<boolean>(true);
    const [metamaskDoNotShow, setMetamaskDoNotShow] = useState<boolean>(true);

    const [metamaskDoNot, setMetamaskDoNot] = useState<boolean>(true);
    const [token, setToken] = useState<any>();

    const [searchParams, setSearchParams] = useSearchParams();

    const veri = {
        welcome: showWelcome,
        metamask: metamaskDoNotShow,
    };

    console.log("searchparams", searchParams.get("scene_name"));

    const userWelcome = async (showCheck: "metamask" | "welcome") => {
        const formData = new FormData();
        formData.append("doNotShowAgainJsonString", JSON.stringify(veri));

        const request = await fetch(`https://api-prod.atlas.space/api/User/AddUserDoNotShowAgainJson`, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
            },
            body: formData,
        });

        await request.json();
        if (showCheck === "metamask") {
            setMetamaskDoNot(false);
        } else if (showCheck === "welcome") {
            setShowWelcomeDonot(false);
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        if (torus.isLoggedIn === true) {
            torus.showTorusButton();
        }

        if (authModal === true) {
            unityContext.send("NetworkManager", "CloseKeyboardInput");
        } else {
            unityContext.send("NetworkManager", "OpenKeyboardInput");
        }

        class UnityInstance {
            SendMessage(p1: any, p2: any, p3: any) {
                unityContext?.send(p1, p2, p3);
            }
        }

        (window as any)["unityInstance"] = new UnityInstance();

        const getAccount = async () => {
            const account = await window.ethereum.request({
                method: "eth_accounts",
                params: [],
            });
            return account;
        };

        const DoNotShowWelcome = async () => {
            const localInfo = await getLocalStorage();

            if (localInfo?.data?.userId) {
                try {
                    const request = await fetch(`https://cdn.atlas.space/files/seen/${localInfo?.data?.userId}.json`, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                    const account = await getAccount();
                    const response = await request.json();
                    console.log("reponse", response);
                    console.log("account", account);

                    if (account.length > 0) {
                        setMetamaskDoNotShow(false);
                        setMetamaskDoNot(false);
                        setShowWelcomeDonot(response.welcome);
                        setShowWelcome(response.welcome);
                    } else {
                        setShowWelcomeDonot(response.welcome);
                        setShowWelcome(response.welcome);
                        setMetamaskDoNot(response.metamask);
                        setMetamaskDoNotShow(response.metamask);
                    }
                } catch (error) {
                    console.log("error", error);
                }
            }
        };

        const getLocalStorage = async (): Promise<IResponse> => {
            const local: IResponse = await JSON.parse(localStorage.getItem("tokenAtlas") as any);
            setToken(local?.data?.token?.accessToken);
            return local;
        };

        const getMyUserInfo = async (): Promise<IUserInfo> => {
            const localInfo = await getLocalStorage();

            const request = await fetch(`${apiUrl}/User/GetMyUserInfo`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localInfo?.data?.token?.accessToken,
                },
            });

            const response: IUserInfo = await request.json();


            if (response.status === "Success") {
                unityReactLoginUI({
                    token: localInfo?.data?.token?.accessToken,
                    loginAsAGuest: loginAsAGuest,
                    userInfo: response,
                });
            } else {
                unityReactLoginUI(null);
            }

            setUserInformation(response);
            setLoadingInformation(false);
            return response;
        };

        if (state && state.loginType === "denizbank") {
            (window as any)["react_login_ui"] = async () => {
                console.log("react_login_ui");
            };

            unityContext.send("NetworkManager", "LoginGuestWithNickname", state.username);
        } else {

            getMyUserInfo();
        }



        unityStart();

        unityStartContext();

        unityReadyUnity(setReady);

        unitySendSceneName(setSceneName);

        unityLoadingValue(setLoadingValue);

        unityUploadAndCall();

        setTimeout(() => {
            unityListAndCall();

            unityListFiles();
        }, 1000);

        unityLoadingOff(setLoading);

        unityLoadingOpen(setLoading);

        unityGetCurrentVideo();

        unityInsertReadyPlayerMe();

        unityLoginPressed(setAuthModal);

        unityGetBuilderScenes();

        unityDownloadFile();

        unityDrop();

        unityOpenFileDialogFile();

        unityUploadDroppedFile();

        unityUploadFileDialogVideo();

        unityOpenAI();

        unitySetAudio();

        // unityCheckUrl();

        unitySetSceneInformation();

        unityEnableUnityDebugMode();

        unityOpenSiteModal();

        DoNotShowWelcome();

        // if (ready === "ready") {
        //   console.log("ready unity");
        //   (window as any).addAvatar();
        // }

        const handleContextmenu = (e: MouseEvent) => {
            e.preventDefault();
        };

        document.addEventListener("contextmenu", handleContextmenu);

        return function cleanup() {
            document.removeEventListener("contextmenu", handleContextmenu);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginAsAGuest, ready, authModal, loading]);

    useLayoutEffect(() => {
        if (unityContext?.htmlCanvasElement) {
            unityContext.htmlCanvasElement.style.zIndex = "2";
        }
    });

    useEffect(() => {
        const sceneName = searchParams.get("scene_name");
        const roomName = searchParams.get("room_name");

        ReactGA.event({
            category: "scene",
            action: "scene",
            label: sceneName ? sceneName + ` username= ${userInformation?.data?.userName === null ? "Guest" : userInformation?.data?.userName}` : "",
        });
    }, [userInformation]);

    return (
        <>
            <UnityComponent />

            {loading && <Loading loadingValue={loadingValue!} sceneName={sceneName} />}

            {/* {showWelcomeDonot === true && <Welcome setShowWelcome={setShowWelcome} userWelcome={userWelcome} setShowWelcomeDonot={setShowWelcomeDonot} />}

      {metamaskDoNot === true && (
        <MetamaskReminder userWelcome={userWelcome} setMetamaskDoNot={setMetamaskDoNot} setMetamaskDoNotShow={setMetamaskDoNotShow} />
      )} */}

            {authModal === true && <AuthInGameModal setAuthModal={setAuthModal} />}
        </>
    );
};

export default UnityContextView;

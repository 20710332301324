import React from "react";
import "./Inputs.css";
import { IProps } from "./IProps";
const Inputs: React.FC<IProps> = ({ title, children, className, ...props }) => {
  return (
    <label htmlFor="">
      {children}
      <input {...props} required={true} autoComplete={"off"} />
      <h6 className={className}>{title}</h6>
    </label>
  );
};

export default Inputs;

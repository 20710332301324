import Mail from "assets/Svg/Mail";
import AuthCards from "components/AuthCards/AuthCards";
import "./LoginHome.css";
import { Link, useNavigate } from "react-router-dom";
import User from "assets/Svg/UserSvg";
import Social from "assets/Svg/Social";
import Metamask from "assets/Svg/Metamask";
import MetamaskFrame from "assets/Svg/MetamaskFrame";
import Torus from "@toruslabs/torus-embed";
import { Localstorage } from "utils/localstorage/Localstorage";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";
import { connectWithMetamask } from "utils/metamaskLogin/MetamaskLogin";
import { useGuestLogin } from "store/useGuestLogin";
import { useState } from "react";
import Web3Loading from "components/Web3Loading/Web3Loading";

import ReactGA from "react-ga4";

interface IProps {
  setLoginAsGuest: (state: boolean) => void;
}
export const torus = new Torus({
  buttonPosition: "top-left",
});

const Index: React.FC<IProps> = ({ setLoginAsGuest }) => {
  const navigate = useNavigate();
  const guestLogin = useGuestLogin((state) => state.guestLogin);
  const [socialLoading, setSocialLoading] = useState(false);

  const socialLogin = async () => {
    try {
      ReactGA.event({
        category: "Login",
        action: "socialLogin",
        label: "socialLogin",
      });
      setSocialLoading(true);
      await torus.init();
      setSocialLoading(false);
      await torus.login();
      const userInfo: any = await torus.getUserInfo("userInformation");
      const publicAddress = torus.provider.selectedAddress;
      torus.hideTorusButton();

      const response = await checkTorusPublicAddress(publicAddress!);
      const info = {
        publicAddress: publicAddress,
        email: userInfo.email,
        socialMediaVerifier: userInfo.typeOfLogin,
      };

      if (response.data.torusUserIsExists === true) {
        console.log("torus üyeliği var");
        const responseTorusLogin = await userSocialLogin(publicAddress!);
        console.log("response torus", responseTorusLogin);

        if (responseTorusLogin.status === "Success") {
          new Localstorage().setLocalstorage(responseTorusLogin);
          if (responseTorusLogin.data.avatarUrl === "" || responseTorusLogin.data.avatarUrl === null) {
            navigate("/create-avatar");
          } else {
            navigate("/meta");
          }
        }
      } else {
        navigate("/socialregister", {
          state: info,
        });
      }
    } catch (error: any) {
      if (error.message === "User cancelled login") {
        torus.clearInit();
      } else if (error.message === "user closed popup") {
        torus.cleanUp();
      }
    }
  };

  const checkTorusPublicAddress = async (address: string) => {
    const response = await fetch(`${apiUrl}/Torus/CheckTorusUserExists?publicAddress=${address}`);
    const data = await response.json();
    return data;
  };

  const userSocialLogin = async (publicAddress: string) => {
    const request = await fetch(`${apiUrl}/Torus/LoginTorusUser`, {
      method: "POST",
      headers: {
        "CONTENT-TYPE": "application/json",
      },
      body: JSON.stringify({
        publicAddress: publicAddress,
      }),
    });
    const response = await request.json();
    return response;
  };

  const guestSignIn = async () => {
    ReactGA.event({
      category: "Login",
      action: "guestLogin",
      label: "guestLogin",
    });
    localStorage.removeItem("tokenAtlas");
    setLoginAsGuest(true);
    navigate("/meta");
    // guestLogin();
  };

  return (
    <>
      {socialLoading === true && <Web3Loading />}

      {socialLoading === false && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="login">
          <div className="login-title">
            <h1> WELCOME TO ATLAS SPACE</h1>
            <p>Join To The Atlas Space For Free</p>
          </div>

          <div className="login-container">
            <div className="login-mail">
              <AuthCards title="Log in with E-Mail" url="/login">
                <Mail />
              </AuthCards>
            </div>

            <div className="login-metamask">
              <div className="login-frame">
                <MetamaskFrame />
              </div>
              <div className="login-metamask-container" onClick={() => connectWithMetamask(navigate, setSocialLoading)}>
                <AuthCards title="Log in with Metamask" url="/">
                  <Metamask />
                </AuthCards>
              </div>

              <Link to="https://metamask.io/">
                <p>What is crypto-wallet?</p>
              </Link>
            </div>
            <div className="login-social">
              <div onClick={socialLogin}>
                <AuthCards title="Log in with Socials" url="/">
                  <Social />
                </AuthCards>
              </div>

              <Link to="/socialverified">
                <p>Why I should be verified?</p>
              </Link>
            </div>
            <div className="login-guest" onClick={guestSignIn}>
              <AuthCards title="Log in as a Guest" url="/meta">
                <User />
              </AuthCards>
            </div>
          </div>

          <div className="already-account">
            <span>
              Don’t have an account? <Link to="/register">Sign Up</Link>
            </span>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Index;

import { unityContext } from "components/UnityComponent/UnityComponent";
import { IAddAvatarData } from "./unityAddAvatar";
import { unityAddAvatar } from "./unityAddAvatar";

export const unityReactLoginUI = (avatarInit: IAddAvatarData | null) => {
    (window as any)["react_login_ui"] = async () => {
        console.log("react_login_ui");

        unityAddAvatar(avatarInit);
        var isReady = (window as any).ready_unity("ready");
        if (isReady === "ready") {
            //unityContext.send("NetworkManager", "Reset");
            (window as any).addAvatar();
        }
    };
};

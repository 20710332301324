import React from "react";
import "./Back.css";
import BackSvg from "assets/Svg/Back";
import { Link } from "react-router-dom";

interface IProps {
  link: string;
}

const Back: React.FC<IProps> = ({ link }) => {
  return (
    <Link to={link} className="back">
      <BackSvg />
      Back
    </Link>
  );
};

export default Back;

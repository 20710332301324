import { IResetPasswordError } from "./IResetPasswordError";

export const ResetPasswordError = ({
  ...response
}: IResetPasswordError): void => {
  switch (response.errors[0].key) {
    case "Invalid Reset Code":
      const verifyCodeElement =
        document.getElementsByClassName("reset-account");
      verifyCodeElement[0].classList.add("error");

      const verificationInputElement =
        document.getElementsByTagName("label")[0];
      verificationInputElement.classList.add("verificationcode");

      const smallElement = document.getElementsByClassName(
        "wrong-verificationcode"
      );

      smallElement[0].classList.add("error");

      setTimeout(() => {
        verifyCodeElement[0].classList.remove("error");
        verificationInputElement.classList.remove("verificationcode");
        smallElement[0].classList.remove("error");
      }, 2000);

      break;

    case "Reset Code Expired":
      const expiredCodeElement =
        document.getElementsByClassName("reset-account");
      expiredCodeElement[0].classList.add("error");

      const expiredCodeInputElement = document.getElementsByTagName("label")[0];
      expiredCodeInputElement.classList.add("verificationcode");

      const expiredSmallElement =
        document.getElementsByClassName("resetcode-expired");

      expiredSmallElement[0].classList.add("error");

      setTimeout(() => {
        expiredCodeElement[0].classList.remove("error");
        expiredCodeInputElement.classList.remove("verificationcode");
        expiredSmallElement[0].classList.remove("error");
      }, 2000);

      break;

    case "Password and confirm password are not the same":
      const passwordElement = document.getElementsByClassName("reset-account");
      passwordElement[0].classList.add("error");

      const passwordInputElement = document.getElementsByTagName("label");
      passwordInputElement[1].classList.add("password");
      passwordInputElement[2].classList.add("password");

      break;

    case "Password check success":
      const passwordCheckElement =
        document.getElementsByClassName("reset-account");
      passwordCheckElement[0].classList.remove("error");

      const passwordCheckInputElement = document.getElementsByTagName("label");
      passwordCheckInputElement[1].classList.remove("password");
      passwordCheckInputElement[2].classList.remove("password");
      break;
    default:
      break;
  }
};

import { IBaseService } from "./IBaseService";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { Localstorage } from "../utils/localstorage/Localstorage";
import { BaseResponse } from "./BaseResponse";

export class BaseService implements IBaseService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL:
        process.env.NODE_ENV === "development"
          ? "https://api-stage.atlas.space/api/"
          : "https://api-prod.atlas.space/api/",
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.axiosInstance.interceptors.request.use((config) => {
      const data = new Localstorage().getLocalstorage();
      if (data) {
        config.headers.Authorization = `Bearer ${data}`;
      }
      return config;
    });
  }

  public async get<T>(url: string): Promise<T> {
    const response = await this.axiosInstance.get(url);
    try {
      return response.data;
    } catch (error) {
      console.log(error);
    }
    return response.data;
  }

  public async post<T, U>(
    url: string,
    data: T
  ): Promise<AxiosResponse<BaseResponse<U>>> {
    const response = await this.axiosInstance.post(url, data);
    return response;
  }
}

import Logo from "assets/Svg/Logo";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import "./Loading.css";
import React, { useState, useEffect } from "react";
import ProgressBar from "./Progressbar";
import text from "./text.json";

interface IProps {
  loadingValue: number;
  sceneName: string;
}

const Loading: React.FC<IProps> = ({ loadingValue, sceneName }) => {
  const textData = Object.entries(text)[0][1];

  const [currentTip, setCurrentTip] = useState(0);
  // const [mapsName, setMapsName] = useState("Social Island");
  // const mapsName = location === "bms.atlas.space" ? "BMS" : "Social Island";
  // console.log(location);

  useEffect(() => {
    // const changeMapsName = () => {
    //   const location = window.location.host;
    //   if ("bms.atlas.space" === location) {
    //     setMapsName("BMS");
    //   } else if ("denizbank.atlas.space" === location) {
    //     setMapsName("DenizBank");
    //   }
    // };

    // changeMapsName();

    const interval = setInterval(() => {
      setCurrentTip((currentTip) => (currentTip + 1) % textData.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [textData.length]);

  return (
    <div style={{ position: "relative", zIndex: "999" }}>
      <Background>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Container>
            <div className="loading">
              <div className="loading-logo">
                <Logo />
              </div>
              <div className="loading-container">
                <h3>
                  You are now entering {sceneName}. Please wait while loading...
                </h3>
                <p> {textData[currentTip]}</p>
              </div>
            </div>
          </Container>

          <ProgressBar loadingValue={loadingValue} />
        </div>
      </Background>
    </div>
  );
};

export default Loading;

import Background from "components/Background/Background";
import Container from "components/Container/Container";
import MetamaskLoginCodeView from "views/Metamask/MetamaskLoginCode/MetamaskLoginCode";
import Back from "components/BackButton/Back";

const MetamaskLoginCode = () => {
  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/metamaskregister" />
          <MetamaskLoginCodeView />
        </Container>
      </div>
    </Background>
  );
};

export default MetamaskLoginCode;

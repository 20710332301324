import { unityContext } from "components/UnityComponent/UnityComponent";

export const closeReadyPlayerMe = (
  imageButton: HTMLImageElement,
  canvas: HTMLCanvasElement
) => {
  imageButton.addEventListener("click", () => {
    document.getElementById("frame")!.hidden = true;
    document.getElementById("ready-player-me-container")!.style.display =
      "none";
    canvas.hidden = false;
  });

  unityContext.send("NetworkManager", "OpenKeyboardInput");
};

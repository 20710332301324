import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityUploadFileDialogVideo = () => {
  (window as { [key: string]: any })["open_file_dialog_video"] = async (
    sceneName: any
  ) => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_this) => {
      (window as any).upload_and_call(
        input.files![0],
        "videos/" + sceneName,
        sceneName,
        (_s: any) => {
          unityContext.send("NetworkManager", "OnVideoUploaded", _s);
        }
      );
    };
    input.click();
    input.remove();
  };
};

import React, { useState } from 'react'

import { motion } from "framer-motion";
import Inputs from 'components/Inputs/Inputs';
import User from 'assets/Svg/Register/User';
import "./denizbank.css"
import { useNavigate } from 'react-router-dom';

const DenizBankLogin = () => {
    const navigate = useNavigate()
    const [genders, setGenders] = useState({
        male: true,
        female: false

    })

    const [username, setUsername] = useState("")

    const denizbankLogin = () => {
        console.log("nameesss", `${username}|${genders.male ? "1" : "0"}`);

        // unityContext.send("NetworkManager", "LoginGuestWithNickname", `${username}|${genders.male ? "1" : "0"}`)
        navigate("/meta", {
            state: {
                username: `${username}|${genders.male ? "1" : "0"}`,
                loginType: "denizbank"

            }
        })
    }

    const handleKeyPress = (event: any) => {
        // Klavyeden girilen tuşun kodunu al
        const keyCode = event.key
        console.log("key code", keyCode);

        // Eğer '#' veya '_' karakterlerinden biri girildiyse, işlemi durdur
        if (keyCode === "#") {
            event.preventDefault();
        }
    };



    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="login-email">
            <div className="login-title">
                <h1>Welcome to ATLAS SPACE</h1>
                <p>Join to the Atlas Space for free</p>
            </div>
            <div className="denizbank-wrapper">

                <div className='denizbank-account'>
                    <h6>Account</h6>
                    <Inputs title="Username" name="usernameOrEmail" className="title-padding" style={{ paddingLeft: "45px" }} onChange={(e) => setUsername(e.currentTarget.value)} onKeyPress={handleKeyPress} >
                        <User />
                    </Inputs>

                </div>
                <div className='select-gender'>
                    <h6>Select Your Gender</h6>

                    <div className='genders'>
                        <div className='gender-radio'>
                            <h6>Male</h6>
                            <input type="radio" id='gender' name='gender' checked={genders.male} onChange={() => {
                                setGenders({ male: true, female: false })
                            }} />
                        </div>
                        <div className='gender-radio'>
                            <h6>Female</h6>
                            <input type="radio" id='gender' name='gender' checked={genders.female} onChange={() => {
                                setGenders({ male: false, female: true })
                            }} />
                        </div>
                    </div>

                </div>

                <div className="login-accept denizbank-btn">
                    <button onClick={denizbankLogin}>Accept and Start Exploring</button>
                </div>
            </div>
        </motion.div>
    )
}

export default DenizBankLogin
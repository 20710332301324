import Mail from "assets/Svg/Mail";
import User from "assets/Svg/Register/User";
import AuthCards from "components/AuthCards/AuthCards";
import Inputs from "components/Inputs/Inputs";
import "./MetamaskRegister.css";
import Metamask from "assets/Svg/Metamask";
import { ChangeEvent, useState, useEffect } from "react";
import Terms from "views/Terms/";
import { MetamaskRegisterRequest } from "services/AuthService/types/MetamaskTypes";
import { useNavigate } from "react-router-dom";
import { MetamaskErrorCustom } from "utils/authCustomError/Metamask/MetamaskError";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";
const MetamaskRegister = ({ address }: { address: string }) => {
  const navigate = useNavigate();

  const [showMetamaskRegister, setShowMetamaskRegister] =
    useState<boolean>(false); //terms page için kontrol
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<MetamaskRegisterRequest>({
    publicAddress: address?.toLowerCase(),
    email: "",
    userName: "",
  });

  const validateEmail = () => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,}$/i;
    if (data?.email.match(regex)) {
      return regex.test(data?.email);
    } else if (data?.email === "") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const emailControl = () => {
      const deneme = validateEmail();
      setError(deneme);
    };
    emailControl();
  });

  console.log(error);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const metamaskRegister = async () => {
    if (data.email === "") {
      const request = await fetch(`${apiUrl}/Metamask/CreateMetamaskUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const response = await request.json();
      console.log(response);

      if (response.status === "Success") {
        localStorage.setItem("tokenAtlas", JSON.stringify(response));
        if (
          response.data.avatarUrl === "" ||
          response.data.avatarUrl === null
        ) {
          navigate("/create-avatar");
        } else {
          navigate("/meta");
        }
      } else {
        MetamaskErrorCustom(response);
      }
    } else {
      console.log(data);

      const request = await fetch(
        `${apiUrl}/Metamask/CreateMetaMaskUserRegisterForm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const response = await request.json();
      if (response.status === "Success") {
        navigate("/metamaskregistercode", {
          state: response,
        });
      } else {
        MetamaskErrorCustom(response);
      }
    }
  };

  if (!showMetamaskRegister) {
    return (
      <>
        <Terms setShowRegister={setShowMetamaskRegister} />
      </>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="metamask-email"
    >
      <div className="metamask-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p> Set your Username and Password </p>
      </div>
      <div className="metamask-flex">
        <div className="metamask-left">
          <AuthCards title="Log in with Metamask" url="/">
            <Metamask />
          </AuthCards>
        </div>
        <div className="metamask-account">
          <h6>Account</h6>
          <Inputs
            title="Username"
            name="userName"
            className="title-padding"
            style={{ paddingLeft: "45px" }}
            type="text"
            onChange={handleChange}
          >
            <User />
          </Inputs>
          <Inputs
            title="Email (Optional)"
            name="email"
            className="title-padding"
            style={{ paddingLeft: "45px" }}
            onChange={handleChange}
          >
            <Mail width={20} height={20} />
          </Inputs>

          <div className="metamask-remember">
            <div className="metamask-checkbox">
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
          </div>
        </div>
      </div>
      <div className="metamask-accept">
        <button onClick={metamaskRegister}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default MetamaskRegister;

import React, { useState, useEffect } from "react";
import "./Progressbar.css";
interface IProps {
  loadingValue: number;
}

const ProgressBar: React.FC<IProps> = ({ loadingValue }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let targetProgress = 0;
    let startTimestamp: number | null = null;

    if (loadingValue === undefined) {
      targetProgress = lerp(10, 78, progress / 78);
    } else if (loadingValue >= 0 && loadingValue <= 0.375) {
      targetProgress = lerp(78, 99, loadingValue / 0.375);
    } else if (loadingValue > 0.375 && loadingValue <= 0.9) {
      targetProgress = lerp(99, 100, (loadingValue - 0.5) / 0.605);
    } else if (loadingValue > 0.9 && loadingValue <= 1) {
      targetProgress = 100; // Set the target progress to 99.5 instead of 100
    }

    const animate = (timestamp: number) => {
      if (!startTimestamp) {
        startTimestamp = timestamp;
      }

      const elapsedTime = timestamp - startTimestamp;
      let duration = 1000; // Total animation duration in milliseconds

      // Adjust duration for the range above 98%
      if (progress > 88 && progress < 100) {
        duration = 200; // Faster animation duration
      }

      if (elapsedTime < duration) {
        setProgress(lerp(progress, targetProgress, elapsedTime / duration));
        requestAnimationFrame(animate);
      } else {
        setProgress(targetProgress);
      }
    };

    requestAnimationFrame(animate);

    return () => {
      startTimestamp = null;
    };
  }, [loadingValue, progress]);

  const lerp = (start: number, end: number, t: number) => {
    return start + (end - start) * t;
  };

  return (
    <div className="progress-bar">
      <div className="progress" style={{ width: `${progress}%` }}>
        <div className="progress-text">{Math.floor(progress)}%</div>
      </div>
    </div>
  );
};
export default ProgressBar;

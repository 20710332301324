import React from "react";
import { IProps } from "./IProps";
import "./PrivacyBackground.css";
import { motion } from "framer-motion";
const PrivacyBackground: React.FC<IProps> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="privacy"
    >
      <div className="privacy-content">{children}</div>
    </motion.div>
  );
};

export default PrivacyBackground;

import { ILogin } from "./ILoginError";

export const LoginError = ({ ...response }: ILogin): void => {
  response.errors.forEach((error) => {
    if (error.key === "Wrong Password") {
      const errorElement = document.getElementsByClassName("login-account");
      errorElement[0].classList.add("error");

      const passwordElement = document.getElementsByTagName("label")[1];
      passwordElement.classList.add("password");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-small-password");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      passwordElement.insertAdjacentHTML(
        "afterend",
        createSmallElement.outerHTML
      );
      const wrongPasswordElement = document.getElementById(
        "wrong-small-password"
      );
      // const smallPasswordElement =
      //   document.getElementsByClassName("wrong-password");
      // smallPasswordElement[0].classList.add("error");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        passwordElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }

    if (error.key === "Email or Username Not Found") {
      const errorElement = document.getElementsByClassName("login-account");
      errorElement[0].classList.add("error");

      const emailElement = document.getElementsByTagName("label")[0];
      emailElement.classList.add("email");

      const createSmallElement = document.createElement("small");
      createSmallElement.setAttribute("id", "wrong-small");
      createSmallElement.style.color = "white";
      createSmallElement.style.fontSize = "12px";
      createSmallElement.style.fontWeight = "bold";
      createSmallElement.innerText = error.description;

      emailElement.insertAdjacentHTML("afterend", createSmallElement.outerHTML);
      const wrongPasswordElement = document.getElementById("wrong-small");
      // const smallPasswordElement =
      //   document.getElementsByClassName("wrong-password");
      // smallPasswordElement[0].classList.add("error");

      setTimeout(() => {
        errorElement[0].classList.remove("error");
        emailElement.classList.remove("password");
        wrongPasswordElement?.remove();
        // smallPasswordElement[0].classList.remove("error");
      }, 2000);
    }
  });
};

import Background from "components/Background/Background";
import Container from "components/Container/Container";
import MetamaskRegisterCodeView from "views/Metamask/MetamaskRegisterCode/MetamaskRegisterCode";
import Back from "components/BackButton/Back";

const MetamaskRegisterCode = () => {
  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/metamaskregister" />
          <MetamaskRegisterCodeView />
        </Container>
      </div>
    </Background>
  );
};

export default MetamaskRegisterCode;

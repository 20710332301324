import { useEffect } from "react";
import Unity, { UnityContext } from "react-unity-webgl";
export const unityContext = new UnityContext({
    loaderUrl: "unity/Build/atlas.loader.js",
    dataUrl: "unity/Build/atlas.data.unityweb",
    frameworkUrl: "unity/Build/atlas.js.unityweb",
    codeUrl: "unity/Build/atlas.wasm.unityweb",
});

export const { unload }: any = unityContext;

const UnityComponent = () => {
    useEffect(() => {
        setTimeout(() => {
            unityContext.send("WebGLBridge", "KeyboardInputClose");
        }, 1000);
    }, []);

    return (
        <>
            <Unity
                style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    zIndex: "-1",
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                }}
                unityContext={unityContext}
            />
        </>
    );
};

export default UnityComponent;

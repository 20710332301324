import Launch from "components/Launch/Launch";
import Auth from "pages/Auth";
import CreateAvatar from "pages/Avatar/CreateAvatar";
import Unity from "pages/UnityPages";
import { useEffect, useState } from "react";
import { unload } from "components/UnityComponent/UnityComponent";
import ReactGA from "react-ga4";
import CreateNftGallery from "pages/CreateNftGallery";

function App() {
  const [loginAsAGuest, setLoginAsGuest] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(false);

  const initGA = () => {
    ReactGA.initialize("G-JHHDRE471Y"); // Replace with your tracking ID
  };
  const logPageView = () => {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  };

  useEffect(() => {
    initGA();
    logPageView();

    function checkResponsive() {
      var ua = navigator.userAgent;
      var responsive = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
      setMobile(responsive);
    }

    const handleBackButton = async () => {
      window.location.assign("/");
      await unload();
    };

    checkResponsive();

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(mobile);

  return (
    <>
      {mobile === true && <Launch />}
      {mobile === false && (
        <>
          <Auth setLoginAsGuest={setLoginAsGuest} />
          <Unity loginAsAGuest={loginAsAGuest} setLoginAsGuest={setLoginAsGuest} />
          <CreateAvatar />
          <CreateNftGallery />
        </>
      )}
    </>
  );
}

export default App;

import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityOpenFileDialogFile = () => {
  (window as { [key: string]: any })["open_file_dialog_file"] = async (
    sceneName: any,
    username: any
  ) => {
    let input = document.createElement("input");
    input.type = "file";
    input.onchange = (_this) => {
      var file = input.files![0];
      var size = file.size / 1024.0 / 1024;
      size = 0; //
      if (size > 10) {
        //unityInstance.SendMessage("NetworkManager", "OnFileLoadFailed");
        unityContext.send(
          "NetworkManager",
          "OnFileUploadStarted",
          file.name + "/" + file.size + "/" + username + "/" + "true"
        );
      } else {
        unityContext.send(
          "NetworkManager",
          "OnFileUploadStarted",
          file.name + "/" + file.size + "/" + username + "/" + "false"
        );
        (window as any).upload_and_call(
          input.files![0],
          "files/" + sceneName + "/" + username,
          "",
          (_s: any) => {
            unityContext.send("NetworkManager", "OnFileUploaded", _s);
          }
        );
      }
    };
    input.click();
    input.remove();
  };
};

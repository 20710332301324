export const unityUploadAndCall = () => {
  (window as { [key: string]: any })["upload_and_call"] = async (
    file: any,
    subAdress: any,
    name: any,
    func: any
  ) => {
    var fileName = name;
    if (name === "") {
      fileName = file.name;
    }

    var address = await (window as any).upload_file(file, subAdress, fileName);
    func(address);
  };
};

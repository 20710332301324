import Background from "components/Background/Background";
import Container from "components/Container/Container";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginHomeView from "views/LoginHome";
import { domainName, hostname } from "../index";

interface IProps {
    setSocialAdress: (state: string) => void;
    setSocialRegisterAdress: (state: string) => void;
    setSocialRegisterMail: (state: string) => void;
    setLoginAsGuest: (state: boolean) => void;
}

const Index: React.FC<IProps> = ({
    setSocialAdress,
    setSocialRegisterAdress,
    setSocialRegisterMail,
    setLoginAsGuest,
}) => {
    const navigate = useNavigate()



    useEffect(() => {

        if (domainName.includes(hostname)) {
            navigate("/metaversespacesignin")
        }
    }, [])

    return (
        <Background>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Container>
                    <LoginHomeView setLoginAsGuest={setLoginAsGuest} />
                </Container>
            </div>
        </Background>
    );
};

export default Index;

export const unityLoginPressed = (
  setLoginPressed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  (window as { [key: string]: any })["login_pressed"] = (param: number) => {
    console.log("loginpressed", param);
    if (param === 0) {
      setLoginPressed(false);
      (window as any)["insertReadyPlayerMeIFrame"]();
    } else if (param === 1) {
      setLoginPressed(true);
    }
  };
};

import React, { useEffect, useRef, useState } from "react";
import "./CreateAvatar.css";
import { unityContext } from "components/UnityComponent/UnityComponent";
import { apiUrl } from "api/apiUrl";
const CreateAvatar = () => {
  const subdomain = "fullbody"; // See section about becoming a partner
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const [showIFrame, setShowIFrame] = useState(true);

  useEffect(() => {
    let iFrame = iFrameRef.current;
    if (iFrame) {
      iFrame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
    }
  }, []);

  const createAvatar = async (url: string) => {
    const localInToken = await getLocalStorage();

    await fetch(`${apiUrl}/User/UpdateUserAvatar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localInToken.data.token.accessToken,
      },
      body: JSON.stringify({ avatarUrl: url }),
    });

    const userInfo = await getMyUserInfo();
    localInToken.data.avatarUrl = userInfo?.data?.avatarUrl;
    localStorage.setItem("tokenAtlas", JSON.stringify(localInToken));
    setTimeout(() => {
      window.location.assign("/meta");
    }, 1000);
  };

  const getLocalStorage = async () => {
    const local = await JSON.parse(localStorage.getItem("tokenAtlas") as any);
    return local;
  };

  const getMyUserInfo = async () => {
    const localInfo = await getLocalStorage();
    const requet = await fetch(`${apiUrl}/User/GetMyUserInfo`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localInfo.data.token.accessToken,
      },
    });
    const response = await requet.json();
    return response;
  };

  async function subscribe(event: any) {
    const json = parse(event);
    if (json?.source !== "readyplayerme") {
      return;
    }
    // Subscribe to all events sent from Ready Player Me
    // once frame is ready
    if (json.eventName === "v1.frame.ready") {
      let iFrame = iFrameRef.current;
      if (iFrame && iFrame.contentWindow) {
        iFrame.contentWindow.postMessage(
          JSON.stringify({
            target: "readyplayerme",
            type: "subscribe",
            eventName: "v1.**",
          }),
          "*"
        );
      }
    }
    // Get avatar GLB URL
    if (json.eventName === "v1.avatar.exported") {
      await createAvatar(json.data.url);
      unityContext.send(
        "NetworkManager",
        "ConnectFromWebGL",
        JSON.stringify(json.data)
      );

      setShowIFrame(false);
    }

    // Get user id
    if (json.eventName === "v1.user.set") {
      console.log(`User with id ${json.data.id} set:
 ${JSON.stringify(json)}`);
    }
  }

  function parse(event: any) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    window.addEventListener("message", subscribe);
    document.addEventListener("message", subscribe);

    return () => {
      window.removeEventListener("message", subscribe);
      document.removeEventListener("message", subscribe);
    };
  });
  return (
    <div className="App">
      <iframe
        allow="camera *; microphone *"
        className="iFrame"
        id="frame"
        ref={iFrameRef}
        style={{
          display: `${showIFrame ? "block" : "none"}`,
        }}
        title={"Ready Player Me"}
      />
    </div>
  );
};

export default CreateAvatar;

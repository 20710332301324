import { BaseService } from "services/BaseService";
import Web3Modal from "web3modal";
import { Web3Provider } from "@ethersproject/providers";
import axios, { AxiosResponse } from "axios";
import {
  MetamaskLoginFormRequest,
  MetamaskRegisterRequest,
  MetamaskRegisterResponse,
  MetamaskRegisterCodeRequest,
  MetamaskLoginCodeRequest,
} from "./types/MetamaskTypes";
import { BaseResponse } from "services/BaseResponse";
import { MetamaskErrorCustom } from "utils/authCustomError/Metamask/MetamaskError";

export class MetamaskService {
  private metamaskService: BaseService;

  private readonly url: string = "Metamask/CreateMetaMaskUserLoginForm";

  private readonly registerUrl: string =
    "Metamask/CreateMetaMaskUserRegisterForm";

  private readonly registerCodeUrl: string =
    "Metamask/ConfirmMetaMaskUserRegisterCode";

  private readonly loginCodeUrl: string =
    "Metamask/ConfirmMetaMaskUserLoginCode";

  constructor() {
    this.metamaskService = new BaseService();
  }

  private async ConnectWallet(): Promise<MetamaskLoginFormRequest> {
    var isOpera = navigator.userAgent.indexOf(" OPR/") >= 0;

    if (isOpera) {
      let accounts = await window.ethereum.request({
        /* New */ method: "eth_requestAccounts" /* New */,
      });
      const requestFromAddress: MetamaskLoginFormRequest = {
        publicAddress: accounts[0],
      };
      sessionStorage.setItem("publicAddress", accounts[0]);

      return requestFromAddress;
    } else {
      const web3modal = new Web3Modal({ cacheProvider: true });
      const instance = await web3modal.connect();
      const provider = new Web3Provider(instance);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      sessionStorage.setItem("publicAddress", address);
      const requestFromAddress: MetamaskLoginFormRequest = {
        publicAddress: address,
      };
      return requestFromAddress;
    }
  }

  public async MetamaskLoginForm(): Promise<AxiosResponse | void> {
    const address = await this.ConnectWallet();

    try {
      const response = await this.metamaskService.post<
        MetamaskLoginFormRequest,
        AxiosResponse<BaseResponse<null>>
      >(this.url, address);

      return response;
    } catch (error: any | AxiosResponse<BaseResponse<null>>) {
      if (axios.isAxiosError(error)) {
        return error.response?.data;
      } else {
        console.log("loggg", error.response.data);
      }
    }
  }

  public async MetamaskRegisterForm(
    data: MetamaskRegisterRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.metamaskService.post<
        MetamaskRegisterRequest,
        AxiosResponse<BaseResponse<MetamaskRegisterResponse>>
      >(this.registerUrl, data);
      sessionStorage.setItem("formId", JSON.stringify(response.data.data));

      return response;
    } catch (error) {
      console.log(error);
    }
  }

  public async MetamaskRegisterCode(
    data: MetamaskRegisterCodeRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.metamaskService.post<
        MetamaskRegisterCodeRequest,
        AxiosResponse
      >(this.registerCodeUrl, data);

      return response;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.log(error.response);

        MetamaskErrorCustom(error.response?.data);
      } else {
        console.log(error);
      }
    }
  }

  public async MetamaskLoginCode(
    data: MetamaskLoginCodeRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.metamaskService.post<
        MetamaskLoginCodeRequest,
        AxiosResponse
      >(this.loginCodeUrl, data);
      return response;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.log(error.response);

        MetamaskErrorCustom(error.response?.data);
      } else {
        console.log(error);
      }
    }
  }
}

import { unityContext } from "components/UnityComponent/UnityComponent";

export const unityUploadDroppedFile = () => {
  (window as { [key: string]: any })["upload_dropped_file"] = (
    sceneName: number,
    username: any
  ) => {
    var file = undefined;
    if (file !== undefined) {
      var size = file["size"] / 1024.0 / 1024;
      size = 0; //
      if (size > 10) {
        unityContext.send("NetworkManager", "OnFileLoadFailed");
      } else {
        (window as any).upload_and_call(
          file,
          "files/" + sceneName + "/" + username,
          "",
          (_s: any) => {
            unityContext.send("NetworkManager", "OnFileUploaded", _s);
            file = undefined;
          }
        );
      }
    }
  };
};

import React from 'react'

const User = () => {
    return (
        <svg
            width={18}
            height={19}
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.99674 9.24017C7.75517 9.24017 6.74815 8.85156 5.97566 8.07435C5.20316 7.29715 4.81691 6.29087 4.81691 5.05552C4.81691 3.82017 5.20241 2.8153 5.97341 2.0409C6.7444 1.26648 7.75067 0.879272 8.99224 0.879272C10.2338 0.879272 11.2441 1.26595 12.0233 2.0393C12.8024 2.81265 13.1919 3.82041 13.1919 5.0626C13.1919 6.29323 12.8031 7.29715 12.0255 8.07435C11.2479 8.85156 10.2383 9.24017 8.99674 9.24017ZM0.523438 18.2499V15.4289C0.523438 14.7175 0.703771 14.1017 1.06444 13.5814C1.42509 13.0612 1.89186 12.6659 2.46476 12.3956C3.60533 11.8623 4.70928 11.4688 5.77661 11.2151C6.84393 10.9615 7.91495 10.8347 8.98969 10.8347C10.0825 10.8347 11.1564 10.9681 12.2115 11.2347C13.2666 11.5014 14.3644 11.8883 15.505 12.3956C16.097 12.6535 16.5751 13.0441 16.9392 13.5672C17.3034 14.0904 17.4854 14.7097 17.4854 15.4252V18.2499H0.523438Z"
                fill="white"
            />
        </svg>
    )
}

export default User
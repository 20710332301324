import Back from "components/BackButton/Back";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import React from "react";
import SocialVerifiedView from "views/SocialVerified";

function index() {
  return (
    <Background>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />

          <SocialVerifiedView />
        </Container>
      </div>
    </Background>
  );
}

export default index;

import { Localstorage } from "utils/localstorage/Localstorage";

export const getMyUserInfo = async () => {
  const getTokenInLocalStorage = await new Localstorage().getLocalstorage();
  const requet = await fetch(
    `https://api-prod.atlas.space/api/User/GetMyUserInfo`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + getTokenInLocalStorage.data.token.accessToken,
      },
    }
  );
  const response = await requet.json();
  return response;
};

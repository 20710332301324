import React, { ChangeEvent, useState } from "react";
import "./LoginSocial.css";
import AuthCards from "components/AuthCards/AuthCards";
import Inputs from "components/Inputs/Inputs";
import User from "assets/Svg/Register/User";
import Social from "assets/Svg/Social";
import Terms from "views/Terms/";
import { useLocation, useNavigate } from "react-router-dom";
import { TorusRegisterError } from "utils/authCustomError/Torus/TorusError";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";

const LoginSocial = () => {
  const [showLoginSocial, setShowLoginSocial] = useState<boolean>(false); //terms page için kontrol
  const navigate = useNavigate();
  const { state } = useLocation();

  console.log(state);

  const [registerTorus, setRegisterTorus] = useState<{ userName: string }>({
    userName: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setRegisterTorus((prev: { userName: string }) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

  const torusRegister = async () => {
    console.log(state);
    const request = await fetch(`${apiUrl}/Torus/CreateTorusUserRegister`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        publicAddress: state.publicAddress,
        email: state.email,
        socialMediaVerifier: state.socialMediaVerifier,
        userName: registerTorus.userName,
      }),
    });
    const response = await request.json();
    console.log(response);

    if (response.status === "Success") {
      navigate("/socialregistercode", {
        state: response.data.registerFormId,
      });
    } else {
      TorusRegisterError(response.errors);
    }
  };

  if (!showLoginSocial) {
    return <Terms setShowRegister={setShowLoginSocial} />;
  }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="login-email"
    >
      <div className="login-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p>Set your Username and Password</p>
      </div>
      <div className="login-flex">
        <div className="login-left">
          <AuthCards title="Log in with Socials" url="/">
            <Social />
          </AuthCards>
        </div>
        <div className="login-account">
          <h6>Account</h6>
          <Inputs
            title="Username"
            name="userName"
            className="title-padding"
            style={{ paddingLeft: "45px" }}
            onChange={handleChange}
          >
            <User />
          </Inputs>

          <div className="login-remember">
            <div className="login-checkbox">
              <input type="checkbox" />
              <span>Remember Me</span>
            </div>
          </div>
        </div>
      </div>
      <div className="login-accept">
        <button onClick={torusRegister}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default LoginSocial;

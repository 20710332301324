import React from "react";
import { Link } from "react-router-dom";
import "./SocialVerified.css";
import PrivacyBackground from "components/PrivacyBg/PrivacyBackground";

function index() {
  return (
    <PrivacyBackground>
      <div className="social-title">
        <h1>Why Should I be Verified?</h1>
      </div>
      <div className="social-container-text">
        <p>
          In order to provide a secure and trustworthy environment for all our
          users, we require verification during the sign-up process. Verifying
          your identity helps us ensure the authenticity of our user base,
          promoting a reliable community. By confirming user identities, we can
          effectively prevent fraudulent activities, protect against scams, and
          maintain a high level of security. Additionally, verification enables
          us to offer personalized features and tailored experiences based on
          your preferences, enhancing your overall user experience. Compliance
          with relevant regulations is also a priority, and verification helps
          us meet legal requirements, providing a safe platform for sensitive
          information and financial transactions. By verifying your identity, we
          aim to foster a community that upholds our standards and prioritizes
          user safety.
        </p>
        <Link to="/">
          <p>Return To Login</p>
        </Link>
      </div>
    </PrivacyBackground>
  );
}

export default index;

import React from "react";
interface EmailProps {
  width?: string;
  height?: string;
}

const Key: React.FC<EmailProps> = ({ width, height }) => {
  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "14"}
      viewBox="0 0 24 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.8326 10.0718C7.7326 10.0718 8.4951 9.75925 9.1201 9.13425C9.7451 8.50925 10.0576 7.74675 10.0576 6.84675C10.0576 5.94675 9.7451 5.18425 9.1201 4.55925C8.4951 3.93425 7.7326 3.62175 6.8326 3.62175C5.94203 3.62175 5.18189 3.93425 4.55218 4.55925C3.92246 5.18425 3.6076 5.94675 3.6076 6.84675C3.6076 7.74675 3.92246 8.50925 4.55218 9.13425C5.18189 9.75925 5.94203 10.0718 6.8326 10.0718ZM6.83745 13.1935C5.08277 13.1935 3.58785 12.5748 2.3527 11.3375C1.11757 10.1001 0.5 8.60208 0.5 6.84343C0.5 5.08478 1.116 3.58788 2.348 2.35273C3.58 1.11758 5.07487 0.5 6.8326 0.5C8.28532 0.5 9.52585 0.898008 10.5542 1.69402C11.5826 2.49004 12.2967 3.5174 12.6967 4.7761H21.174L23.2337 6.7685L19.9283 10.4772L17.2935 8.25653L15.0685 10.5207L13.4402 8.8674H12.6967C12.3424 10.1428 11.6282 11.1826 10.5543 11.987C9.48031 12.7913 8.24137 13.1935 6.83745 13.1935Z"
        fill="white"
      />
    </svg>
  );
};

export default Key;

import { ITorusError, ITorusError2 } from "./ITorusError";

export const TorusErrorCustom = ({ ...response }: ITorusError): void => {
  switch (response.errors[0].key) {
    case "Confirmation Code Not Found":
      const codeElement = document.getElementsByClassName("login-account");
      codeElement[0].classList.add("error");

      const labelCodeElement = document.getElementsByTagName("label")[0];
      labelCodeElement.classList.add("code");

      const smallUsernameElement =
        document.getElementsByClassName("wrong-code");
      smallUsernameElement[0].classList.add("error");

      setTimeout(() => {
        codeElement[0].classList.remove("error");
        labelCodeElement.classList.remove("code");
        smallUsernameElement[0].classList.remove("error");
      }, 2000);
      break;

    default:
      break;
  }
};

export const TorusRegisterError = (error: ITorusError2[]): void => {
  error.forEach((data) => {
    if (
      data.key === "UserName" ||
      data.key === "Username Already Registered" ||
      data.key === "Confirmation Code Not Found"
    ) {
      const usernameElement = document.getElementsByClassName("login-account");
      usernameElement[0].classList.add("error");

      const usernameLabelElement = document.getElementsByTagName("label")[0];
      usernameLabelElement.classList.add("email");

      const smallElement = document.createElement("small");
      smallElement.style.color = "white";
      smallElement.style.fontWeight = "bold";
      smallElement.innerText = data.description;
      usernameElement[0].appendChild(smallElement);

      setTimeout(() => {
        usernameElement[0].classList.remove("error");
        usernameLabelElement.classList.remove("username");
        usernameElement[0].removeChild(smallElement);
      }, 2000);
    }
    console.log("test");
  });
};

import React from "react";
import "./AuthCards.css";
import { Link } from "react-router-dom";

interface IProps {
  title?: string;
  children?: React.ReactNode;
  url: string;
}

const AuthCards: React.FC<IProps> = ({ title, url, children }) => {
  return (
    <Link to={url}>
      <div className="auth-cards">
        <div className="card-svg">{children}</div>
        <h3 className="auth-title">{title}</h3>
      </div>
    </Link>
  );
};

export default AuthCards;

import React, { ChangeEvent, useState } from "react";
import "./ForgotPass.css";
import AuthCards from "components/AuthCards/AuthCards";
import Mail from "assets/Svg/Mail";
import Inputs from "components/Inputs/Inputs";
import { ForgotPasswordService } from "services/AuthService/ForgotPassword";
import { ForgotPasswordRequest } from "services/AuthService/types/ForgotPasswordTypes";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
interface IProps {
  setForgotEmail: (state: string) => void;
}

const Index: React.FC<IProps> = ({ setForgotEmail }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<ForgotPasswordRequest>({
    emailOrUsername: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail((prev: ForgotPasswordRequest) => {
      setForgotEmail(e.target.value);
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

  const forgotPass = async () => {
    const userService = new ForgotPasswordService();
    const response = await userService.forgotPassword(email);
    if (response?.status === 200) {
      navigate("/resetpassword");
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="forgotpass-wrapper"
    >
      <div className="forgot-pass">
        <div className="forgot-email">
          <div className="forgot-title">
            <h1>Forgot Your Password</h1>
            <p>
              Please enter the email associated with your Atlas Space account.
              <br /> You will receive the link to reset your password
            </p>
          </div>
          <div className="forgot-flex">
            <div className="forgot-left">
              <AuthCards title="Log in with E-Mail" url="/">
                <Mail />
              </AuthCards>
            </div>
            <div className="forgot-account">
              <h6>Email</h6>
              <Inputs
                title="Email"
                name="emailOrUsername"
                className="title-padding"
                style={{ paddingLeft: "45px" }}
                onChange={handleChange}
              ></Inputs>
              <small className="wrong-username">User Not Found</small>
            </div>
          </div>
          <div className="forgot-accept">
            <button onClick={forgotPass}>
              Request Link
              {/* <Link to="/resetpassword">Request Link</Link> */}
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Index;

import Back from "components/BackButton/Back";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import React from "react";
import ResetPassView from "views/ResetPass";
interface IProps {
  forgotEmail: string;
}

const index: React.FC<IProps> = ({ forgotEmail }) => {
  return (
    <Background>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/forgotpassword" />
          <ResetPassView forgotEmail={forgotEmail} />
        </Container>
      </div>
    </Background>
  );
};

export default index;

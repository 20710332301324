import React from "react";

function Icon3() {
  return (
    <svg
      width={80}
      height={81}
      viewBox="0 0 80 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_1870_84169"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={80}
        height={80}
      >
        <rect width={80} height={80} fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1870_84169)"></g>
      <g clipPath="url(#clip0_1870_84169)">
        <mask
          id="mask1_1870_84169"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={32}
          height={33}
        >
          <path d="M32 0.5H0V32.5H32V0.5Z" fill="white" />
        </mask>
        <g mask="url(#mask1_1870_84169)">
          <path
            d="M31.9863 16.7994C31.9863 15.4884 31.8774 14.5317 31.6418 13.5396H16.3203V19.4569H25.3137C25.1324 20.9274 24.1533 23.142 21.9774 24.6301L21.9469 24.8281L26.7913 28.4951L27.1269 28.5279C30.2093 25.7463 31.9863 21.6537 31.9863 16.7994Z"
            fill="#4285F4"
          />
          <path
            d="M16.3203 32.3901C20.7263 32.3901 24.4252 30.9727 27.1269 28.5279L21.9774 24.6301C20.5994 25.5691 18.7499 26.2246 16.3203 26.2246C12.0049 26.2246 8.34231 23.4432 7.03669 19.5986L6.84531 19.6145L1.80806 23.4236L1.74219 23.6025C4.42569 28.8112 9.93781 32.3901 16.3203 32.3901Z"
            fill="#34A853"
          />
          <path
            d="M7.03512 19.5986C6.69062 18.6065 6.49125 17.5434 6.49125 16.445C6.49125 15.3465 6.69063 14.2835 7.017 13.2914L7.00787 13.0801L1.9075 9.20984L1.74063 9.2874C0.634625 11.4489 0 13.8761 0 16.445C0 19.0139 0.634625 21.441 1.74063 23.6025L7.03512 19.5986Z"
            fill="#FBBC05"
          />
          <path
            d="M16.3203 6.66537C19.3846 6.66537 21.4516 7.95869 22.6302 9.03948L27.2357 4.64571C24.4072 2.07679 20.7263 0.5 16.3203 0.5C9.93781 0.5 4.42569 4.07875 1.74219 9.28742L7.01856 13.2914C8.34231 9.44693 12.0049 6.66537 16.3203 6.66537Z"
            fill="#EB4335"
          />
        </g>
        <mask
          id="mask2_1870_84169"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={48}
          y={0}
          width={32}
          height={33}
        >
          <path d="M80 0.5H48V32.5H80V0.5Z" fill="white" />
        </mask>
        <g mask="url(#mask2_1870_84169)">
          <path
            d="M78.2339 32.5001C79.2091 32.5001 80 31.7094 80 30.734V2.26612C80 1.29062 79.2091 0.5 78.2339 0.5H49.7661C48.7905 0.5 48 1.29062 48 2.26612V30.734C48 31.7094 48.7905 32.5001 49.7661 32.5001H78.2339Z"
            fill="#395185"
          />
          <path
            d="M70.0793 32.5001V20.108H74.2389L74.8616 15.2786H70.0793V12.1951C70.0793 10.7969 70.4676 9.844 72.4728 9.844L75.0301 9.84288V5.5235C74.5876 5.46463 73.0696 5.33313 71.3036 5.33313C67.6164 5.33313 65.0921 7.58375 65.0921 11.717V15.2786H60.9219V20.108H65.0921V32.5001H70.0793Z"
            fill="white"
          />
        </g>
        <mask
          id="mask3_1870_84169"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x={0}
          y={48}
          width={32}
          height={33}
        >
          <path d="M32 48.5H0V80.5H32V48.5Z" fill="white" />
        </mask>
        <g mask="url(#mask3_1870_84169)">
          <path
            d="M27.2655 75.7658H22.5241V68.3405C22.5241 66.5698 22.4925 64.2905 20.0581 64.2905C17.5886 64.2905 17.2108 66.2197 17.2108 68.2116V75.7653H12.4695V60.4958H17.0211V62.5826H17.0849C17.5404 61.8037 18.1986 61.163 18.9895 60.7286C19.7803 60.2942 20.6742 60.0825 21.5759 60.116C26.3815 60.116 27.2675 63.277 27.2675 67.3892L27.2655 75.7658ZM7.1195 58.4087C5.59988 58.409 4.36775 57.1772 4.3675 55.6576C4.36725 54.138 5.59888 52.9058 7.1185 52.9056C8.63813 52.9052 9.87025 54.137 9.8705 55.6566C9.87063 56.3863 9.58088 57.0863 9.06497 57.6024C8.54906 58.1185 7.84926 58.4085 7.1195 58.4087ZM9.49025 75.766H4.74388V60.4958H9.49013L9.49025 75.766ZM29.6293 48.5022H2.36138C1.07263 48.4877 0.01575 49.5201 0 50.8088V78.1904C0.01525 79.4798 1.072 80.5132 2.36125 80.4997H29.6293C30.9213 80.5157 31.9821 79.4823 32 78.1904V50.8067C31.9816 49.5155 30.9206 48.4832 29.6293 48.5001"
            fill="#0A66C2"
          />
        </g>
        <path
          d="M80 54.0401C78.8017 54.571 77.5307 54.9193 76.2292 55.0738C77.5849 54.2613 78.6258 52.9747 79.1159 51.4417C77.8272 52.2064 76.4173 52.7453 74.947 53.0349C73.7494 51.759 72.0432 50.9615 70.1549 50.9615C66.5291 50.9615 63.5895 53.9011 63.5895 57.5266C63.5895 58.0413 63.6476 58.5423 63.7595 59.0229C58.3032 58.749 53.4657 56.1353 50.2276 52.1633C49.6626 53.1329 49.3389 54.2608 49.3389 55.4638C49.3389 57.7415 50.498 59.7509 52.2595 60.9283C51.2169 60.8956 50.1973 60.6141 49.2858 60.107C49.2854 60.1345 49.2854 60.1621 49.2854 60.1898C49.2854 63.3706 51.5484 66.024 54.5516 66.6272C53.5849 66.8901 52.5708 66.9286 51.5869 66.7397C52.4222 69.3479 54.8469 71.246 57.7196 71.2991C55.4728 73.0599 52.6419 74.1094 49.5661 74.1094C49.0361 74.1094 48.5136 74.0783 48 74.0177C50.9054 75.8804 54.3563 76.9674 58.0638 76.9674C70.1396 76.9674 76.743 66.9634 76.743 58.2879C76.743 58.0032 76.7368 57.72 76.724 57.4385C78.0093 56.5094 79.1186 55.3587 80 54.0401Z"
          fill="#55ACEE"
        />
      </g>
      <defs>
        <clipPath id="clip0_1870_84169">
          <rect width={80} height={81} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon3;

import Inputs from "components/Inputs/Inputs";
import Metamask from "assets/Svg/Metamask";
import AuthCards from "components/AuthCards/AuthCards";
import "./MetamaskLoginCode.css";
import { ChangeEvent, useState } from "react";
import { MetamaskLoginCodeRequest } from "services/AuthService/types/MetamaskTypes";
import { MetamaskService } from "services/AuthService/MetamaskService";
import { useNavigate } from "react-router-dom";
import { Localstorage } from "utils/localstorage/Localstorage";
import { unityContext } from "components/UnityComponent/UnityComponent";
import { motion } from "framer-motion";
const MetamaskLoginCode = () => {
  const localstorage = new Localstorage();
  const navigate = useNavigate();
  const [data, setData] = useState<
    Omit<MetamaskLoginCodeRequest, "loginFormId">
  >({
    confirmationCode: "",
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const metamaskLoginCode = async () => {
    const registerForm = JSON.parse(
      sessionStorage.getItem("loginFormId") as any
    );
    const response = await new MetamaskService().MetamaskLoginCode({
      confirmationCode: data.confirmationCode,
      loginFormId: registerForm.loginFormId,
    });

    localstorage.setLocalstorage(response?.data);
    // localStorage.setItem("tokenAtlas", JSON.stringify(response.data));

    if (response?.status === 200) {
      unityContext.send(
        "NetworkManager",
        "AuthTokenTaken",
        response?.data?.data?.token?.accessToken
      );
      if (
        response?.data.data.avatarUrl === null ||
        response?.data.data.avatarUrl === ""
      ) {
        navigate("/create-avatar");
      } else {
        navigate("/meta");
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="metamasklogin-email"
    >
      <div className="metamasklogin-title">
        <h1>Welcome to ATLAS SPACE</h1>
        <p>Please enter your register Code That Has Been Sent To Your Email</p>
      </div>
      <div className="metamasklogin-flex">
        <div className="metamasklogin-left">
          <AuthCards title="Log in with Metamask" url="/">
            <Metamask />
          </AuthCards>
        </div>
        <div className="metamaskregister-account">
          <h6>Login Code</h6>
          <Inputs
            title="Code"
            name="confirmationCode"
            onChange={handleChange}
          />
          <small className="wrong-code">Confirm Code Not Found</small>
        </div>
      </div>
      <div className="metamasklogin-accept">
        <button onClick={metamaskLoginCode}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default MetamaskLoginCode;

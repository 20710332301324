import React from "react";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import LoginView from "views/Login/Login";
import Back from "components/BackButton/Back";

const Login = () => {
    return (
        <Background>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Container>
                    <Back link="/" />
                    <LoginView />
                </Container>
            </div>
        </Background>
    );
};

export default Login;

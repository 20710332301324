import Back from "components/BackButton/Back";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import MetamaskRegisterView from "views/Metamask/MetamaskRegister/MetamaskRegister";
import React from "react";
import { useLocation } from "react-router-dom";

const MetamaskRegister = () => {
  const { state } = useLocation();
  console.log(state);

  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />

          <MetamaskRegisterView address={state} />
        </Container>
      </div>
    </Background>
  );
};

export default MetamaskRegister;

import { create } from "zustand";
import { apiUrl } from "api/apiUrl";

interface ReminderState {
  metamask: boolean;
  welcome: boolean;
}

interface CopyReminderState {
  metamask: boolean;
  welcome: boolean;
}

interface MetamaskReminderState {
  reminders: ReminderState;
  copyReminders: CopyReminderState;
  handleChangeInput: () => void;
  fecthMetamaskReminder: () => Promise<void>;
  updateMetamaskReminder: (state: ReminderState) => Promise<void>;
}

export const useMetamaskReminder = create<MetamaskReminderState>()((set) => ({
  reminders: {
    metamask: true,
    welcome: true,
  },

  copyReminders: {
    metamask: true,
    welcome: true,
  },

  handleChangeInput: () =>
    set((state) => ({
      ...state,
      copyReminders: {
        metamask: !state.copyReminders.metamask,
        welcome: state.copyReminders.welcome,
      },
    })),

  fecthMetamaskReminder: async () => {
    const getByUserId = JSON.parse(localStorage.getItem("tokenAtlas") || "{}");
    console.log("------- Get by User ID", getByUserId?.data?.userId);
    try {
      const request = await fetch(`https://cdn.atlas.space/files/seen/${getByUserId?.data?.userId}.json`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const response = await request.json();

      set(() => ({ reminders: response }));
    } catch (error) {
      console.log("error", error);
    }
  },

  updateMetamaskReminder: async (state: ReminderState) => {
    const formData = new FormData();
    formData.append("doNotShowAgainJsonString", JSON.stringify(state));

    const getByUserToken = JSON.parse(localStorage.getItem("tokenAtlas") || "{}");

    const request = await fetch(`${apiUrl}/User/AddUserDoNotShowAgainJson`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getByUserToken?.data?.token?.accessToken,
      },
      body: formData,
    });
    const response = await request.json();
    console.log("response update", response);
  },
}));

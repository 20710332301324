import React, { useEffect, useState } from "react";
import "./Register.css";
import Inputs from "components/Inputs/Inputs";
import User from "assets/Svg/Register/User";
import PasswordKey from "assets/Svg/Register/Key";
import { Link } from "react-router-dom";
import { inputCheckControll } from "utils/inputCheckControll/InputCheckControll";
import Terms from "views/Terms/";
import { useNavigate } from "react-router-dom";
import { customError } from "utils/authCustomError/RegisterError/RegisterError";
import { motion } from "framer-motion";
import { apiUrl } from "api/apiUrl";
import { Localstorage } from "utils/localstorage/Localstorage";
import ReactGA from "react-ga4";

type RegisterType = {
  firstName: string;
  surName: string;
  userName: string;
  email: string;
  password: string;
  companyName: string;
  confirmPassword: string;
};

const Register = () => {
  const navigate = useNavigate();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false); //Button enable ve disable kontrolü
  const [showRegister, setShowRegister] = useState<boolean>(false); //terms page için kontrol

  const [registerData, setRegisterData] = useState<RegisterType>({
    firstName: "",
    surName: "",
    userName: "",
    password: "",
    companyName: "",
    email: "",
    confirmPassword: "",
  });

  const registerInputHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegisterData((prev: RegisterType) => {
      return {
        ...prev,
        [event.target.id]: event.target.value,
      };
    });
  };

  useEffect(() => {
    var copyRegisterData: Omit<RegisterType, "companyName"> = {
      firstName: registerData.firstName,
      surName: registerData.surName,
      userName: registerData.userName,
      password: registerData.password,
      email: registerData.password,
      confirmPassword: registerData.confirmPassword,
    };
    inputCheckControll<Omit<RegisterType, "companyName">>(copyRegisterData).then((res) => {
      if (res === true && copyRegisterData.password === copyRegisterData.confirmPassword) {
        setButtonDisabled(true);
      } else {
        setButtonDisabled(false);
      }
    });
  }, [registerData]);

  const signup = async () => {
    ReactGA.event({
      category: "Register",
      action: "register",
      label: "register",
    });

    var copyRegisterData: Omit<RegisterType, "companyName"> = {
      firstName: registerData.firstName,
      surName: registerData.surName,
      userName: registerData.userName,
      password: registerData.password,
      email: registerData.password,
      confirmPassword: registerData.confirmPassword,
    };
    const inputCheck = await inputCheckControll<Omit<RegisterType, "companyName">>(copyRegisterData);

    // input check kontrolü - dolu veya boş
    if (inputCheck) {
      let requestBody: Omit<RegisterType, "confirmPassword"> = {
        firstName: registerData.firstName,
        surName: registerData.surName,
        email: registerData.email,
        companyName: registerData.companyName,
        password: registerData.password,
        userName: registerData.userName,
      };

      const token = await new Localstorage().getLocalstorage();

      const request = await fetch(`${apiUrl}/Auth/CreateUser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token?.data?.token?.accessToken,
        },
        body: JSON.stringify(requestBody),
      });
      const response = await request.json();

      if (response && response.status === "Success") {
        navigate("/login");
      } else {
        customError(response);
        console.log("tüm alanları doldurun");
      }
    }
  };

  return (
    <>
      {!showRegister ? (
        <Terms setShowRegister={setShowRegister} />
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} className="register-hfull">
          <div className="register">
            <div className="register-title">
              <h1>Create an Account</h1>
              <p>Please enter your information to sign up</p>
            </div>
            <div className="register-inputs">
              <div className="information">
                <h6>Information</h6>
                <div className="input-wrapper">
                  <div className="input-flex">
                    <Inputs title="Name" id="firstName" onChange={registerInputHandleChange} />
                    <Inputs title="Surname" id="surName" onChange={registerInputHandleChange} />
                  </div>
                  <Inputs title="Email" id="email" onChange={registerInputHandleChange} />

                  <Inputs title="Company" id="companyName" onChange={registerInputHandleChange} />
                </div>
                <span>Optional*</span>
              </div>
              <div className="account">
                <h6>Account</h6>

                <div className="input-wrapper">
                  <Inputs
                    title="Username"
                    id="userName"
                    className="title-padding"
                    style={{ paddingLeft: "45px" }}
                    onChange={registerInputHandleChange}
                  >
                    <User />
                  </Inputs>

                  <Inputs
                    title="Password"
                    type="password"
                    id="password"
                    className="title-padding"
                    style={{ paddingLeft: "45px" }}
                    onChange={registerInputHandleChange}
                  >
                    <PasswordKey />
                  </Inputs>

                  <Inputs
                    title="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    className="title-padding"
                    style={{ paddingLeft: "45px" }}
                    onChange={registerInputHandleChange}
                  >
                    <PasswordKey />
                  </Inputs>
                </div>
              </div>
            </div>
            <div className="register-save">
              <button className={buttonDisabled ? "continue" : "disabled"} onClick={signup}>
                Continue
              </button>
            </div>
            <div className="already-account">
              <span>
                Already have an account? <Link to="/login">Login</Link>
              </span>
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
};

export default Register;

import React from "react";

const Close = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.23609 17.4607L0.550781 15.7627L7.31363 8.9998L0.550781 2.24075L2.23609 0.526245L9.01165 7.2853L15.7618 0.526245L17.4471 2.24075L10.7135 8.9998L17.4471 15.7627L15.7618 17.4607L9.01165 10.7016L2.23609 17.4607Z"
        fill="white"
      />
    </svg>
  );
};

export default Close;

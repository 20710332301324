import { BaseService } from "services/BaseService";
import {
  ForgotPasswordRequest,
  ForgotPasswordResponse,
} from "./types/ForgotPasswordTypes";
import axios, { AxiosResponse } from "axios";
import { BaseResponse } from "services/BaseResponse";
import { ForgotPasswordError } from "utils/authCustomError/forgotPasswordError/ForgotPasswordError";
export class ForgotPasswordService {
  private userService: BaseService;
  private readonly url: string = "Auth/SendResetPasswordCode";

  constructor() {
    this.userService = new BaseService();
  }

  public async forgotPassword(
    data: ForgotPasswordRequest
  ): Promise<AxiosResponse | void> {
    try {
      const response = await this.userService.post<
        ForgotPasswordRequest,
        AxiosResponse<BaseResponse<ForgotPasswordResponse>>
      >(this.url, data);

      return response;
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        ForgotPasswordError(error.response?.data);
        console.log("error", error.response?.data);
      } else {
        console.log(error);
      }
    }
  }
}

import AuthCards from "components/AuthCards/AuthCards";
import "./MetamaskReminderView.css";
import Metamask from "assets/Svg/Metamask";
import { useNavigate } from "react-router-dom";
import { useMetamaskReminder } from "store/useMetamaskReminder";
import { motion } from "framer-motion";
interface ReminderState {
  metamask: boolean;
  welcome: boolean;
}

const MetamaskReminderView = () => {
  const inputChanged = useMetamaskReminder((state) => state.handleChangeInput);
  const reminders = useMetamaskReminder((state) => state.copyReminders);
  const updateReminders = useMetamaskReminder(
    (state) => state.updateMetamaskReminder
  );

  const navigate = useNavigate();
  const metanavigate = (state: ReminderState) => {
    updateReminders(state);
    navigate("/meta");
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="metamask_wrapper"
    >
      <div className="metamask_container_reminder">
        <h1>Would you like to connect your wallet?</h1>
        <h2>You can always connect your wallet from Settings Menu.</h2>
        <div className="login-metamask-container">
          <AuthCards title="Log in with Metamask" url="/">
            <Metamask />
          </AuthCards>
        </div>
        <p>What is crypto-wallet?</p>

        <div className="login-checkbox">
          <input type="checkbox" onChange={inputChanged} />
          <span>Do not show me this option again.</span>
        </div>
        <button onClick={() => metanavigate(reminders)}>Start Exploring</button>
      </div>
    </motion.div>
  );
};

export default MetamaskReminderView;

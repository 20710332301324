import React from "react";
import Background from "components/Background/Background";
import Container from "components/Container/Container";
import SocialFactorView from "views/Torus/SocialFactor/SocialFactor";
import Back from "components/BackButton/Back";

interface IProps {
  socialRegisterFormId: string;
}

const SocialFactor: React.FC<IProps> = ({ socialRegisterFormId }) => {
  return (
    <Background>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container>
          <Back link="/" />

          <SocialFactorView socialRegisterFormId={socialRegisterFormId} />
        </Container>
      </div>
    </Background>
  );
};

export default SocialFactor;

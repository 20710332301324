import { unityContext } from "components/UnityComponent/UnityComponent";
import { createAvatar } from "api/createAvatar/CreateAvatar";
import { closeReadyPlayerMe } from "./unityCloseReadyPlayerMe";
export const unityInsertReadyPlayerMe = () => {
  (window as { [key: string]: any })["insertReadyPlayerMeIFrame"] =
    async () => {
      console.log("profile tıklandı");
      var canvas = document.querySelector(
        "#unity-canvas-1"
      ) as HTMLCanvasElement;
      var container = document.createElement("div"); // Create container div
      var frame = document.createElement("iframe") as HTMLIFrameElement; // create readyplayerme iframe
      var closeImage = document.createElement("img"); // create close image button

      // Container attribute
      container.setAttribute("id", "ready-player-me-container");
      container.style.position = "absolute";
      container.style.top = "0";
      container.style.left = "0";
      container.style.bottom = "0";
      container.style.right = "0";
      container.style.display = "none";
      container.appendChild(frame);

      // Frame attribute
      frame.style.width = "100%";
      frame.style.height = "100%";
      frame.style.margin = "0";
      frame.style.fontSize = "14px";
      frame.style.border = "none";
      frame.setAttribute("id", "frame");
      document.body.appendChild(container);

      console.log("Insert Fonksiyonu Cagirildi");

      //Create Close image button
      closeImage.src = "/images/Close.png";
      closeImage.style.width = "15px";
      closeImage.style.height = "15px";
      closeImage.style.position = "absolute";
      closeImage.style.top = "4%";
      closeImage.style.left = "12%";
      // closeImage.style.margin = "10px"
      closeImage.style.cursor = "pointer";
      closeImage.setAttribute("id", "close-image");
      container.appendChild(closeImage);

      // Hide Unity Canvas
      canvas.hidden = true;

      const scdomain = "atlasspace"; // Replace with your custom subdomain, demo for integraion
      // const frame = document.getElementById("frame");

      frame.src = `https://${scdomain}.readyplayer.me/avatar?frameApi`;

      displayIframe();
      window.addEventListener("message", subscribe);
      document.addEventListener("message", subscribe);
      closeReadyPlayerMe(closeImage, canvas);

      async function subscribe(event: any) {
        const json = parse(event);

        if (json?.source !== "readyplayerme") {
          return;
        }

        // Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === "v1.frame.ready") {
          frame.contentWindow!.postMessage(
            JSON.stringify({
              target: "readyplayerme",
              type: "subscribe",
              eventName: "v1.**",
            }),
            "*"
          );
        }

        // Get avatar GLB URL
        if (json.eventName === "v1.avatar.exported") {
          console.log(`Avatar URL: ${json.data.url}`);
          await createAvatar(json.data.url);
          // Hide ReadyPlayerMe Panel and display unity canvas
          document.getElementById("frame")!.hidden = true;
          document.getElementById("ready-player-me-container")!.style.display =
            "none";
          canvas.hidden = false;
          // Find the LaunchManager object in the scene and call Connect method on it
          console.log(JSON.stringify(json.data));

          unityContext.send(
            "NetworkManager",
            "ConnectFromWebGL",
            JSON.stringify(json.data)
          );
        }

        // Get user id
        if (json.eventName === "v1.user.set") {
          console.log(
            `User with id ${json.data.id} set: ${JSON.stringify(json)}`
          );
        }
      }

      function parse(event: any) {
        try {
          return JSON.parse(event.data);
        } catch (error) {
          return null;
        }
      }

      function displayIframe() {
        document.getElementById("ready-player-me-container")!.style.display =
          "block";
        document.getElementById("frame")!.hidden = false;
      }
    };
};

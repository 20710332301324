import React from "react";
import { Route, Routes } from "react-router-dom";
import UnityView from "views/UnityContext";

interface IProps {
  loginAsAGuest: boolean;

  setLoginAsGuest: React.Dispatch<React.SetStateAction<boolean>>;
}

const Unity: React.FC<IProps> = ({ loginAsAGuest, setLoginAsGuest }) => {
  return (
    <Routes>
      <Route
        path="/meta"
        element={
          <UnityView
            loginAsAGuest={loginAsAGuest}
            setLoginAsGuest={setLoginAsGuest}
          />
        }
      />
    </Routes>
  );
};

export default Unity;

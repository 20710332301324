export class Localstorage {
  async getLocalstorage() {
    const data = JSON.parse(localStorage.getItem("tokenAtlas") as any);
    return data;
  }

  async setLocalstorage<T>(obj: T) {
    const data = localStorage.setItem("tokenAtlas", JSON.stringify(obj));
    return data;
  }
}

import React from "react";
import "./Web3Loading.css";
const Web3Loading = () => {
  return (
    <div className="webthree-loading">
      <div className="loading-circle"></div>
      <h1>Loading...</h1>
    </div>
  );
};

export default Web3Loading;
